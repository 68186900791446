


export const getAllFeedbackData = async (page = 1, limit = 10) => {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}admin/feedback?page=${page}&limit=${limit}`,
        {
            method:"GET",
              headers:{
                  "Content-Type":"application/json",
                  'Authorization': `${sessionStorage.getItem("better-dating-app-auth-token")}`
              }
          }
    );
    return await response.json();
}
import React, { useState, useEffect } from 'react';
import Button from '../../commonComponents/Button/Button';
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { toast } from "react-toastify";
import { getAllModules, deleteModule } from '../../API/apis/contentApi';
import GenericModal from "../../commonComponents/GenericModal/GenericModal"
import GenericModal2 from "../../commonComponents/GenericModal/GenericModal"
import QuestionnaireForm from './addModuleModalComp';
import EditModuleModalComp from './editModuleModalComp';
import { Stack, Pagination } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from "@mui/material/Paper";
import "./content_management.css"
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';


const StyledPagination = styled(Pagination)(({ theme }) => ({
  '& .Mui-selected': {
    backgroundColor: '#57267b',
  },
  '& .MuiPaginationItem-root:hover': {
    backgroundColor: 'rgba(87, 38, 123, 0.6)', // Lighten color on hover
  },
  // Add more styles here as needed for different states
}));


const Content_management = () => {

  const navigate=useNavigate();

  const [allModuleData, setAllModuleData] = useState([]);
  const [addModuleModal, setAddModuleModal] = useState(false);
  const [editModuleModal, setEditModuleModal] = useState(false);
  const [targetModuleModal, setTargetModuleModal] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [alreadyAddedTraits,setAlreadyAddedTraits]=useState({
    "Empathetic":0,
    "Resilient":0,
    "Good Listener":0,
    "Self Aware":0,
    "Direct Communicator":1
  });

  const handleAddModuleModal = (items) => {
    setAddModuleModal(!addModuleModal);
  }
  const handleEditModuleModal = (items) => {
    setEditModuleModal(!editModuleModal);
  }
  const pushToAllModuleData = (newModule) => {
    setAllModuleData([...allModuleData, newModule]);

  }
  const pushUpdatedToAllModuleData = (updatedModule) => {
    setAllModuleData(allModuleData =>
      allModuleData.map(module =>
        module._id === updatedModule._id ? updatedModule : module
      )
    );
  }
  const handleDeleteModule = async (moduleId) => {
    try {
      // Call the API to delete the module from the database
      const response = await deleteModule(moduleId);

      if (response.status === 200) {
        // Update state to filter out the deleted module
        setAllModuleData(prevModules => prevModules.filter(module => module._id !== moduleId));
        toast.success('Module deleted successfully');
      } else {
        // Handle any unsuccessful deletion here
        toast.error('Failed to delete module');
      }
    } catch (error) {
      console.error('Error deleting module:', error);
      toast.error('An error occurred while deleting the module');
    }
  };

  async function fetchAllModuleData(page = 1) {
    const { response, pagination,status } = await getAllModules(page, 10); // Assuming 10 items per page
    
   if(status===425){
     localStorage.clear();
     sessionStorage.clear();
     navigate("/login");
     return;
    }
    if (response.status >= 400) {
      toast.error(response?.message, { autoClose: 5000 });
    } else {
      setAllModuleData(response);

      
      // response.forEach(element => {   
      //   let temp=alreadyAddedTraits;
      //   temp[element.associatedTrait]=1;
        
      // });
      
      // setAlreadyAddedTraits(temp);


      setTotalPages(pagination.totalPages); // Update your state with the total pages
    }
  }

  // Update useEffect to pass currentPage as a dependency
  useEffect(() => {
    fetchAllModuleData(currentPage);
    
  }, [currentPage]);


  return (
    <>
    <Paper
      className=" overflow-y-clip xl:max-h-[80%] 2xl:max-h-[89vh] overflow-x-auto 2xl:w-full xl:w-[100%] xl:h-[85vh] 2xl:h-[85vh] p-6 pb-16 flex flex-col gap-6"
      style={{ borderRadius: "12px" }}
      component={Paper}
    >
      <div className=''> 

        <div className='flex items-center justify-between mb-3'>
          <div className='text-xl text-[#57267B] font-semibold '>Content Management</div>
          <div onClick={() => {
            handleAddModuleModal()
          }}>
            <Button title={"+ Add Modules"} color={"#ffffff"} background={"#57267B"} />

          </div>

        </div>
        <div className=' flex justify-between mt-16 border-b border-b-1 p-4 text-md font-bold text-[#57267B] border-[#E0E0E0]'>
          <div className='flex-grow ' style={{ flexBasis: 0 }}>Module Title</div>
          <div className='flex-grow ' style={{ flexBasis: 0 }}>Associated Trait</div>
          <div className='flex-grow text-center' style={{ flexBasis: 0 }}>Action</div>

        </div>
        {/* Scrollable section */}
        <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.8 }}
          >
        <div > {/* You can adjust the 10rem to the sum height of header + footer */}

          <div style={{scrollbarWidth:"none"}} className="overflow-y-auto 2xl:pb-3 mt-2 h-[calc(100vh-22rem)]">
            {/* Your dynamic content goes here, it will scroll if it overflows the maxHeight */}
            {allModuleData?.map((eachModule) => {
              return (
                <div className=' flex justify-between mt-6 border-b border-b-1 p-4 text-md font-medium  border-[#E0E0E0]'>
                  <div className='flex-grow ' style={{ flexBasis: 0 }}>{eachModule?.moduleTitle}</div>
                  <div className='flex-grow ' style={{ flexBasis: 0 }}>{eachModule?.associatedTrait}</div>
                  <div className='flex justify-center flex-grow' style={{ flexBasis: 0 }}>
                    <div className='flex justify-center px-5 w-fit'>
                      <div className='p-1 mr-1 rounded-lg hover:bg-purple-100 hover:cursor-pointer' onClick={() => {
                        setTargetModuleModal(eachModule);
                        handleEditModuleModal();
                      }}><EditIcon /></div>
                      <div className='p-1 ml-1 rounded-lg hover:bg-purple-100 hover:cursor-pointer' onClick={() => {
                        handleDeleteModule(eachModule?._id)
                      }}><DeleteOutlineIcon /></div>
                    </div>


                  </div>

                </div>
              );
            })}

          </div>
        </div>

        </motion.div>


      </div>



      
    </Paper>




    <GenericModal
        open={addModuleModal}
        title="Add Module"
        caption={"Please fill below details to add a new module."}
        onClose={handleAddModuleModal}
        style={{ height: '60%', maxWidth: '42rem' }}
        content={
          //  <AddQuestionnaire
          //    onCancel={handleAddQuestionaireModal}
          //    handleFetchQuestionnaire={fetchQuestionnaires}
          //  />
          <QuestionnaireForm close={handleAddModuleModal} pushToAllModuleData={pushToAllModuleData}  />
          // <div>this is me!!</div>
        }
      />
      <GenericModal2
        open={editModuleModal}
        title="Edit Module"
        caption={"Please fill below details to edit a module."}
        onClose={handleEditModuleModal}
        content={
          <div style={{scrollbarWidth:"none"}} className='xl:max-h-[25rem] 2xl:max-h-[40rem]  overflow-y-auto '>
           
            <EditModuleModalComp close={handleEditModuleModal} pushUpdatedToAllModuleData={pushUpdatedToAllModuleData} data={targetModuleModal} />
            
            
          </div>
         
          
        }
      />
    <Stack spacing={2} direction="row" justifyContent="center" alignItems="center" marginY={2}>
    <Pagination
      count={totalPages}
      page={currentPage}
      onChange={(_event, page) => setCurrentPage(page)}
    />
  </Stack>

  </>

  )
}



export default Content_management;
import { createSlice } from "@reduxjs/toolkit";

const token=sessionStorage.getItem("better-dating-app-auth-token");

const initialState = {
    loggedIn: !!token,
};


const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login(state, action) {

            console.log("this is redux")
            state.loggedIn = true;
            return state;
           
        },

        logout(state) {
            state.loggedIn = false;
            return state;
        },
    },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
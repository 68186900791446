


export const getAllSessions = async (page = 1, limit = 10, filters={}) => {


  // console.log(filters);
  // debugger;

  
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}subscribe/trainingSubscription`,
    {
      method:"POST",
        headers:{
            "Content-Type":"application/json",
            'Authorization': `${sessionStorage.getItem("better-dating-app-auth-token")}`
        },
        body:JSON.stringify(filters)
    }
  );

  const myResult=await response.json();

  console.log(myResult);
  debugger;
  
  return myResult
};


export const updateSesstionData = async (sendObj) => {


  // console.log(filters);
  // debugger;

  
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}admin/traitScore_and_Status`,
    {
      method:"POST",
        headers:{
            "Content-Type":"application/json",
            'Authorization': `${sessionStorage.getItem("better-dating-app-auth-token")}`
        },
        body:JSON.stringify(sendObj)
    }
  );

  const myResult=await response.json();

  console.log(myResult);
  debugger;
  
  return myResult
};


// {
//   "_id": "667ac5f85fc19adad7d87ccd",
//   "uid": "667a94cedb132a71d37177ab",
//   "userName": "Mandan yadav",
//   "userEmail": "Mandan@yopmail.com",
//   "orderId": "BD-171932210401",
//   "paymentId": "pi_3PVZPJE9gz7tqtWH1xe1dDTj",
//   "trainingType": "Group",
//   "moduleName": "Resilient",
//   "cost": 45,
//   "dates": [
//       "June 26, 2024 at 12:00 AM",
//       "July 2, 2024 at 12:00 AM",
//       "June 28, 2024 at 12:00 AM"
//   ],
//   "createdAt": "2024-06-25T13:28:24.429Z",
//   "updatedAt": "2024-06-25T13:28:24.429Z",
//   "__v": 0
// }


// export const getAllSessions = async (page = 1, limit = 10) => {
//   const response = await fetch(
//     `${process.env.REACT_APP_BASE_URL}subscribe/trainingSubscription?page=${page}&limit=${limit}`,
//     {
//       method:"GET",
//         headers:{
//             "Content-Type":"application/json",
//             'Authorization': `${sessionStorage.getItem("better-dating-app-auth-token")}`
//         }
//     }
//   );
//   return await response.json();
// };


  import React, { useState, useEffect } from 'react';
  import TextField from '@mui/material/TextField';
  import MenuItem from '@mui/material/MenuItem';
  import Button from '@mui/material/Button';
  import Select from '@mui/material/Select';
  import FormControl from '@mui/material/FormControl';
  import InputLabel from '@mui/material/InputLabel';
  import { addModules } from '../../API/apis/contentApi';
  import { toast } from "react-toastify";
  import { getAllModules } from '../../API/apis/contentApi';
  import { useNavigate } from 'react-router-dom';

  const MyFormComponent = ({ close, pushToAllModuleData }) => {

    const navigate=useNavigate();
    const [moduleTitle, setModuleTitle] = useState('');
    const [associatedTrait, setAssociatedTrait] = useState('');
    const [description, setDescription] = useState('');
    const [alreadyAddedTraits, setAlreadyAddedTraits] = useState({
      "Empathetic": 0,
      "healthyCommunication": 0,
      "Good Listener": 0,
      "Self Aware": 0,
      "Direct Communicator": 1
    });

    console.log({ alreadyAddedTraits });

    async function fetchAllModuleData(page = 1) {
      const { response, pagination } = await getAllModules(page, 10); // Assuming 10 items per page
      if (response.status >= 400) {
        toast.error(response?.message, { autoClose: 5000 });
      } else {
        const temp = { ...alreadyAddedTraits };
        response.forEach(element => {
          temp[element.associatedTrait] = 1;
        });
        setAlreadyAddedTraits(temp);
        console.log({ response });
      }
    }

    useEffect(() => {
      fetchAllModuleData();
    }, []);

    const handleAdd = async () => {
      if (moduleTitle === "" || associatedTrait === "" || description.length===0 ) {
        if(moduleTitle === ""){
          toast.error("Module Title is required!", {
            autoClose: 5000
          });
          return;
        }
        else if(associatedTrait === ""){
          toast.error("Please Select an Associated Trait.", {
            autoClose: 5000
          });
          return;
        }
        else if(description.length===0){
          toast.error("Please add some description.", {
            autoClose: 5000
          });
          return;
        }
        else{
          toast.error("Please fill all the details.", {
            autoClose: 5000
          });
          return;

        }
      
      } 
      else if (description.length > 1200) {
        toast.error("Description must be under 300 words.", {
          autoClose: 5000
        });
        return;
      }
      const quiz = [];

      const result = await addModules({ moduleTitle, associatedTrait, description, quiz });

      if(result?.status===425){
        localStorage.clear();
        sessionStorage.clear();
        navigate("/login");
      }

      if (result.status === 200) {
        pushToAllModuleData(result?.response);
        close();
      } else {
        toast.error(result?.message, {
          autoClose: 5000
        });
      }
    };

    const allTraitsAdded = Object.values(alreadyAddedTraits).every(value => value === 1);

    return (
      <div className="p-8 mt-12 rounded-lg w-full">
        <div className="space-y-4">
          <TextField
            fullWidth
            label="Module Title"
            variant="outlined"
            value={moduleTitle}
            onChange={(e) => setModuleTitle(e.target.value)}
            sx={{
              '& label.Mui-focused': {
                color: '#57267B',
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: '#57267B',
                },
              },
            }}
          />
          <FormControl fullWidth variant="outlined" className="w-full">
            <InputLabel
              sx={{
                color: 'rgba(0, 0, 0, 0.54)', // default label color
                '&.Mui-focused': {
                  color: '#57267B', // label color when the input is focused
                },
              }}
            >
              Associated Traits
            </InputLabel>
            <Select
              label="Associated Traits"
              value={associatedTrait}
              onChange={(e) => setAssociatedTrait(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.23)', // default border color
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.54)', // border color on hover
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#57267B', // border color when the input is focused
                },
                '& .MuiSelect-icon': {
                  color: 'rgba(0, 0, 0, 0.54)', // default icon color
                },
                '&.Mui-focused .MuiSelect-icon': {
                  color: '#57267B', // icon color when the input is focused
                },
              }}
            >
              {alreadyAddedTraits["Empathetic"] === 0 && <MenuItem value="Empathetic">Empathetic</MenuItem>}
              {alreadyAddedTraits["healthyCommunication"] === 0 && <MenuItem value="healthyCommunication">Healthy Communication</MenuItem>}
              {alreadyAddedTraits["Good Listener"] === 0 && <MenuItem value="Good Listener">Good Listener</MenuItem>}
              {alreadyAddedTraits["Self Aware"] === 0 && <MenuItem value="Self Aware">Self Aware</MenuItem>}
              {/* {alreadyAddedTraits["Direct Communicator"] === 0 && <MenuItem value="Direct Communicator">Direct Communicator</MenuItem>} */}
              {allTraitsAdded && <MenuItem value=""><em>No trait available</em></MenuItem>}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Description"
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            sx={{
              '& label.Mui-focused': {
                color: '#57267B',
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: '#57267B',
                },
              },
            }}
          />
          <div className="flex justify-end space-x-2">
            <Button
              variant="outlined"
              style={{ borderColor: '#57267B', color: '#57267B' }}
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: '#57267B', color: 'white' }}
              onClick={handleAdd}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    );
  };

  export default MyFormComponent;

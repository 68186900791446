import React, { useEffect, useState } from "react";
import classes from "./Chat.module.css";
import ChatBox from "./ChatBox/ChatBox";
import { useSelector } from "react-redux";
import "./Chat.css"
import SearchInput from "../../commonComponents/Common/SearchInput/SearchInput.jsx"
import { Avatar, Grid } from "@mui/material";
import { onSnapshot } from "firebase/firestore";
import {
  queryClientRoom,
  queryGroupRoom,
  queryRooms,
} from "./ChatBox/firebaseQueries";
// import Button from "../../commonComponents/Common/Button/Button.jsx"
import moment from "moment";
import GenericModal from "../../commonComponents/GenericModal/GenericModal.jsx";
import { useNavigate } from 'react-router-dom';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { db } from "../Chat/firebase.js";
import { getFirestore, doc, updateDoc, getDoc, collection, addDoc, serverTimestamp, Timestamp } from "firebase/firestore";
import Badge from "@mui/material/Badge";
import { toast } from "react-toastify";
import Button from '@mui/material/Button';

const Chat = () => {

  const navigate = useNavigate();
  const user = useSelector((state) => state?.user);
  const [search, setSearch] = useState("");
  const [staffs, setStaffs] = useState([]);
  const [filteredStaffs, setFilteredStaffs] = useState([]);
  const [groups, setGroups] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [selectedListOfPeople, setSelectedListOfPeople] = useState("staff");
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [addGroupForm, setAddGroupForm] = useState(false);
  const [editGroup, setEditGroup] = useState(false);
  const [editGroupForm, setGroupForm] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [selectedChatType, setSelectedChatType] = useState('Promotional')

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  }

  const chatTypeMethod = (getValue) => {
    setSelectedChatType(getValue)
    localStorage.setItem('chatTab', getValue);
    if (getValue === 'Promotional') {
      const PromotionalRooms = staffs.filter(room => room.chatType === "Promotions");
      setFilteredStaffs(PromotionalRooms)
      setSelectedRoom(PromotionalRooms[0]);
    }
    if (getValue === 'Non Promotional') {
      const supportRooms = staffs.filter(room => room.chatType === "Support");
      setFilteredStaffs(supportRooms)
      setSelectedRoom(supportRooms[0]);
    }
  }

  // const tabName = localStorage.getItem('chatTab');

  // if(tabName){
  //   setSelectedChatType(tabName)
  // }
  const currentUser = user?.role === 4 ? "support" : user?.id;

  useEffect(() => {





    const roomsQuery = queryRooms(currentUser);

    // console.log({roomsQuery});


    const unsubscribe = onSnapshot(roomsQuery, (snapshot) => {
      let rooms = [];

      snapshot.forEach((doc) => {

        // console.log({doc});
        let roomId = doc.ref?.id;
        rooms.push({ ...doc.data(), uniqueId: roomId });
      });

      // console.log({rooms});



      // debugger
      // debugger
      // // aditya
      // const supportRooms = rooms.filter(room => room.chatType === "Promotions");
      // debugger
      // debugger
      setStaffs(rooms);
      const tabName = localStorage.getItem('chatTab');
      console.log("selectedChatType",selectedChatType)
      if(tabName){
        if (tabName === 'Promotional') {
          const PromotionalRooms = rooms.filter(room => room.chatType === "Promotions");
          setFilteredStaffs(PromotionalRooms)
          setSelectedRoom(PromotionalRooms[0]);
        }
        if (tabName === 'Non Promotional') {
          const supportRooms = rooms.filter(room => room.chatType === "Support");
          setFilteredStaffs(supportRooms)
          setSelectedRoom(supportRooms[0]);
        }
      }else{
        const supportRooms = rooms.filter(room => room.chatType === "Promotions");
         setFilteredStaffs(supportRooms);
         setSelectedRoom(supportRooms[0]);
      }
    
      // setFilteredStaffs(supportRooms);
      // setSelectedRoom(supportRooms[0]);


    });



    return () => {
      unsubscribe();

    };



  }, []);

  async function makeApiCall() {

    let response = await fetch(
      `${process.env.REACT_APP_BASE_URL}admin/multipleLoginCheck`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `${sessionStorage.getItem("better-dating-app-auth-token")}`
        }
      }
    );
    response = await response.json();
    if (response?.status === 425) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/login");
      return;
    }


  }

  useEffect(() => {

    makeApiCall();



  }, [])


  useEffect(() => {

    const tempFilteredStaffs = staffs.filter((staff) => {
      let flag = false;
      Object.keys(staff?.userDetails)?.forEach((userId) => {
        if (!flag)
          flag = staff?.userDetails?.[userId]?.fullName
            ?.toLowerCase()
            ?.includes(search?.toLowerCase());
      });

      return flag;
    });

    const tempFilteredClients = clients.filter((client) => {
      let flag = false;
      Object.keys(client?.userDetails)?.forEach((userId) => {
        if (!flag)
          flag = client?.userDetails?.[userId].fullName
            ?.toLowerCase()
            ?.includes(search?.toLowerCase());
      });

      return flag;
    });

    const tempFilteredGroups = groups.filter((group) =>
      group?.groupName?.toLowerCase()?.includes(search?.toLowerCase())
    );

    let filterByChatType = []

    if (selectedChatType === 'Promotional') {
      filterByChatType = tempFilteredStaffs.filter(room => room.chatType === "Promotions");
    }
    if (selectedChatType === 'Non Promotional') {
      filterByChatType = tempFilteredStaffs.filter(room => room.chatType === "Support");
    }

    setFilteredStaffs(filterByChatType);
    setFilteredClients(tempFilteredClients);
    setFilteredGroups(tempFilteredGroups);
  }, [search]);



  // sending dara to chat bort 
  const sendDataToChatBot = async (getInfo) => {
    // try {
    //   let chatRoomRef = doc(db, "userSupport", getInfo?.uniqueId);
    //   console.log(getInfo)
    //   console.log("working..",)
    //   await updateDoc(chatRoomRef, {
    //     lastMessage: {
    //       read: false,
    //     }
    //   });
    //   setSelectedRoom(getInfo);
    // } catch (error) {
    //   toast.error(error?.message);
    // }


    try {
      let chatRoomRef = doc(db, "userSupport", getInfo?.uniqueId);

      // Fetch the current document data
      const chatRoomSnap = await getDoc(chatRoomRef);
      if (chatRoomSnap.exists()) {
        let lastMessage = chatRoomSnap.data().lastMessage || {};
        lastMessage.read = false;

        // Update only the read status of lastMessage
        await updateDoc(chatRoomRef, { lastMessage });
        console.log("Read status updated successfully");
      } else {
        console.error("Document does not exist");
      }

      setSelectedRoom(getInfo);
    } catch (error) {
      toast.error(error?.message);
    }

    // setSelectedRoom(staff);
  }



  return (
    <div className="pkj_flex-row  pkj_wd-100 pkj_overflow-auto  xl:h-[81vh] 2xl:h-[85.5vh] pkj_gap-28  ">

      <div className={`${classes.left_container} pkj_flex-col pkj_gap-24 pkj_vt-pd-24  bg-[#57267B]`}>
        {/* this is my search bar at side bar */}
        <div className="pkj_side-margin ">
          <SearchInput
            placeholder="Search by username or email"
            value={search}
            onChange={(e) => setSearch(e.target.value)}

          />
        </div>

        <Grid container>
          <Grid xs={6} md={6}>
            <Button variant="contained" sx={{
              width: '100%',
              borderRadius: '0px',
              textTransform: 'unset',
              backgroundColor: selectedChatType == 'Promotional' ? '#795195' : '#57267b',
              '&:hover': {
                backgroundColor: selectedChatType == 'Promotional' ? '#795195' : '#57267b',
              }
            }} onClick={() => chatTypeMethod('Promotional')}>Promotional</Button>
          </Grid>
          <Grid xs={6} md={6}>
            <Button variant="contained" sx={{
              width: '100%',
              borderRadius: '0px',
              textTransform: 'unset',
              backgroundColor: selectedChatType == 'Non Promotional' ? '#795195' : '#57267b',
              '&:hover': {
                backgroundColor: selectedChatType == 'Non Promotional' ? '#795195' : '#57267b',
              }
            }} onClick={() => chatTypeMethod('Non Promotional')}>Non Promotional</Button>
          </Grid>
        </Grid>

        {/* my side screens where i am showing all the users  */}
        <div className={`${classes.peopleList} pkj_hidden-scroll`}>
          <div>
            {filteredStaffs?.length !== 0 ? (
              filteredStaffs.map((staff) => {

                // console.log({staff});
                const usersId = Object.keys(staff?.userDetails);

                const userId = usersId.filter((id) => id !== "support");

                const user = staff?.userDetails?.[userId];

                // console.log({
                //   "usersId":usersId,
                //   "userId":userId,
                //   "user":user,
                //   "staff":staff,
                //   "user?.image":user?.image,
                //   "userFullName":user?.fullName
                // })       




                return (
                  <div
                    key={staff?.roomId}
                    className={`${classes.chat_slot} ${selectedRoom === staff ? classes.activeRoom : ""
                      }
                      rounded
                      
                      
                      `}
                    onClick={() => {
                      debugger
                      debugger
                      // setSelectedRoom(staff);
                      sendDataToChatBot(staff)
                    }}

                    style={{
                      //text color should be white and make it important
                      // backgroundColor:"#D1D5DB",
                      color: 'white !important', // Sets text color to white and makes it important
                    }}
                  >
                    <div className="pkj_flex-row flex-wrap pkj_just-sp-bwn pkj_align-center  ">
                      <div className="pkj_flex-row pkj_align-center pkj_medium-gap ">

                        <Badge
                          color="primary"
                          className="relative hover:bg-purple-100 rounded-3xl"
                          style={{ backgroundColor: !staff?.lastMessage?.read ? "#fff" : "#F3E8FF" }}
                        >
                          <Avatar src={user?.image} alt={user?.fullName} />
                          <div
                            style={{
                              display: (!staff?.lastMessage?.read) ? "none" : "flex",
                              fontSize: "0.60rem",
                              lineHeight: "0rem"

                            }}
                            className="absolute -top-2 -right-1 bg-green  text-white text-xs font-bold w-5 h-5 flex items-center justify-center rounded-full"
                          >
                            {"🟢"}
                          </div>


                        </Badge>

                        {/* <Avatar src={user?.image} alt={user?.fullName} /> */}
                        <div className={`${classes.staff_heading} pkj_flex-col pkj_ht-100  pkj_small-gap`}>
                          <h3 className="text-white">{user?.fullName}</h3>

                        </div>
                      </div>

                      <div className="py-1 mb-auto pkj_flex-col xl:ml-auto   ">
                        <div style={{ backgroundColor: staff?.roomName === "Promotions" ? ("#F1426D") : (staff?.isOpen ? "#228B26" : "#7A7878") }} className="bg-white flex justify-center items-center rounded px-2 	"><p style={{ color: staff?.isOpen ? "#ffffff" : "#ffffff", fontSize: "10px" }} className="text-xs " >{staff?.roomName}</p></div>
                        <div className="flex">
                          <div className=" ml-auto"><p className={classes.timestamp}>{`${staff?.lastMessage?.createdAt ? (moment.unix(staff?.lastMessage?.createdAt?.seconds).format("lll")) : (<div>loading..</div>)}`}</p></div></div>


                      </div>
                    </div>
                    {/* <div className="text-white ">{staff?.lastMessage ? (staff.lastMessage.message.length >= 25 ? staff.lastMessage.message.substring(0, 25) + '...' : staff.lastMessage.message) : ''}</div> */}

                  </div>
                );

              })
            ) : (
              <p className={classes.no_chat_found}>No Chats found</p>
            )}
          </div>
        </div>

      </div>


      <div className={`${classes.right_container} pkj_bg-white pkj_overflow-auto pkj_hidden-scroll pkj_pd-24`}>
        {selectedRoom && (<ChatBox room={selectedRoom} />)}
      </div>

      {isModalOpen &&
        <GenericModal
          open={isModalOpen}
          handleClose={handleModalToggle}
          content={<div>Me and me</div>}
          isCross={true}
          title="Preview"
          width="738px"
          height="auto"
        />}

    </div>
  );
};

export default Chat;

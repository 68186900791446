export const saveRefferal = async (sendObj) => {
    
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}admin/referrals/admin_save_referral`,
      {
        method:"POST",
          headers:{
              "Content-Type":"application/json",
              'Authorization': `${sessionStorage.getItem("better-dating-app-auth-token")}`
          },
          body:JSON.stringify(sendObj)
      }
    );
  
    const myResult=await response.json();
  
    console.log(myResult);
    debugger;
    
    return myResult
  };
  

  export const saveRefferalStatus = async (sendObj) => {
    
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}admin/referrals/admin_save_referral_status`,
      {
        method:"POST",
          headers:{
              "Content-Type":"application/json",
              'Authorization': `${sessionStorage.getItem("better-dating-app-auth-token")}`
          },
          body:JSON.stringify(sendObj)
      }
    );
    const myResult=await response.json();    
    return myResult
  };
  

  export const getAllReferralsByAdmin = async (params) => {
    const queryString = `data=${encodeURIComponent(JSON.stringify(params))}`;

    const response = await fetch(`${process.env.REACT_APP_BASE_URL}admin/referrals/admin_get_referral?${queryString}`,
        {
            method:"GET",
              headers:{
                  "Content-Type":"application/json",
                  'Authorization': `${sessionStorage.getItem("better-dating-app-auth-token")}`
              }
          }
    );
    return await response.json();
}
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
// import { loginUser } from "../../API/authApi";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/slices/auth";
// import { userActions } from "../../redux/reducers/user";
import { toast } from "react-toastify";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { loginAdmin } from "../../API/apis/authApi";
import { userDetailsActions } from "../../store/slices/userDetails";
import { getFirestore,doc, updateDoc,  getDocs,collection, addDoc, serverTimestamp, Timestamp  } from "firebase/firestore";
import { db } from "../Chat/firebase.js";

const Login = () => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const passwordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  async function getMeTotalUnReadCount(){
    try{
      const userSupportCollection = collection(db, "userSupport");
      // Get all documents in the "userSupport" collection
      const querySnapshot = await getDocs(userSupportCollection);
      const rooms = [];
      querySnapshot.forEach((doc) => {
        rooms.push({ id: doc.id, ...doc.data() });
      });

      const totalUnReadCount=rooms?.filter((eachRoom)=>eachRoom?.lastMessage?.read===true);
     debugger;
     const unReadCount=totalUnReadCount.length;
     return unReadCount;
    }
    catch(err){
      console.log({err});
      return 0;
    }
   

  }

  const handleLogin = async () => {
    try {
      const data = {
        email: formData.email,
        password: formData.password,
      };
     

      if (data?.email === '' && data?.password !== '') {
        toast.error("Email is required", {
          autoClose: 5000
        });
        return;
      }
      if (data?.password === '' && data?.email !== '') {
        toast.error("Password is required", {
          autoClose: 5000
        });
        return;
      }
      if (data?.password === '' && data?.email === '') {
        toast.error("Login credentials are required", {
          autoClose: 5000
        });
        return;
      }
      const response=await loginAdmin(data);
      if(response.status===400 || response.status===404){
        toast.error(response?.message, {
          autoClose: 5000
        });
        return;
      }
      else if(response.status===200){
        sessionStorage.setItem("better-dating-app-auth-token",response?.token);   
        const userDetails=response?.response;
        const unReadCount=await getMeTotalUnReadCount();
        
      // const unReadCount=3;
        dispatch(userDetailsActions.setUserDetails({ userDetails }));
        dispatch(userDetailsActions.setNewNotification({userDetails}));
        dispatch(userDetailsActions.setUnReadCount({unReadCount}));
            
        toast.success("Login Successful!", {
                  autoClose: 5000
                });
          
                navigate("/dashboard")
     
      }
    //   loginUser(data)
    //     .then((response) => {
    //       toast.success(response?.data?.message, {
    //         autoClose: 5000
    //       },);
    //       sessionStorage.setItem(API.AUTH_TOKEN, response.data.data.resultValue?.accessToken);
          dispatch(authActions.login(response?.data?.data?.resultValue));
    //       dispatch(userActions.storeUser(response.data.data.resultValue));
    //       navigate("/user_management");
    //     })
    //     .catch((error) => {
    //       toast.error(error?.message, {
    //         autoClose: 5000
    //       })
    //     }
    //     );
      

    } catch (error) {
      
      console.log(error,"myError")
    }
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    handleLogin();

  };

  return (
    <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="100vh"
    className="gradient"
    sx={{ position: "relative", overflow: "hidden" }} // Adjust this as needed
  >
    {/* Heart shapes as background elements */}
    <div className="h-[847px] top-[67vh] right-[137px] opacity-40 absolute">
    <div className="heart-shape upper-heart "></div>
    </div>
    <div className="h-[84px] top-[78vh] right-[180px] opacity-20 absolute">
    <div className="heart-shape upper-heart h-[50px] "></div>
    </div>
   
    <div className="h-[100px] top-[30vh] left-[180px] opacity-30  absolute">
    <div className="heart-shape upper-heart rotate-8 "></div>
    </div>

    <div className="h-[110px] top-[40vh] left-[160px] opacity-10  absolute">
    <div className="heart-shape upper-heart rotate-8 "></div>
    </div>

    <div className="h-[100px] top-[50vh] left-[120px] opacity-5  absolute">
    <div className="heart-shape upper-heart rotate-8 "></div>
    </div>
    
   

    <div className="h-[600px] top-[20vh] left-[110px] opacity-20  absolute">
    <div className="heart-shape upper-heart "></div>
    </div>


    <Card sx={{ borderRadius: "20px", zIndex: 2, maxWidth: "500px", minWidth:"200px" }}> 
      <CardContent sx={{ padding: "36px" }}>
        <div className="flex justify-center items-center mx-auto mb-4">
          
        <svg className="flex justify-center items-center mx-auto w-[142px] h-[90.51px] " width="167" height="102" viewBox="0 0 167 102" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_529_5152)">
                <path d="M10.6714 92.2701C10.6714 93.2765 10.186 94.2451 8.97581 94.7851C10.5033 95.2515 11.0622 96.8714 11.0622 97.6909C11.0622 100.597 8.88336 101.527 6.18348 101.527H0V88.4902H6.18348C8.75309 88.4902 10.6714 89.6458 10.6714 92.2701ZM2.42044 93.724H6.18137C7.72777 93.724 8.22993 93.0727 8.22993 92.3268C8.22993 91.7301 7.74668 90.7258 6.18137 90.7258H2.42044V93.724ZM2.42044 99.2351H6.18137C7.18779 99.2351 8.62073 98.899 8.62073 97.6698C8.62073 96.4407 7.18779 95.8818 6.18137 95.8818H2.42044V99.233V99.2351Z" fill="#662C90" />
                <path d="M24.5828 101.527H14.8232V88.4902H24.5828V90.875H17.2647V93.8732H24.3222V96.1634H17.2647V99.1049H24.5828V101.525V101.527Z" fill="#662C90" />
                <path d="M32.6823 90.7258H28.5474V88.4902H39.2734V90.7258H35.1385V101.527H32.6802V90.7258H32.6823Z" fill="#662C90" />
                <path d="M46.2004 90.7258H42.0654V88.4902H52.7914V90.7258H48.6565V101.527H46.1983V90.7258H46.2004Z" fill="#662C90" />
                <path d="M66.0704 101.527H56.313V88.4902H66.0704V90.875H58.7523V93.8732H65.8099V96.1634H58.7523V99.1049H66.0704V101.525V101.527Z" fill="#662C90" />
                <path d="M81.6017 101.528H78.677L74.8783 97.17H72.5125V101.528H70.0542V88.4736C72.1217 88.4736 74.1891 88.4925 76.2566 88.4925C79.3284 88.5115 80.9504 90.56 80.9504 92.8124C80.9504 94.6004 80.131 96.4073 77.6538 96.9095L81.6017 101.36V101.528ZM72.5125 90.7806V94.9513H76.2566C77.8198 94.9513 78.4921 93.9091 78.4921 92.8649C78.4921 91.8207 77.803 90.7785 76.2566 90.7785H72.5125V90.7806Z" fill="#662C90" />
                <path d="M101.974 94.8956C102.031 98.1922 100.018 101.524 95.4563 101.524H90.3359V88.4873H95.4563C99.9253 88.4873 101.919 91.6725 101.974 94.8935V94.8956ZM92.7732 99.1608H95.4542C98.4146 99.1608 99.5891 97.0009 99.5324 94.8767C99.4756 92.847 98.2843 90.8174 95.4542 90.8174H92.7732V99.1608Z" fill="#EC5777" />
                <path d="M114.915 99.0692H108.099L106.981 101.527H104.317L110.164 88.4902H112.845L118.692 101.527H116.012L114.913 99.0692H114.915ZM111.507 91.2279L109.086 96.7769H113.929L111.509 91.2279H111.507Z" fill="#EC5777" />
                <path d="M123.109 90.7258H118.974V88.4902H129.7V90.7258H125.565V101.527H123.106V90.7258H123.109Z" fill="#EC5777" />
                <path d="M133.276 101.527V88.4902H135.715V101.527H133.276Z" fill="#EC5777" />
                <path d="M148.658 88.4707H151.116V101.525H149.589V101.544L142.735 92.7359V101.527H140.277V88.4896H142.269L148.656 96.5725V88.4707H148.658Z" fill="#EC5777" />
                <path d="M165.119 91.675C164.243 90.8367 162.94 90.3723 161.804 90.3723C159.009 90.3723 157.316 92.4965 157.316 95.1775C157.316 97.3185 158.564 99.5351 161.804 99.5351C162.827 99.5351 163.722 99.3124 164.745 98.493V96.1839H161.411V93.9862H166.998V99.4805C165.712 100.951 164.092 101.827 161.802 101.827C156.904 101.827 154.912 98.6065 154.912 95.1796C154.912 91.5111 157.202 88.1221 161.802 88.1221C163.552 88.1221 165.302 88.7923 166.645 90.1139L165.117 91.6771L165.119 91.675Z" fill="#EC5777" />
                <path d="M61.4208 14.2382C65.1886 14.2382 68.243 11.1838 68.243 7.41595C68.243 3.64815 65.1886 0.59375 61.4208 0.59375C57.653 0.59375 54.5986 3.64815 54.5986 7.41595C54.5986 11.1838 57.653 14.2382 61.4208 14.2382Z" fill="#662C90" />
                <path d="M86.2408 14.1005C86.2408 14.1005 86.2471 14.1026 86.2492 14.1047C86.7472 14.3127 87.2851 14.403 87.8251 14.4136L90.1404 14.4619C90.3232 14.4199 90.5039 14.3673 90.672 14.3001C91.7982 13.8631 92.9685 13.5353 94.1766 13.5479C94.8385 13.5542 95.536 13.6298 96.1684 13.821C96.3827 12.8567 96.5025 11.8376 96.5025 10.7829C96.5025 4.82633 92.855 -0.00195312 88.3545 -0.00195312C83.854 -0.00195312 80.2065 4.82633 80.2065 10.7829C80.2065 11.8839 80.3326 12.947 80.5658 13.9492C81.8937 13.6362 84.2784 13.279 86.2429 14.0984L86.2408 14.1005Z" fill="#EC5777" />
                <path d="M79.5618 25.9971C80.4652 26.9132 81.894 27.0477 82.9592 26.3228C85.0056 24.9277 87.4261 24.2028 89.9558 24.2511C93.2293 24.3142 96.2674 25.6631 98.5093 28.0499C99.2573 28.8462 99.875 29.716 100.367 30.6321C101.676 29.6887 102.856 28.5394 103.852 27.2095L104.222 26.7157C103.684 25.9089 103.077 25.1336 102.392 24.4024C99.1606 20.9608 94.7799 19.0152 90.0587 18.9249C86.4092 18.8703 82.9151 19.9103 79.9652 21.9189C78.589 22.856 78.3957 24.81 79.5639 25.995L79.5618 25.9971Z" fill="#EC5777" />
                <path d="M101.301 39.69C100.749 41.5642 99.7298 43.3333 98.2423 44.8188L74.8762 68.1828L63.3664 56.673C62.3264 55.633 60.6413 55.633 59.6013 56.673C58.5613 57.7131 58.5613 59.3981 59.6013 60.4382L74.8762 75.713L102.007 48.5818C105.525 45.0646 107.233 40.4527 107.119 35.8682C105.363 37.4167 103.4 38.7025 101.301 39.6858V39.69Z" fill="#EC5777" /><path d="M115.605 10.2583L106.768 14.2735L115.05 14.8429L105.098 28.1448C101.15 33.4227 94.5271 36.0953 88.2301 34.9565C84.8579 34.3472 81.7672 32.763 79.2879 30.3741L77.2478 28.3633L72.6317 23.8586C66.028 17.1688 55.3756 16.8494 48.3832 23.1316C44.7525 26.3925 42.685 30.8762 42.5611 35.7549C42.4371 40.6231 44.2672 45.1951 47.7108 48.6345L54.5646 55.774L70.3857 39.8373C71.4215 38.7931 71.4152 37.108 70.371 36.0722C69.3267 35.0364 67.6417 35.0427 66.6058 36.0869L54.6276 48.1534L51.5306 44.9282L51.4886 44.8841C49.0765 42.4847 47.797 39.2931 47.8831 35.8915C47.9693 32.4919 49.4106 29.3676 51.9403 27.0943C56.8127 22.7135 64.2358 22.9362 68.875 27.6363L72.7851 31.4519L75.6762 34.1203C78.8971 37.2215 82.9165 39.2805 87.2994 40.0747C88.5663 40.3038 89.8417 40.4151 91.1128 40.4151C98.0926 40.4151 104.927 37.0471 109.258 31.2607L119.305 17.8348L122.558 25.5647L123.497 15.9039L124.436 6.24316L115.599 10.2604L115.605 10.2583Z" fill="#662C90" />
              </g>
              <defs>
                <clipPath id="clip0_529_5152">
                  <rect width="167" height="101.825" fill="white" />
                </clipPath>
              </defs>
            </svg>
        </div>

        <Typography
          variant="h5"
          component="h1"
          sx={{
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            fontSize: "28px",
            lineHeight: "39px",
            fontWeight: 800,
            textAlign: "center",
            mb: 1,
          }}
        >
          
        </Typography>
        <Typography
          sx={{
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            fontSize: "18px",
            color: "#888888",
            textAlign: "center",
            mb: 4,
          }}
        >
          Please enter the below details in order to login.
        </Typography>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email Address"
              value={formData.email}
              onChange={handleInputChange}
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "4px",
                border: "1px solid #C7C6C6",
                fontFamily: "'Plus Jakarta Sans', sans-serif",
              }}
            />
          </div>
          <div className="mb-4 relative">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleInputChange}
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "4px",
                border: "1px solid #C7C6C6",
                fontFamily: "'Plus Jakarta Sans', sans-serif",
              }}
            />
            <button
              type="button"
              onClick={passwordVisibility}
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                background: "none",
                border: "none",
              }}
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </button>
          </div>
          <div className="flex justify-end mb-6">
            <Link to="/forgetPassword" style={{ textDecoration: "none" }}>
              <Button sx={{ fontFamily: "'Plus Jakarta Sans', sans-serif", color: "#6F2395", fontWeight: 600 }}>
                Forgot Password?
              </Button>
            </Link>
          </div>
          <button
            type="submit"
            className="login-btn"
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "4px",
              border: "none",
              background: "#6F2395",
              color: "white",
              fontFamily: "'Plus Jakarta Sans', sans-serif",
              fontWeight: 700,
              fontSize: "16px",
              cursor: "pointer",
            }}
          >
            Login
          </button>
        </form>
      </CardContent>
    </Card>
  </Box>
  );
};

export default Login;



import React, { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Link, useNavigate,useParams } from "react-router-dom";
import { toast } from "react-toastify";
import './reset_new_password.css'; // Make sure this path is correct for your project structure
import { changePassword } from "../../API/apis/authApi";

const ResetNewPassword = () => {
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });
  const navigate = useNavigate();

  const {token}=useParams();
  console.log(token);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleResetPassword = async () => {
    // Insert your password reset logic here
    // This is just a placeholder logic for demonstration
    if (formData.newPassword !== formData.confirmNewPassword) {
      toast.error("Passwords do not match.");
      return;
    }

    const response= await changePassword({password:formData.newPassword},token);
    console.log("this is it",response);
    if(response?.status===200){
      toast.success("Password reset successfully!");
    navigate('/login'); 

    }
    else if(response?.status===401){
      toast.error("Reset Link Expired!");
    }
    else{

      toast.error("Internal Server Error");
    }
    
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleResetPassword();
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      className="gradient"
    >
      {/* Similar to your ForgetPassword Page */}
      <Card style={{ borderRadius: "20px", zIndex: 2 }}>
        <CardContent className="login-card" style={{ padding: "36px" }}>
          {/* Replace with your logo */}

          <div className="frame2" style={{ marginTop: "30px", gap: "4px", textAlign: "center" }}>
            <Typography
              sx={{
                fontFamily: "Plus Jakarta Sans, sans-serif",
                fontSize: "28px",
                lineHeight: "39px",
                fontWeight: "800",
                textAlign: "center"
              }}
            >
              Reset Your Password
            </Typography>
            <p className="text-description">
              Please enter your new password below.
            </p>
          </div>
          <form onSubmit={handleSubmit} className="form">
            <div className="input-wrapper">
              <input
                type="password"
                name="newPassword"
                placeholder="Your New Password"
                value={formData.newPassword}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="input-wrapper">
              <input
                type="password"
                name="confirmNewPassword"
                placeholder="Confirm Your New Password"
                value={formData.confirmNewPassword}
                onChange={handleInputChange}
                required
              />
            </div>
            <button type="submit" className="submit-btn">
              Reset Password
            </button>
          </form>
          <Link to="/" className="back-link">Back to login</Link>
        </CardContent>
      </Card>
    </Box>
  );
};

export default <ResetNewPassword/>;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

const SubscriptionsBarChart = ({ pkjComp }) => {
  const chartContainerRef = useRef(null);
  const [containerSize, setContainerSize] = useState({ width: '50%', height: '80%' });
  const [filterBy, setFilterBy] = useState('weekly');
  const [chartXaxisLabel, setChartXaxisLabel] = useState(['Sun','Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']);
  const [chartData, setChartData] = useState([]);

// This function converts an array of objects into a CSV string
function convertToCSV(objArray) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    // Add headers
    let header = Object.keys(array[0]).join(',');
    str += header + '\r\n';

    // Loop through the array of objects and for each object turn the values into a CSV string
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line !== '') line += ',';

        let value = array[i][index];
        if (value instanceof Date) {
          value = value.toISOString();
        }
        line += value;
      }

      str += line + '\r\n';
    }

    return str;
  }

  // This function triggers the download of a CSV file
  function downloadCSV(data, filename = 'export.csv') {
    let csv = convertToCSV(data);
    if (csv === null) return;

    if (!csv.match(/^data:text\/csv/i)) {
      csv = 'data:text/csv;charset=utf-8,' + csv;
    }

    const dataUri = encodeURI(csv);
    const link = document.createElement('a');
    link.setAttribute('href', dataUri);
    link.setAttribute('download', filename);
    link.click();
  }


  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
          setContainerSize({
              width: entry.contentRect.width,
              height: entry.contentRect.height
          });
      }
    });


    if (chartContainerRef.current) {
      resizeObserver.observe(chartContainerRef.current);
    }

    return () => {
      if (chartContainerRef.current) {
        resizeObserver.unobserve(chartContainerRef.current);
      }
    };
  }, []);

  async function getAllChartData(periodd="weekly"){
    try{  
      const data = {
          period: periodd
      };
  
      let response = await fetch(process.env.REACT_APP_BASE_URL + "subscribe/barChartSubscription", {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
              "Content-Type": "application/json"
          }
      });
  
      response = await response.json();
     
      if(periodd==="weekly"){
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const values = days.map(day => response?.data[day]);
        setChartData(values);
      }
      else if(periodd==="yearly"){
        const months = [
          "January", "February", "March", "April", "May", "June", 
          "July", "August", "September", "October", "November", "December"
        ];
        const values = months.map(month => response?.data[month]);
        setChartData(values);

      }
      else{

        setChartData(response?.data);

      }

      setLabelToXaxis(periodd);

      // debugger;
      
  
     
  
    
      


  }
  catch(error){
    console.log({error})
  }

  }

  useEffect(()=>{
    // setLabelToXaxis(filterBy)
    getAllChartData(filterBy);
  },[filterBy])

  function setLabelToXaxis(labelName){
    switch(labelName){
      case "weekly":
        setChartXaxisLabel(['Sun','Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']);
        // setChartData([15, 20, 25, 30, 35, 40, 45]);
        break;
      case "monthly":
        setChartXaxisLabel(['Week-1', 'week-2', 'Week-3', 'Week-4']);
        // setChartData([120, 150, 180, 200]);
        break;        
      case "yearly":
        setChartXaxisLabel(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']);
        // setChartData([40, 26, 10, 66, 27, 14, 68, 90, 46, 90, 57, 20]);
        break;

      // case "yearly":
      //   setChartXaxisLabel(['2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030']);
      //   setChartData([40, 26, 10, 66, 27, 14, 68, 90, 46, 90]);
      //   break;
      default:
        setChartXaxisLabel(['Sun','Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']);
        // setChartData([40, 26, 10, 66, 27, 14, 68, 90, 46, 90, 57, 20]);
        break;
    }
  }

  const options = {
    credits: {
      enabled: false, // Disable the Highcharts logo
    },
    chart: {
      type: 'column',
      height: containerSize.height,
      width: containerSize.width,
    },
    title: {
      text: '',
      align: 'left',
    },
    subtitle: {
      text: '',
      align: 'left',
    },
    xAxis: {
      categories: chartXaxisLabel,
      crosshair: true,
      accessibility: {
        description: 'Time Period',
      },
    },
    yAxis: {
      visible: false,
      min: 0,
      title: {
        text: 'Number of Users',
      },
    },
    tooltip: {
      valueSuffix: ' Users',
    },
    plotOptions: {
      column: {
        pointPadding: 0.25,
        borderWidth: 0,
      },
    },
    legend: {
      align: 'center',
      verticalAlign: 'top',
      layout: 'horizontal',
      enabled: false,
    },
    series: [
      {
        name: 'Subscribed',
        data: chartData,
        color: '#F1426D',
      },
    ],
  };

  return (
    <div className=" h-full rounded-xl">
      <div className=" card-body p-0 d-flex justify-content-between flex-column overflow-hidden">
       
        <div className="flex justify-between 2xl:text-base xl:text-xs flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3">
          <div className="flex items-center">
            <span className="fs-7 fw-bolder text-gray-700 pe-4 text-nowrap d-none d-xxl-block">
              Filter By:
            </span>
            <select
              className="form-select form-select-sm form-select-solid w-100px w-xxl-125px"
              data-control="select2"
              data-placeholder="Yearly"
              data-hide-search="true"
              value={filterBy}
              onChange={(e) => {
                setFilterBy(e.target.value);
                // setLabelToXaxis(e.target.value); 
              }}
            >
              <option value="weekly">Week</option>
              <option value="monthly">Month</option>
              <option value="yearly">Year</option>
            </select>
          </div>
          {/* <div onClick={()=>downloadCSV(pkjComp)}  className='2xl:text-sm bg-[#57267B] hover:cursor-pointer xl:text-xs p-1 text-white rounded'>Download CSV</div> */}
        </div>
        <div ref={chartContainerRef} className="2xl:h-[16rem] xl:h-32 rounded-lg pb-2">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>

      </div>
    </div>
  );
};

export default SubscriptionsBarChart;

import React, { useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { motion } from "framer-motion";
import Paper from "@mui/material/Paper";
import Button from '../../commonComponents/Button/Button';
import { Checkbox, MenuItem, Pagination, Select, Stack, TextField } from "@mui/material";
import GenericModal from "../../commonComponents/GenericModal/GenericModal"
import UserView from "./userView";
import Delete from "./delete";
import UserBlock from "./block";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Moment from "react-moment";
import { toast } from "react-toastify";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ChatIcon from '@mui/icons-material/Chat';
import { db } from "../Chat/firebase.js";
import { getFirestore, doc, updateDoc, getDocs, collection, addDoc, serverTimestamp, Timestamp } from "firebase/firestore";
import { HashLoader, ClipLoader, SyncLoader, BounceLoader, PacmanLoader } from 'react-spinners';
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
import PermMediaIcon from "@mui/icons-material/PermMedia";
import CustomButton from '../../commonComponents/Button/Button';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';

const CustomTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    height: '12rem', // Adjust this value to set the desired height
  },
});

export default function UserManagement() {


  const [userId, setUserId] = useState("");
  const [userView, setUserView] = useState(false);
  const [isDel, setIsDel] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allTableData, setAllTableData] = useState([]);

  const [tableData, setTableData] = useState([]);

  const [isBlock, setIsBlock] = useState(false);
  const [isBlockModal, setIsBlockModal] = useState(false);
  const [filterSearch, setFilterSearch] = useState("");

  const [isBlocked, setIsBlocked] = useState(false);
  const [yes, setYes] = useState(false)

  const [filter, setFilter] = useState({});
  const [interestFilter, setInterestFilter] = useState({});
  const [ratingFilter, setRatingFilter] = useState({});
  const [resetSubs, setResetSubs] = useState("");
  const [resetFlagged, setResetFlagged] = useState("");
  const [resetRating, setResetRating] = useState("");
  const [resetInterest, setResetInterest] = useState("");

  // This is new code for chat checkbox 
  const [selectedUserList, setSelectedUserList] = useState([]);
  const [sendMessageModalCheck, setSendMessageModalCheck] = useState(false);
  const [messageValue, setMessageValue] = useState(null);

  const [selectAllCheckBox, setSelectAllCheckBox] = useState(false);

  // Media check section ...
  //file name
  const [mediaPreview, setMediaPreview] = useState("");
  //file type
  const [mediaType, setMediaType] = useState('');
  // send button 
  const [disableSendBtn, setDisableSendBtn] = useState(false);
  // file data
  const [chatMedia, setChatMedia] = useState("");

  const [previewOnly, setForPreviewOnly] = useState("");


  const navigate = useNavigate();

  // Custom content for the loading toast
  const LoadingToast = ({ message }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {/* <ClipLoader size={16} color={"#6F2395"} loading={true} /> */}
      <HashLoader
        color="#6f2395"
        size={28}
      />
      <span style={{ marginLeft: '10px' }}>{message}</span>

    </div>
  );

  // async function doesRoomExist(userId){
  //   const userSupportCollection = collection(db, "userSupport");
  //   try {
  //     // Get all documents in the "userSupport" collection
  //     const querySnapshot = await getDocs(userSupportCollection);

  //     // Process the documents
  //     const rooms = [];
  //     querySnapshot.forEach((doc) => {
  //       rooms.push({ id: doc.id, ...doc.data() });
  //     });

  //     const roomsOfUserId=rooms?.filter((eachRoom)=>eachRoom?.users[0]===userId || eachRoom?.users[1]===userId);

  //     let promotionRoom=roomsOfUserId?.filter((eachRoom)=>eachRoom?.roomName==="Promotions")

  //     return promotionRoom[0]??null;


  //   } catch (error) {
  //     console.error("Error getting documents: ", error);
  //   }

  // }

  async function doesRoomExist(userId) {
    try {
      const userSupportCollection = collection(db, "userSupport");
      const querySnapshot = await getDocs(userSupportCollection);

      const rooms = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      const roomsOfUserId = rooms.filter(
        (eachRoom) => eachRoom?.users[0] === userId || eachRoom?.users[1] === userId
      );

      const promotionRoom = roomsOfUserId.find((eachRoom) => eachRoom?.roomName === "Promotions");
      return promotionRoom || null;
    } catch (error) {
      console.error("Error getting documents: ", error);
    }
  }


  const OpenModalMethod = () => {
    if (selectedUserList.length <= 0) {
      toast.error("Please select atleast one user !", {
        autoClose: 5000
      });
      return;
    }
    setSendMessageModalCheck(true)
  }

  async function handleMultipleChatClicks() {
    const toastId = toast.info(<LoadingToast message="Fetching user info" />, {
      autoClose: false,
      closeOnClick: false,
      draggable: false,
      hideProgressBar: true,
      icon: false,
    });
    console.log(selectedUserList)
    try {
      for (let i = 0; i < selectedUserList.length; i++) {
        const userDetails = selectedUserList[i];
        const userId = userDetails?._id;
        // Check if room exists for the user
        const result = await doesRoomExist(userId);

        if (result) {
          // Room already exists, update lastMessage.createdAt
          await updateRoomLastMessage(result.id, userId);
        } else {
          // Create a new room for the user
          await createNewRoom(userDetails);
        }
      }

      toast.dismiss(toastId);
      navigate('/userSupport');
    } catch (error) {
      toast.dismiss(toastId);
      console.error("Error processing users: ", error);
    }
  }

  async function updateRoomLastMessage(roomId, userId) {
    try {
      const roomDocRef = doc(db, "userSupport", roomId);
      await updateDoc(roomDocRef, {
        "lastMessage.message": messageValue,
        "lastMessage.read": false,
        "lastMessage.createdAt": Timestamp.now(),
      });
      const myId = uuidv4();
      const messagesReference = collection(db, `userSupport/${roomId}/messages`);
      let sendObj = {}
      if (mediaPreview) {
        const _mediaPreview = mediaPreview;
        sendObj = {
          text: messageValue,
          media: {
            name: _mediaPreview,
            type: mediaType.includes("image")
              ? "image"
              : mediaType.includes("video")
                ? "video"
                : "",
            url: chatMedia?.url || "",
            thumbnail: chatMedia?.thumbnail || "",
          },
          messageType: mediaType.includes("image")
            ? "image"
            : mediaType.includes("video")
              ? "video"
              : "text",
          sentBy: "support",
          sentTo: userId,
          createdAt: serverTimestamp(),
          _id: myId
        }
        if (mediaPreview) {
          if (mediaType.includes("image")) {
            sendObj['image'] = chatMedia?.url
          }
          if (mediaType.includes("video"))
            sendObj['video'] = chatMedia?.url
        }
      } else {
        sendObj = {
          text: messageValue,
          sentBy: "support",
          sentTo: userId,
          createdAt: serverTimestamp(),
          _id: myId
        }
      }
      await addDoc(messagesReference, sendObj);

      const myData = {
        userId: userId,
        message: messageValue,
        messageType: mediaType.includes("image")
          ? "image"
          : mediaType.includes("video")
          ? "video"
          : "text",
        roomId: roomId,
      };

      const result = await fetch(
        process.env.REACT_APP_BASE_URL + "call/notification",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(myData),
        }
      );

    } catch (error) {
      console.error("Error updating document: ", error);
    }
  }

  async function createNewRoom(userDetails) {
    try {
      const userId = userDetails?._id;
      const newDoc = await addDoc(collection(db, "userSupport"), {
        chatType: "Promotions",
        createdAt: serverTimestamp(),
        isOpen: true,
        lastMessage: {
          createdAt: serverTimestamp(),
          message: messageValue,
          read: false,
          sentBy: "support",
        },
        last_message: {
          createdAt: serverTimestamp(),
          message: `Greetings ${userDetails?.fullName}!`,
          userId: userId,
        },
        // roomId: uuidv4(),
        roomImage: "https://nextgen-resourcifi.s3.amazonaws.com/Uploads/1693818629729/trackimage.jpeg",
        roomName: "Promotions",
        userDetails: {
          userId: {
            email: userDetails?.email,
            fullName: userDetails?.fullName,
            id: userId,
            image: userDetails?.profileImg,
          },
          support: {
            email: "time4betterdating@gmail.com",
            fullName: "Support Center",
            id: "support",
            image: "https://cdn.pixabay.com/photo/2017/05/30/04/02/support-2355701_640.jpg",
          },
        },
        users: [userId, "support"],
      });

      await updateDoc(newDoc, { roomId: newDoc.id });

      const messagesReference = collection(db, `userSupport/${newDoc?.id}/messages`);
      const myId = uuidv4();
      const _mediaPreview = mediaPreview;
      setMediaPreview("");
      let sendObj = {
        text: messageValue,
        media: {
          name: _mediaPreview,
          type: mediaType.includes("image")
            ? "image"
            : mediaType.includes("video")
              ? "video"
              : "",
          url: chatMedia?.url || "",
          thumbnail: chatMedia?.thumbnail || "",
        },
        messageType: mediaType.includes("image")
          ? "image"
          : mediaType.includes("video")
            ? "video"
            : "text",
        sentBy: "support",
        sentTo: userId,
        createdAt: serverTimestamp(),
        _id: myId
      }
      if (mediaPreview) {
        if (mediaType.includes("image")) {
          sendObj['image'] = chatMedia?.url
        }
        if (mediaType.includes("video"))
          sendObj['video'] = chatMedia?.url
      }
      await addDoc(messagesReference,sendObj);

      const myData = {
        userId: userId,
        message: messageValue,
        messageType: mediaType.includes("image")
          ? "image"
          : mediaType.includes("video")
          ? "video"
          : "text",
        roomId: newDoc?.id,
      };

      const result = await fetch(
        process.env.REACT_APP_BASE_URL + "call/notification",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(myData),
        }
      );

      console.log("Document written with ID: ", newDoc.id);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  }



  let title = ["View User Details"];
  const tableHeading = [
    "S. No.",
    "Name",
    "Age",
    "Gender",
    "Location",
    "Registration Date",
    "Average Date Trait Rating",
    "Subscribed User",
    "Action",
  ];
  const { state } = useLocation();
  const [userData, setUserData] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [customTimeFilter, setCustomTimeFilter] = useState(state?.type === 6 ? "customDateRange" : "all");
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortDirection2, setSortDirection2] = useState('asc');

  useEffect(() => {


    if (allTableData.length !== 0) {
      setTableData(allTableData.filter((eachSessions) => {
        if (eachSessions?.fullName) {
          return eachSessions?.fullName.toLowerCase().includes(filterSearch.toLowerCase());
        }

      }));

    }


  }, [filterSearch]);

  const getTypeFilter = (type) => {
    if (type === 2) {
      return "GIVETHANKS";
    } else if (type === 3) {
      return "GETTHANKS";
    } else {
      return "ALL";
    }
  };


  const sortByName = () => {
    const sortedData = [...tableData].sort((a, b) => {
      if (sortDirection === 'asc') {
        return a.fullName.localeCompare(b.fullName);
      } else {
        return b.fullName.localeCompare(a.fullName);
      }
    });
    setAllTableData(sortedData);
    setTableData(sortedData);
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc'); // Toggle sort direction
  };

  const sortByAge = () => {
    const sortedData = [...tableData].sort((a, b) => {
      if (sortDirection2 === 'asc') {
        return parseInt(a.age) - parseInt(b.age);
      } else {
        return parseInt(b.age) - parseInt(a.age);
      }
    });

    setAllTableData(sortedData);
    setTableData(sortedData);
    setSortDirection2(sortDirection2 === 'asc' ? 'desc' : 'asc'); // Toggle sort direction
  };


  const applyFilter = () => {


    console.log({
      filter, interestFilter, ratingFilter
    })


    // const myData=allTableData;
    // //filtering Subscribed User
    // if(filterSubscribed!==""){
    //   if(filterSubscribed==="Yes"){
    //     filter={...filter,isSubscribtionActive:true}
    //   }
    //   else{
    //     filter={...filter,isSubscribtionActive:false}
    //   }
    // }


    // //filtering by User's interest

    // myData = myData.filter((eachSessions) => {
    //   return eachSessions?.interest.includes(filterInterest);
    // })


    // //here i want to add logic for sort by Name and Age Assesnding and age decessending
    // // by using filterSortBy
    // if (filterSortBy.length>0) {
    //   switch (filterSortBy) {
    //     case 'Name':
    //       myData.sort((a, b) => a.fullName.localeCompare(b.fullName));
    //       break;
    //     case 'Age ASC':
    //       myData.sort((a, b) => parseInt(a.age) - parseInt(b.age));
    //       break;
    //     case 'Age DESC':
    //       myData.sort((a, b) => parseInt(b.age) - parseInt(a.age));
    //       break;
    //     default:
    //       break;
    //   }
    // }

    // //filtering flagged User 

    // // myData=myData.filter((eachSessions)=>{
    // //   if(eachSessions?.flaggedUser){
    // //   return eachSessions?.flaggedUser.includes(filterFlagged);
    // // }
    // // })   


    // //filtering users on basis of ratings
    // //below average filte
    // if (filterRating === "belowAverage") {
    //   myData = myData.filter((eachSessions) => {
    //     return parseFloat(eachSessions?.avg_date_trait_rating) <= 3;
    //   })

    // }
    // else if (filterRating === "Average") {
    //   myData = myData.filter((eachSessions) => {
    //     return (parseFloat(eachSessions?.avg_date_trait_rating) > 3) && (parseFloat(eachSessions?.avg_date_trait_rating) < 6);
    //   })
    // }
    // else if (filterRating === "Good") {
    //   myData = myData.filter((eachSessions) => {
    //     return parseFloat(eachSessions?.avg_date_trait_rating) >= 6;
    //   })

    // }

    // //filtering on based of name
    // myData = myData.filter((eachSessions) => {
    //   return eachSessions?.userName.toLowerCase().includes(filterSearch.toLowerCase());
    // })

    // setTableData(myData);



  }

  const [typeFilter, setTypeFilter] = useState(getTypeFilter(state?.type));
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchFilled, setIsSearchFilled] = useState(false);
  const [addressData, setAddressData] = useState([]);
  const [delId, setDelId] = useState("");
  const [isDeleted, setIsDeleted] = useState(false);
  const [customRange, setCustomRange] = useState(false);
  const currentDate = new Date();
  const [customDateRange, setCustomDateRange] = useState({
    startDate: state?.type === 6 ? new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0, 0).toISOString() : "",
    endDate: state?.type === 6 ? new Date().toISOString() : ""
  })
  const [loading, setLoading] = useState(true);

  const [filterSubscribed, setFilterSubscribed] = useState("");
  const [filterFlagged, setFilterFlagged] = useState("");
  const [filterRating, setFilterRating] = useState("");
  const [filterInterest, setFilterInterest] = useState("");
  const [filterSortBy, setFilterSortBy] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);




  const fetchUserData = async () => {
    // debugger;
    setLoading(false);
    try {
      const dataa = {
        filter, interestFilter, ratingFilter
      };
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}admin/userManagement?page=${currentPage}&limit=${limit}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `${sessionStorage.getItem("better-dating-app-auth-token")}`
        },
        body: JSON.stringify(dataa)

      });

      const data = await response.json();
      if (data?.status === 425) {
        localStorage.clear();
        sessionStorage.clear();
        navigate("/login");
      }
      if (response.ok) {
        setTableData(data.response);
        setAllTableData(data.response);
        setTotalCount(data.pagination.totalItems);
        setTotalPages(data.pagination.totalPages);
        console.log(data.response);

      } else {
        throw new Error(data.message || "Failed to fetch user data");
      }



    }
    catch (error) {
      // toast.error(error?.message, {
      //       autoClose: 5000, toastId: "regeg"
      //     })
      console.log(`Error while fetching the user info ${error}`)
    }

  };

  useEffect(() => {
    if (isDeleted) {
      fetchUserData();
      setIsDeleted(false);
    }
    if (isBlocked) {
      fetchUserData();
      setIsBlocked(false);
    }
  }, [isDeleted, isBlocked]);

  useEffect(() => {
    fetchUserData();
  }, [state, limit, currentPage, page, typeFilter, customDateRange, filter, interestFilter, ratingFilter]);


  const handleOpenModal = () => {
    setIsModalOpen(true);
    setUserView(!userView);
    setIsBlockModal(!isBlockModal);
    setIsBlock(!isBlock);
    setIsDel(!isDel);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setUserView(false);
    setIsBlockModal(false);
    setIsBlock(false);
    setIsDel(false);
  };

  const handleUserView = (data) => {
    setUserData(data);
    handleOpenModal();
    setIsDel(false);
    setIsBlockModal(false);
    setIsBlock(false);
  };

  const handleDelete = (id) => {
    setDelId(id)
    handleOpenModal();
    setIsDel(!isDel);
    setUserView(false);
    setIsBlockModal(false);
    setIsBlock(false);
  };

  const handleBlock = (id, yes) => {
    handleOpenModal();
    setIsBlockModal(!isBlockModal);
    setIsBlock(!isBlock);
    setIsDel(false);
    setUserView(false);
    setYes(yes)
    setUserId(id)
  };







  const renderTableCell = (content, style = {}) => (
    <TableCell
      style={{
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: "400",
        ...style,
      }}
    >
      {content}
    </TableCell>
  );

  const renderTextContent = (content, style = {}) => (
    <p
      className="w-max text-[14px] mx-auto "
      style={{ color: "#000000", ...style }}
    >
      {content}
    </p>
  );


  const renderActionButtons = (row) => (
    <div className="flex items-center justify-between " >

      <button
        type="button"
        className="w-[36.8px] h-[36.8px] bg-[#ffffff] hover:border-solid hover:border-2  hover:border-[#6F2295] border-none rounded-[5.02px] p-[10.04px]"
        onClick={() => handleUserView(row)}
      >

        <svg id="Layer_1" style={{ fill: "#6F2295" }} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>eye-glyph</title><path d="M320,256a64,64,0,1,1-64-64A64.07,64.07,0,0,1,320,256Zm189.81,9.42C460.86,364.89,363.6,426.67,256,426.67S51.14,364.89,2.19,265.42a21.33,21.33,0,0,1,0-18.83C51.14,147.11,148.4,85.33,256,85.33s204.86,61.78,253.81,161.25A21.33,21.33,0,0,1,509.81,265.42ZM362.67,256A106.67,106.67,0,1,0,256,362.67,106.79,106.79,0,0,0,362.67,256Z" /></svg>

      </button>

      <button
        type="button"
        className="w-[36.8px] h-[36.8px] bg-[#ffffff] hover:border-solid hover:border-2  hover:border-[#6F2295] border-none rounded-[5.02px] p-[10.04px]"
        onClick={() => handleBlock(row?._id, row?.isBlocked)}
        disabled={row?.reportCount < 5 ? true : false}
        style={{
          cursor: row?.reportCount < 5 ? 'not-allowed' : 'pointer',
        }}

      >
        {/* {row?.isBlocked ? <img src={Unblock} alt="" className="w-5 h-auto" /> : <img src={Block} alt="" className="w-5 h-auto" />} */}
        <svg class="svg-icon" style={{ fill: row?.reportCount >= 5 ? (row?.isBlocked ? "#f93441" : "#6F2295") : ("#757575") }} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M511.232 85.504q176.64 0 302.08 124.8t125.44 301.44-125.44 301.44-302.08 124.8-301.44-124.8-124.8-301.44 124.8-301.44 301.44-124.8zm-266.24 426.24q0 110.08 78.08 188.16t188.16 78.08q64 0 122.88-30.72l-358.4-358.4q-30.72 58.88-30.72 122.88zm501.76 122.88q32-61.44 32-122.88 0-110.08-78.72-188.16t-188.8-78.08q-62.72 0-121.6 30.72z" /></svg>
      </button>


      <button
        type="button"
        className="w-[36.8px] h-[36.8px] bg-[#ffffff] hover:border-solid hover:border-2  hover:border-[#6F2295] border-none rounded-[5.02px] p-[10.04px]"
        onClick={() => handleDelete(row?._id)}

      >
        {/* <img src={Trash} alt="" className="w-5 h-auto" /> */}
        <svg class="svg-icon" style={{ fill: "#6F2295" }} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M1009.524364 156.555636A36.235636 36.235636 0 0 0 977.454545 139.217455V139.636364H721.454545V46.708364c0-12.962909-5.166545-24.273455-14.289454-33.512728-9.076364-9.076364-21.364364-13.195636-34.280727-13.195636H347.392c-12.846545 0-23.133091 4.119273-32.256 13.195636l-0.069818 0.116364c-0.116364 0.116364 0.581818 0.186182 0.488727 0.302545-8.797091 8.797091-13.009455 20.689455-13.009454 33.093819V139.636364H45.172364C24.576 139.636364 7.819636 153.949091 7.819636 174.545455c0 13.009455 6.702545 22.877091 16.802909 28.858181 5.818182 4.119273 12.869818 6.050909 20.549819 6.050909h69.306181L159.418182 975.476364c0.791273 12.613818 6.330182 25.949091 15.569454 34.443636 8.773818 8.750545 20.433455 14.08 32.907637 14.08H812.450909c12.869818 0 24.971636-5.469091 34.048-14.568727 9.053091-9.053091 14.196364-22.644364 14.103273-35.328L905.565091 209.454545h69.306182c0.698182 0 1.373091 0.488727 2.071272 0.488728 0.418909 0.279273 0.814545 0.884364 1.233455 0.884363 12.8 0 24.506182-4.840727 31.255273-15.732363a36.235636 36.235636 0 0 0 0.093091-38.539637zM418.909091 826.181818a34.909091 34.909091 0 1 1-69.818182 0v-488.727273a34.909091 34.909091 0 1 1 69.818182 0v488.727273z m232.727273 0a34.909091 34.909091 0 1 1-69.818182 0v-488.727273a34.909091 34.909091 0 1 1 69.818182 0v488.727273zM651.636364 139.636364H372.363636V69.818182h279.272728v69.818182z" /></svg>
      </button>

      {/* <button
        type="button"
        className="w-[36.8px] h-[36.8px] flex justify-center items-center bg-[#ffffff] hover:border-solid hover:border-2  hover:border-[#6F2295] border-none rounded-[5.02px] p-[10.04px]"
        onClick={() => {


          handleChatClick(row)
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#57267b" viewBox="0 0 256 256"><path d="M232,96a16,16,0,0,0-16-16H184V48a16,16,0,0,0-16-16H40A16,16,0,0,0,24,48V176a8,8,0,0,0,13,6.22L72,154V184a16,16,0,0,0,16,16h93.59L219,230.22a8,8,0,0,0,5,1.78,8,8,0,0,0,8-8Zm-42.55,89.78a8,8,0,0,0-5-1.78H88V152h80a16,16,0,0,0,16-16V96h32V207.25Z"></path></svg>
      </button> */}

    </div>
  );


  // new code for chat 
  const handleChange = (getUserValue) => {
    debugger
    debugger
    if (getUserValue?._id) {
      setSelectedUserList(prevList => {
        if (prevList.some(obj => obj._id === getUserValue._id)) {
          // If _id exists, remove it from the list
          return prevList.filter(objVal => objVal._id !== getUserValue._id);
        } else {
          // If _id doesn't exist, add it to the list
          return [...prevList, getUserValue];
        }
      });
    }
  }

  const checkCondition = (getValue) => {
    if (getValue && getValue._id) {
      return selectedUserList.some(user => user._id === getValue._id);
    }
    return false;
  }

  const selectAllUsers = (getValue) => {
    if (getValue) {
      setSelectedUserList(tableData)
    } else {
      setSelectedUserList([])
    }
  }

  const messageModalClose = () => {
    setSendMessageModalCheck(false)
    setMessageValue(null)
  }


  const clearAllMedia = () => {
    setChatMedia('')
    setMediaPreview('')
    setMediaType('')
    setDisableSendBtn(false)
  }

  async function uploadMedia(formData) {
    try {

      const response = await fetch(process.env.REACT_APP_BASE_URL + 'admin/uploadFile', {
        method: 'POST',
        body: formData,
        // headers: {
        //   'Authorization': `Bearer ${sessionStorage.getItem("better-dating-app-auth-token")}`
        // },
        headers: {
          'Authorization': `${sessionStorage.getItem("better-dating-app-auth-token")}`
        },
      });


      const result = await response.json();
      if (response.ok) {
        console.log("Uploaded file URL:", result.fileUrl);
        // Update the state with the new video URL from the upload response
        // Reset or clear the 'uploadNewVideoLen' after successful upload if necessary




        return {
          data: {
            data: {
              url: result.fileUrl
            }
          }
        }
      } else {
        // Handle HTTP error responses

        throw new Error(result.message || 'Failed to upload video');
      }

    }
    catch (error) {
      console.error("Error uploading file:", error.message);
      // Implement your error handling logic here
      toast.error("Unsuccessfull file upload", {
        autoClose: 5000
      });
    }

  }

  return (
    <>

      <Paper
        className="    overflow-y-clip xl:max-h-[80%] 2xl:max-h-[89vh] 2xl:w-full xl:w-[100%] xl:h-[85vh] 2xl:h-[85vh] p-6 pb-16 flex flex-col gap-6"
        style={{ borderRadius: "12px" }}
        component={Paper}
      >

        <div className=''>

          <div className='flex flex-wrap items-center justify-between mb-3 '>
            {/* child 1 */}
            <div className='text-xl text-[#57267B] font-semibold'>User Management</div>

            {/* child 2 */}
            <div className='flex items-center  xl:mt-5  gap-4 2xl:ml-auto'>

              <FormControl variant="outlined" className="w-full" style={{ width: "110px", maxWidth: '150px' }}>
                <InputLabel
                  sx={{
                    color: 'rgba(0, 0, 0, 0.54)', // default label color
                    '&.Mui-focused': {
                      color: '#57267B', // label color when the input is focused
                    },
                  }}
                >
                  User
                </InputLabel>
                <Select
                  label="User"
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(0, 0, 0, 0.23)', // default border color
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(0, 0, 0, 0.54)', // border color on hover
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#57267B', // border color when the input is focused
                    },
                    '& .MuiSelect-icon': {
                      color: 'rgba(0, 0, 0, 0.54)', // default icon color
                    },
                    '&.Mui-focused .MuiSelect-icon': {
                      color: '#57267B', // icon color when the input is focused
                    },
                  }}
                  onChange={(event) => {
                    console.log(event.target.value);
                    if (event.target.value === "Yes") {
                      setFilter({ ...filter, isSubscribtionActive: true });
                      console.log({ ...filter, isSubscribtionActive: true });
                    }
                    else if (event.target.value === "No") {
                      setFilter({ ...filter, isSubscribtionActive: false });
                      console.log({ ...filter, isSubscribtionActive: false });
                    }
                    else {
                      if (filter?.reportCount) {
                        setFilter({ reportCount: filter?.reportCount });
                        console.log({ reportCount: filter?.reportCount });
                      }
                      else {
                        setFilter({});
                        console.log({});
                      }
                    }

                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="Yes">Subscribed</MenuItem>
                  <MenuItem value="No">Un-Subscribed</MenuItem>


                </Select>
              </FormControl>

              <FormControl variant="outlined" className="w-full" style={{ width: "110px", maxWidth: '150px' }}>
                <InputLabel
                  sx={{
                    color: 'rgba(0, 0, 0, 0.54)', // default label color
                    '&.Mui-focused': {
                      color: '#57267B', // label color when the input is focused
                    },
                  }}
                >
                  Flagged
                </InputLabel>
                <Select
                  label="Flagged"
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(0, 0, 0, 0.23)', // default border color
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(0, 0, 0, 0.54)', // border color on hover
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#57267B', // border color when the input is focused
                    },
                    '& .MuiSelect-icon': {
                      color: 'rgba(0, 0, 0, 0.54)', // default icon color
                    },
                    '&.Mui-focused .MuiSelect-icon': {
                      color: '#57267B', // icon color when the input is focused
                    },
                  }}
                  onChange={(event) => {
                    if (event.target.value === "Yes") {
                      setFilter({ ...filter, reportCount: { $gte: 5 } });
                      console.log({ ...filter, reportCount: { $gte: 5 } });
                    }
                    else if (event.target.value === "No") {
                      setFilter({ ...filter, reportCount: { $lt: 5 } });
                      console.log({ ...filter, reportCount: { $lt: 5 } });
                    }
                    else {
                      if (filter?.isSubscribtionActive === true || filter?.isSubscribtionActive === false) {
                        setFilter({ isSubscribtionActive: filter?.isSubscribtionActive });
                        console.log({ isSubscribtionActive: filter?.isSubscribtionActive });
                      }
                      else {
                        setFilter({});
                        console.log({});
                      }
                    }
                    // debugger;

                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="Yes">Flagged</MenuItem>
                  <MenuItem value="No">Un-Flagged</MenuItem>


                </Select>
              </FormControl>

              <FormControl variant="outlined" className="w-full" style={{ width: "110px", maxWidth: '150px' }}>
                <InputLabel
                  sx={{
                    color: 'rgba(0, 0, 0, 0.54)', // default label color
                    '&.Mui-focused': {
                      color: '#57267B', // label color when the input is focused
                    },
                  }}
                >
                  Rating
                </InputLabel>
                <Select
                  label="Rating"
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(0, 0, 0, 0.23)', // default border color
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(0, 0, 0, 0.54)', // border color on hover
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#57267B', // border color when the input is focused
                    },
                    '& .MuiSelect-icon': {
                      color: 'rgba(0, 0, 0, 0.54)', // default icon color
                    },
                    '&.Mui-focused .MuiSelect-icon': {
                      color: '#57267B', // icon color when the input is focused
                    },
                  }}
                  onChange={(event) => {
                    if (event.target.value === "belowAverage") {
                      setRatingFilter({ avgDateTrait: { $lte: 5 } });
                      console.log({ avgDateTrait: { $lte: 5 } });
                    }
                    else if (event.target.value === "Average") {
                      setRatingFilter({ avgDateTrait: { $gt: 5, $lte: 7 } });
                      console.log({ avgDateTrait: { $gt: 5, $lte: 7 } });
                    }
                    else if (event.target.value === "Good") {
                      setRatingFilter({ avgDateTrait: { $gt: 7 } });
                      console.log({ avgDateTrait: { $gt: 7 } });
                    }
                    else {
                      setRatingFilter({});
                      console.log({});
                    }
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="belowAverage">Below Average</MenuItem>
                  <MenuItem value="Average">Average</MenuItem>
                  <MenuItem value="Good">Good</MenuItem>


                </Select>
              </FormControl>

              <FormControl variant="outlined" className="w-full" style={{ width: "110px", maxWidth: '150px' }}>
                <InputLabel
                  sx={{
                    color: 'rgba(0, 0, 0, 0.54)', // default label color
                    '&.Mui-focused': {
                      color: '#57267B', // label color when the input is focused
                    },
                  }}
                >
                  Interest
                </InputLabel>
                <Select
                  label="Interest"
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(0, 0, 0, 0.23)', // default border color
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(0, 0, 0, 0.54)', // border color on hover
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#57267B', // border color when the input is focused
                    },
                    '& .MuiSelect-icon': {
                      color: 'rgba(0, 0, 0, 0.54)', // default icon color
                    },
                    '&.Mui-focused .MuiSelect-icon': {
                      color: '#57267B', // icon color when the input is focused
                    },
                  }}
                  onChange={(event) => {
                    console.log(event.target.value);
                    if (event.target.value === "Casual Dating") {
                      setInterestFilter({ interest: "Casual Dating" });
                      console.log({ interest: "Casual Dating" });
                    }
                    else if (event.target.value === "Long-Term Relationship") {
                      setInterestFilter({ interest: "Long-Term Relationship" });
                      console.log({ interest: "Long-Term Relationship" });
                    }
                    else if (event.target.value === "Marriage") {
                      setInterestFilter({ interest: "Marriage" });
                      console.log({ interest: "Marriage" });
                    }
                    else if (event.target.value === "Friendship") {
                      setInterestFilter({ interest: "Friendship" });
                      console.log({ interest: "Friendship" });
                    }
                    else if (event.target.value === "Open to possibilities") {
                      setInterestFilter({ interest: "Open to possibilities" });
                      console.log({ interest: "Open to possibilities" });
                    }
                    else {
                      setInterestFilter({});
                      console.log({});
                    }

                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="Casual Dating">Casual Dating</MenuItem>
                  <MenuItem value="Long-Term Relationship">Long-Term Relationship</MenuItem>
                  <MenuItem value="Marriage">Marriage</MenuItem>
                  <MenuItem value="Friendship">Friendship</MenuItem>
                  <MenuItem value="Open to possibilities">Open to possibilities</MenuItem>


                </Select>
              </FormControl>


              <div className="text-[#000000] w-36 h-[3.5rem]">
                <input
                  value={filterSearch}
                  onChange={(e) => {
                    setFilterSearch(e.target.value);
                  }}
                  className="h-full font-medium placeholder:text-base	 placeholder:text-[#8B8B8B]" // Tailwind class to style placeholder text color
                  placeholder="Search "
                />
              </div>


              {/* <div onClick={applyFilter} className=" h-[3.5rem] flex items-center">
            <Button title={"Apply"} icon={<FilterAltIcon />} color={"#ffffff"} background={"#57267B"} />

          </div>

          <div onClick={() => {
            // setFilteredSessions(allSessions);

            setTableData(allTableData);
            setFilterSubscribed("");
            setFilterFlagged("");
            setFilterRating("");
            setFilterSearch("");
            setFilterInterest("");
          }} className=" h-[3.5rem] flex items-center">
            <Button title={"Reset"} icon={<RestartAltIcon />} color={"#ffffff"} background={"#57267B"} />

          </div> */}

              <div onClick={() => {
                OpenModalMethod()
              }}>
                <Button title={"+ Promotion"} color={"#ffffff"} background={"#57267B"} />

              </div>


            </div>
          </div>


          {/* <Button title={"+ Add Modules"} color={"#ffffff"} background={"#57267B"} /> */}


          {!loading && <div className="w-auto overflow-x-auto card">
            {tableData?.length !== 1000 ?
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -50 }}
                transition={{ duration: 0.8 }}
              >


                {/* my table */}

                {/* my heading */}
                <div className=' flex justify-between 2xl:mt-16 border-b border-b-1 p-4 text-md font-bold text-[#57267B] border-[#E0E0E0]'>
                  <div className='flex-grow ' style={{ flexBasis: '8%' }}>
                    <Checkbox
                      checked={selectAllCheckBox}
                      onChange={() => {
                        setSelectAllCheckBox(!selectAllCheckBox)
                        selectAllUsers(!selectAllCheckBox)
                      }
                      }
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </div>
                  <div className='flex-grow ' style={{ flexBasis: '8%' }}>S. No.</div>
                  <div className='flex-grow ' style={{ flexBasis: '10%' }}>Name <span onClick={() => { sortByName() }} className="hover:cursor-pointer"><SwapVertIcon /></span></div>
                  <div className='flex-grow' style={{ flexBasis: '8%' }}>Age <span onClick={() => { sortByAge() }} className="hover:cursor-pointer"><SwapVertIcon /></span></div>
                  <div className='flex-grow px-3' style={{ flexBasis: '8%' }}>Gender</div>
                  <div className='flex-grow px-3' style={{ flexBasis: '12%' }}>Interest</div>
                  <div className='flex-grow' style={{ flexBasis: '20%' }}>Location</div>
                  <div className='flex-grow text-center' style={{ flexBasis: '8%' }}>Registration Date</div>
                  <div className='flex-grow text-center' style={{ flexBasis: '8%' }}>Avg Rating</div>
                  <div className='flex-grow text-center' style={{ flexBasis: '8%' }}>Subscribed User</div>
                  <div className='flex-grow text-center' style={{ flexBasis: '10%' }}>Action</div>
                </div>


                <div style={{ scrollbarWidth: "none" }} className="xl:pb-20 overflow-y-auto mt-2 h-[calc(100vh-22rem)]">
                  {/* my table body */}
                  {tableData?.map((row, index) => (
                    <div className=' flex justify-between mt-6 border-b border-b-1 p-4 text-md font-medium border-[#E0E0E0]'>
                      <div className='flex-grow ' style={{ flexBasis: '8%' }}>
                        <Checkbox
                          checked={checkCondition(row)}
                          onChange={() => { handleChange(row) }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </div>
                      <div className='flex-grow ' style={{ flexBasis: '8%' }}>{(currentPage - 1) * 10 + index + 1}</div>
                      <div className='flex-grow ' style={{ flexBasis: '10%' }}>{row?.userName || row?.fullName}</div>
                      <div className='flex-grow' style={{ flexBasis: '8%' }}>{row?.age}</div>
                      <div className='flex-grow px-3' style={{ flexBasis: '8%' }}>{row?.gender}</div>
                      <div className='flex-grow px-3' style={{ flexBasis: '12%' }}>{row?.interest}</div>
                      <div className='flex-grow' style={{ flexBasis: '20%' }}>{row?.userLocation}</div>
                      <div className='flex-grow text-center' style={{ flexBasis: '8%' }}><Moment format="DD MMM, YYYY" withTitle>{row?.createdAt}</Moment></div>
                      <div className='flex-grow text-center' style={{ flexBasis: '8%' }}>{row?.avg_date_trait_rating}</div>
                      <div className='flex-grow text-center' style={{ flexBasis: '8%' }}>{row?.subscribed_user}</div>
                      <div className='flex-grow' style={{ flexBasis: '10%' }}>{renderActionButtons(row)}</div>


                    </div>
                  ))}


                  {tableData.length === 0 ? <div className=' text-[#666464] mt-1 text-center'>Result not found!</div> : null}

                </div>






              </motion.div> : <p className="flex justify-center mt-[150px] text-[18px] font-[600] text-[#888888]">No data found</p>}
          </div >


          }
          {
            userView && (
              <GenericModal
                open={isModalOpen}
                title={title[0]}
                handleClose={handleCloseModal}
                content={<UserView
                  userData={userData}
                />}
                isCross={true}
                width={"800px"}
                height={"500px"}
              />
            )
          }

          {
            isDel && (
              <GenericModal
                open={isModalOpen}
                handleClose={handleCloseModal}
                content={
                  <Delete
                    setIsDeleted={setIsDeleted}
                    setIsOpenModal={setIsModalOpen}
                    id={delId}
                    userType={"users"}
                  />
                }

                isCross={false}
                width={"391px"}
                height={"328px"}
              />
            )
          }

          {
            isBlockModal && (
              <GenericModal
                open={isModalOpen}
                handleClose={handleCloseModal}
                content={
                  <UserBlock
                    setIsBlocked={setIsBlocked}
                    userId={userId}
                    setIsOpenModal={setIsModalOpen}
                    yes={yes}
                    userType={"user"}
                  />
                }
                isCross={false}
                width={"391px"}
                height={yes ? "270px" : "308px"}
              />
            )
          }


        </div>

      </Paper >

      <Stack spacing={2} direction="row" justifyContent="center" alignItems="center" marginY={2}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(event, page) => {
            setAllTableData([]);
            setTableData([]);
            setCurrentPage(page)

          }}

        />
      </Stack>

      <GenericModal
        open={sendMessageModalCheck}
        title="Add Promotional Message"
        // caption={"Please fill below details to add a new module."}
        onClose={messageModalClose}
        style={{ height: '60%', maxWidth: '42rem' }}
        content={
          //  <AddQuestionnaire
          //    onCancel={handleAddQuestionaireModal}
          //    handleFetchQuestionnaire={fetchQuestionnaires}
          //  />
          <>
            <TextField
              fullWidth
              label="Message..."
              multiline
              rows={4}
              value={messageValue}
              onChange={(e) => setMessageValue(e.target.value)}
              sx={{
                '& label.Mui-focused': {
                  color: '#57267B',
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: '#57267B',
                  },
                },
              }}
            />
            <div
              style={{ color: "#a1a0a3", paddingTop:'2px' }}
              className="pkj_pointer"
              onClick={() => {
                document.getElementById("chat_media-user").click();
              }}
            >
              <span style={{marginTop:'2rem'}}>Attachment</span>&nbsp;&nbsp;
              <PermMediaIcon />
            </div>
            <input
              type="file"
              style={{ display: "none" }}
              id="chat_media-user"
              accept=".mp4, .jpeg, .jpg, .png"
              onChange={(e) => {
                setMediaPreview(e.target.files["0"]?.name);
                setMediaType(e.target.files["0"].type);
                setForPreviewOnly(URL.createObjectURL(e.target.files[0]))
                // dispatch(loaderActions.toggleLoading(true));
                setDisableSendBtn(true);
                const toastId = toast.info(<LoadingToast message="Uploading Media" />, {
                  autoClose: false,
                  closeOnClick: false,
                  draggable: false,
                  hideProgressBar: true,
                  icon: false, // Disable the default icon

                });
                const formData = new FormData();
                formData.append("file", e.target.files[0]);

                uploadMedia(formData)
                  .then((res) => {
                    debugger
                    debugger
                    console.log('res?.data?.data', res?.data?.data)
                    setChatMedia(res?.data?.data);
                    toast.success("Media upload successfully!!", {
                      autoClose: 5000
                    });

                  })
                  .catch((error) => {
                    toast.error(error?.message, {
                      toastId: "chat message media upload",
                    });
                    setChatMedia("");
                    setMediaPreview("");
                    setMediaType("");
                  })
                  .finally(() => {
                    // dispatch(loaderActions.toggleLoading(false))
                    toast.dismiss(toastId);
                    setDisableSendBtn(false);
                  }
                  );
                e.target.value = "";
              }}
            />

            <div style={{
              textAlign: 'center',
              justifyContent: 'center',
              display: 'flex'
            }}>
              {mediaType.includes("image") && <img src={previewOnly} alt="preview" id="preview-img" style={{
                height: '8rem',
                width: '8rem'
              }} />}
              {mediaType.includes("video") && (
                <video controls style={{
                  height: '10rem',
                  width: '18rem'
                }} >
                  <source src={previewOnly} id="preview-vid" />
                </video>
              )}
              {mediaType && <CancelTwoToneIcon sx={{ cursor: 'pointer' }} onClick={clearAllMedia} />}
            </div>
            <div style={{ display: 'flex', justifyContent: 'end', margin: '1rem' }}>
              <div onClick={() => {
                messageModalClose()
              }}>
                <Button title={"Cancel"} color={"black"} background={"white"} />
              </div>
              <div onClick={() => {
                if (!messageValue) {
                  toast.error("Please type your text !", {
                    autoClose: 5000
                  });
                  return;
                }
                handleMultipleChatClicks()
              }} style={{ paddingLeft: '7px' }}>
                <Button title={"Send"} color={"#ffffff"} background={"#57267B"} disabled={disableSendBtn} />
              </div>
            </div>
          </>
          // <div>Hello</div>
          // <QuestionnaireForm close={handleAddModuleModal} pushToAllModuleData={pushToAllModuleData} />
          // <div>this is me!!</div>
        }
      />

    </>

  );
}
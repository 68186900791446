import React from "react";
import { Modal, Box, Typography } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { fontWeight } from "@mui/system";

const GenericModal2 = ({ open, onClose, title,   content, caption,width,height,handleClose  }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="generic-modal-title"
      className="flex justify-center items-center p-4 overflow-y-clip my-auto xl:max-h-[110vh]"
      sx={{ zIndex: 50, }}>
      <Box sx={{scrollbarWidth:"none"}} className="w-9/12 2xl:h-4/6 xl:h-5/6 mx-auto overflow-hidden bg-white rounded-lg shadow-lg">
        <div className="flex justify-end px-4 py-2">
          <CloseIcon onClick={onClose} className="cursor-pointer" />
        </div>
        <div className="  h-full">
          {content}
        </div>
      </Box>
    </Modal>
  );
};

export default GenericModal2;

import API from "../urlConstants";

export const loginAdmin=async(data)=>{
    const response=await fetch(process.env.REACT_APP_BASE_URL+"admin/login",{
        method:"POST",
        headers:{
            "Content-Type":"application/json"
        },
        body: JSON.stringify(data)
    });
    return await response.json();
    
    
}


export const SendResetLink=async(data)=>{
    
    const response=await fetch(process.env.REACT_APP_BASE_URL+"admin/forgetPassword",{
        method:"POST",
        headers:{
            "Content-Type":"application/json"
        },
        body: JSON.stringify(data)
    });
    return await response.json();
    
    
}


export const changePassword=async(data,token)=>{
    
    const response=await fetch(process.env.REACT_APP_BASE_URL+"admin/changePassword",{
        method:"POST",
        headers:{
            "Content-Type":"application/json",
            "authorization":token
        },
        body: JSON.stringify(data)
    });
    return await response.json();
    
    
}
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
// import { getGratitudeOfUser } from '../../../API/userManagementApi';
// import { toast } from 'react-toastify';



const UserView = ({ userData }) => {
    const [gratData, setGratData] = useState({});
    console.log("this is user data",userData);
    

    const allQuestions=["How important is your faith and that of a partner?","Which factors are important for you in a relationship?",
      "What type of relationship are you looking for?",
      "What do you like to do for fun?",
      "What are your deal breakers?",
      "What would you want to do on a first date?",
      "What are the core values?",
      "Which traits are most important in a good partner?"
    ]

    useEffect(() => {
        const fetchUserData = () => {

            // getGratitudeOfUser(userData?._id)
            //     .then((response) => setGratData(response?.data?.data))
            //     .catch((error) => toast.error(error?.message, {
            //         autoClose: 5000, toastId: "fgdg"
            //     }));

        };

        fetchUserData();
    }, [userData]);

    return (
        <div style={{scrollbarWidth:"none"}} className='flex flex-col  pt-2 ml-11 justify-center items-center mx-auto w-[90%] font-semibold  overflow-y-auto'>
            


            {/* personal details / */}

            <div className="flex w-full mt-[64rem]">
                <div className="flex flex-col w-1/2 h-12 gap-1">
                    <p className="h-6 font-semibold text-black text-4" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Name</p>
                    <p className="h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>{userData?.fullName}</p>
                </div>
                <div className="flex flex-col w-1/2 h-12 gap-1">
                    <p className="h-6 font-semibold text-black text-4" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Date of registration </p>
                    <p className="h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}> {userData?.createdAt ? (<Moment format="DD MMM, YYYY" withTitle>{userData?.createdAt}</Moment>) : "NA"}</p>
                </div>
                <div className="flex flex-col w-1/2 h-12 gap-1">
                    <p className="h-6 font-semibold text-black text-4" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Average Date Trait</p>
                    <p className="h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}> {userData?.avg_date_trait_rating}</p>
                </div>
                
            </div>

            <div className="flex w-full my-3">
                <div className="flex flex-col w-1/2 h-12 gap-1">
                    <p className="h-6 font-semibold text-black text-4" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Age</p>
                    <p className="break-words h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>{userData?.age}</p>
                </div>
                <div className="flex flex-col w-1/2 h-12 gap-1">
                    <p className="h-6 font-semibold text-black text-4" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Gender</p>
                    <p className="h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>{userData?.gender}</p>
                </div>
                <div className="flex flex-col w-1/2 h-12 gap-1">
                    <p className="h-6 font-semibold text-black text-4" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>User Location</p>
                    <p className="h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}> {userData?.userLocation}</p>
                </div>
            </div>

            <div className="flex w-full my-3">
                <div className="flex flex-col w-1/2 h-12 gap-1">
                    <p className="h-6 font-semibold text-black text-4" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Email Address</p>
                    <p className="break-words h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>{userData?.email}</p>
                </div>
                <div className="flex flex-col w-1/2 h-12 gap-1">
                    <p className="h-6 font-semibold text-black text-4" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Contact Number</p>
                    <p className="h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>{userData?.mobileNumber ? userData?.mobileNumber : "NA"}</p>
                </div>
                <div className="flex flex-col w-1/2 h-12 gap-1">
                    <p className="h-6 font-semibold text-black text-4" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Subscribed</p>
                    <p className="h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}> {userData?.subscribed_user}</p>
                </div>
            </div>

            <div className="flex w-full my-3">
                <div className="flex flex-col w-1/2 h-12 gap-1">
                    <p className="h-6 font-semibold text-black text-4" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Subscription Name</p>
                    <p className="break-words h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>{userData?.subscriptionsDetails?.subscriptionName}</p>
                </div>
                <div className="flex flex-col w-1/2 h-12 gap-1">
                    <p className="h-6 font-semibold text-black text-4" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Date of Purchase</p>
                    <p className="h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>{userData?.subscriptionsDetails?.dateOfPurchase ? (<Moment format="DD MMM, YYYY" withTitle>{userData?.subscriptionsDetails?.dateOfPurchase}</Moment>) : "NA"}</p>
                </div>
                <div className="flex flex-col w-1/2 h-12 gap-1">
                    <p className="h-6 font-semibold text-black text-4" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Amount Paid</p>
                    <p className="h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>{userData?.subscriptionsDetails?.amountPaid}</p>
                </div>
            </div>

            <div className="flex w-full my-3 ">
                <div className="flex flex-col w-1/2 h-12 gap-1">
                    <p className="h-6 font-semibold text-black text-4" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Mode of Payment</p>
                    <p className="break-words h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>{userData?.subscriptionsDetails?.modeOfPayment}</p>
                </div>
                <div className="flex flex-col w-1/2 h-12 gap-1">
                    <p className="h-6 font-semibold text-black text-4" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Expiration Date</p>
                    <p className="h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>{userData?.subscriptionsDetails?.expirationDate ? (<Moment format="DD MMM, YYYY" withTitle>{userData?.subscriptionsDetails?.expirationDate}</Moment>) : "NA"}</p>
                </div>
                <div className="flex flex-col w-1/2  h-12 gap-1">
                    <p className="h-6 font-semibold text-black text-4" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Renewal Date</p>
                    <p className="h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>{userData?.subscriptionsDetails?.expirationDate ? (<Moment format="DD MMM, YYYY" withTitle>{userData?.subscriptionsDetails?.expirationDate}</Moment>) : "NA"}</p>
                </div>
            </div>

            <div className="flex w-full my-3">
            <div className="h-6 underline text-[#57267b] mx-auto font-semibold text-black text-4" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Onboarding Details</div>
            </div>

            
            {allQuestions?.map((eachQuestion,index)=>{
                return (
                     <div className="flex w-full my-3 ">    
                       <div className="flex flex-col w-full h-fit gap-1">
                         <p className="h-6 font-semibold text-black text-4 mb-1" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>{`${index+1}. ${eachQuestion}`}</p>
                         {userData?.onboardingQuestions[index]?.selected?.map((eachAnswer)=>{
                            return (
                                <p className="break-words my-1 h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}> <CheckCircleRoundedIcon sx={{fill:"#57267b"}}/>{` ${eachAnswer}`}</p>
                            )

                         })}
                         
                        
                       </div>
                     </div>  
                  )
            })}
                
            

        </div>
    );
}

UserView.propTypes = {
    userData: PropTypes.shape({
        _id: PropTypes.string,
        userName: PropTypes.string,
        createdAt: PropTypes.string,
        email: PropTypes.string,
        mobileNumber: PropTypes.string,
    }),
}

export default UserView;

import React, { useEffect, useState } from "react";
import { Doughnut, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js';
import Cards from "../../commonComponents/cards";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import GenericModal2 from "../../commonComponents/GenericModal/GenericModal2";
import BarChart2 from "../../commonComponents/barChart2.js";
import SubscriptionsBarChart from "../../commonComponents/subscriptionsBarChart.js";
// import BarChart2 from "../../commonComponents/barChart3.js";
import Paper from "@mui/material/Paper";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { toast } from "react-toastify";
import { HashLoader } from "react-spinners";
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import DataUsageIcon from '@mui/icons-material/DataUsage';



const LoadingToast = ({ message }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {/* <ClipLoader size={16} color={"#6F2395"} loading={true} /> */}
      <HashLoader
    color="#6f2395"
    size={28}
  />
      <span style={{ marginLeft: '10px' }}>{message}</span>
      
    </div>
  );
  



// Registering the necessary components for Chart.js
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

const Subscriptions = () => {

    const navigate=useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalComp, setModalComp] = useState(<div>Loading...</div>);
    const [totalUsers,setTotalUsers]=useState(0);
    const [piChartArray,setPiChartArray]=useState([0,0]);

    const [filterType,setFilterType]=useState("weekly");

    const allPayment2=[];
   
    
    const setLabels = () => {
        return ["Subscribed", "Standard"];
    };


    const statusColors = () => {
        return ["#57267b", "#fff"];
    };

    const setDoghnutArray = () => {
        return piChartArray;
    }


    const options = {
        cutout: 70,
        boxWidth: 100,
        // responsive: true,
        maintainAspectRatio: false,
        devicePixelRatio: 4,
        plugins: {
            legend: {
                display: false,
                position: "right",
                labels: {
                    boxWidth: 50,
                    padding: 25,
                    // paddingBottom: 20,
                },
            },
        },
    };

    const handleModalToggle = () => {
        setIsModalOpen(!isModalOpen);
    }

    const [allPayment, setAllPayment] = useState([]);

   // This function converts an array of objects into a CSV string
   function convertToCSV(objArray) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    // Add headers
    let header = Object.keys(array[0]).join(',');
    str += header + '\r\n';

    // Loop through the array of objects and for each object turn the values into a CSV string
    for (let i = 0; i < array.length; i++) {
        let line = '';
        for (let index in array[i]) {
            if (line !== '') line += ',';

            let value = array[i][index];
            if (value instanceof Date) {
                value = value.toISOString();
            }
            line += value;
        }

        str += line + '\r\n';
    }

    return str;
}


async function makeDownloadWork(periodd="weekly") {
    const toastId = toast.info(<LoadingToast message="Downloading CSV" />, {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        hideProgressBar: true,
        icon: false, // Disable the default icon

      });
    try{
        
        const data = {
            period: periodd
        };
    
        let response = await fetch(process.env.REACT_APP_BASE_URL + "subscribe/exportSubscription", {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            }
        });
    
        response = await response.json();
    
        // Directly pass the CSV string without further parsing
        downloadCSV(response?.data);
    
        toast.dismiss(toastId);
        toast.success("Downloaded successfully!!", {
            autoClose: 5000
          });
        


    }
    catch(error){
        toast.dismiss(toastId);
        toast.error("Error downloading file:", error.message,{
            autoClose: 5000
        })

    }
    
}

// This function triggers the download of a CSV file
function downloadCSV(data, filename = 'export.csv') {
    if (!data.match(/^data:text\/csv/i)) {
      data = 'data:text/csv;charset=utf-8,' + data;
    }

    const dataUri = encodeURI(data);
    const link = document.createElement('a');
    link.setAttribute('href', dataUri);
    link.setAttribute('download', filename);
    link.click();
    console.log("CSV Download initiated")
}


    //   format your date
    function formatDate(date) {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = months[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();
        return `${month} ${day}, ${year}`;
    }

    // Data for the Doughnut Chart
    const dataDoughnut = {
        labels: setLabels(),
        datasets: [
            {
                data: setDoghnutArray(),
                backgroundColor: statusColors(),
                borderColor: "#795195",
                borderWidth: 1
            }
        ]
    };

    // Data for the Bar Chart
    const dataBar = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: '',
                data: [12, 19, 3, 5, 2, 3],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)'
                ],
                borderWidth: 1
            }
        ]
    };

    //get the table data
    const setTableData=async(periodd="weekly")=>{
        const data={
            period:periodd
        }
        let response=await fetch(process.env.REACT_APP_BASE_URL+"subscribe/allSubscription",{
            method:"POST",
            body:JSON.stringify(data),
            headers:{
                "Content-Type":"application/json",
                'Authorization': `${sessionStorage.getItem("better-dating-app-auth-token")}`
            }
        })
        response=await response.json(response);

        if(response?.status===425){
        localStorage.clear();
        sessionStorage.clear();
        navigate("/login");
        return;
      }
        
        setAllPayment(response?.data);
        


    }
    useEffect(()=>{
        setTableData(filterType);

    },[filterType])



    async function setPiChartData(){
        let response=await fetch(process.env.REACT_APP_BASE_URL+"subscribe/activeSubscription",
            {
                method:"GET",
                headers:{
                    "Content-Type":"application/json",
                    'Authorization': `${sessionStorage.getItem("better-dating-app-auth-token")}`
                }
            }
        );
        response=await response.json()
        if(response?.status===425){
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
            return;
          }
        setTotalUsers(response?.data?.totalUsers);
        const tempArray=[];
        tempArray.push(response?.data?.subscribed);
        tempArray.push(response?.data?.unsubscribed);
        setPiChartArray(tempArray);
    }

    useEffect(()=>{
        setPiChartData();

    },[])

    const BarData = [1, 3, 65, 3, 1];

    return (

        <>
            <Paper
                className=" overflow-y-clip  xl:max-h-[80%] 2xl:max-h-[89vh] overflow-x-auto 2xl:w-full xl:w-[100%] xl:h-[85vh] 2xl:h-[85vh] p-6  flex flex-col gap-6 overflowGlobal"
                style={{ borderRadius: "12px" }}
                component={Paper}
            >


              {/* main body  */}
             <div className="h-full  flex flex-col" >

                 {/* Title and View All Features section */}
               <div className='h-fit flex items-center justify-between mb-3'>

                   {/* Subscription Management Title */}
                   <div className='text-xl text-[#57267B] font-semibold '>Subscription Management</div>

                   {/* View All Features button */}
                   <div onClick={() => {    
                               
                          setModalComp(
                              <div className="  gap-4 h-full   flex   pb-6 ">
                                  <div className=" px-4 py-5 basis-1/2 h-[97.5%]">
                                    {/* pink box */}
                                    <div className="bg-pink-50 h-full pt-3 border border-[#FF4775] rounded-lg">
                                        <div className="text-[#FF4775] text-center font-bold text-xl">Standard User</div>
                                        <div className=" mt-4 h-[90%] px-8 font-bold	">
                                            <div className="list-disc flex flex-col gap-3">
                                                <div><CheckCircleIcon sx={{fill:"#FF4775"}} /> 15 min secure call</div>
                                                <div><CheckCircleIcon sx={{fill:"#FF4775"}} /> See 2 traits</div>
                                                <div><CheckCircleIcon sx={{fill:"#FF4775"}}/> 1x Super Swipe/Week</div>
                                                <div><CheckCircleIcon sx={{fill:"#FF4775"}}/>  1x Boost/Week</div>
                                            </div>
                                        </div>
                                    </div>                  
                                  </div>
                                  <div className=" px-4 py-5 basis-1/2 h-[97.5%]">
                                     {/* purple box */}
                                     <div className="bg-purple-50 h-full pt-3 border border-[#6F2395] rounded-lg">
                                        <div className="text-[#6F2395] text-center font-bold text-xl">Subscribed User</div>
                                        <div className=" mt-4 h-[90%] px-8 font-bo">
                                            <ul className="list-disc flex flex-col gap-3">
                                            <div><CheckCircleIcon sx={{fill:"#6F2395"}}/>Call and Video Chat</div>
                                                <div><CheckCircleIcon sx={{fill:"#6F2395"}}/>See all 4 Traits</div>
                                                <div><CheckCircleIcon sx={{fill:"#6F2395"}}/>5x Super Swipe/Week</div>
                                                <div><CheckCircleIcon sx={{fill:"#6F2395"}}/>5x Boost/Week</div>
                                                <div><CheckCircleIcon sx={{fill:"#6F2395"}}/>+1 Mulligen/Month</div>
                                            </ul>
                                        </div>
                                    </div> 
                                  </div>

                              </div>
                          )
                          handleModalToggle();
                          }
                          } className="bg-[#57267B] text-white p-1 w-32 hover:bg-[#795195] hover:cursor-pointer text-center rounded-md">View Features</div>
                
               </div>


                {/* Charts section */}
                <div className="basis-2/5 flex gap-12 2xl:mt-8  justify-center ">

                    <div className="basis-1/3 rounded-lg   shadow-md flex flex-col  h-full">

                       {totalUsers===0?(<div  className="w-full  flex justify-center items-center h-full"><DataUsageIcon fill={"#F3E8FF"} sx={{ width: '256px', height: '256px', color:"#e3e7ed" }} /></div>):(<div className="xl:py-4  flex-grow">
                            <Doughnut data={dataDoughnut} options={options} />
                        </div>)}
                         
                        
                        <div className={` items-center flex justify-center  2xl:left-[27.7%] mt-auto  xl:left-[28rem] 2xl:top-[32%] xl:top-[30.5%] `}>
                                 <div className="flex gap-3 items-center">
                                  <p className="text-[#A1A0A3]">Total Users</p>
                                  <h3 className="font-bold text-[#57267B]	text-2xl">{totalUsers}</h3>
                                 </div>
                               
                         </div>
                        
                    </div>


                    <div className="flex-grow pl-1 rounded-lg  shadow-md   h-full">
                        <div className="flex-grow  h-full   ">
                            <SubscriptionsBarChart pkjComp={allPayment}/>
                        </div>

                    </div>


                </div>

                

                {/* my payment table */}
                <div className="w-full xl:mt-6 2xl:mt-10 flex flex-row-reverse ">
                    <div className="mb-2 flex gap-8 ">
                      <FormControl variant="outlined" className="mt-4 w-40">
                           <InputLabel
                             sx={{
                               color: 'rgba(0, 0, 0, 0.54)', // default label color
                               '&.Mui-focused': {
                                 color: '#57267B', // label color when the input is focused
                               },
                             }}
                           >
                             Filter By
                           </InputLabel>
                           <Select
                             label="Filter By"
                             sx={{
                               '& .MuiOutlinedInput-notchedOutline': {
                                 borderColor: 'rgba(0, 0, 0, 0.23)', // default border color
                               },
                               '&:hover .MuiOutlinedInput-notchedOutline': {
                                 borderColor: 'rgba(0, 0, 0, 0.54)', // border color on hover
                               },
                               '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                 borderColor: '#57267B', // border color when the input is focused
                               },
                               '& .MuiSelect-icon': {
                                 color: 'rgba(0, 0, 0, 0.54)', // default icon color
                               },
                               '&.Mui-focused .MuiSelect-icon': {
                                 color: '#57267B', // icon color when the input is focused
                               },
                             }}
                             onChange={(event) => {
                               console.log(event.target.value);
                               setFilterType(event.target.value);
                            //    if (event.target.value) {
                            //      setBtn1({ BackgroundColor: "#57267b", textColor: "#ffffff" })
                            //    }
                            //    setUploadNewVideoLen(event.target.value);
                             }}
                             value={filterType}
                           >
                             <MenuItem value="weekly">Week</MenuItem>
                             <MenuItem value="monthly">Month</MenuItem>
                             <MenuItem value="yearly">Year</MenuItem>
               
               
                           </Select>
                      </FormControl>

                      <div className="flex items-center justify-center h-full">
                       <div onClick={()=>{
                        
                        makeDownloadWork(filterType)
                        }}  className='2xl:text-sm h-full bg-[#57267B] hover:cursor-pointer xl:text-xs p-1 text-white rounded flex items-center justify-center px-3'><p>Download CSV</p></div>
                      </div>
          
                    

                    </div>
                   </div>
                <div style={{scrollbarWidth:"none"}} className="flex-grow pt-2   w-full rounded-lg   p-1  ">
                   
                  

                   <div style={{scrollbarWidth:"none"}} className="w-full ">
                            <TableContainer >
                                <Table className="" size="small" aria-label="a dense table">
                                    <TableHead >
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 },display:"flex" }} >
                                            <TableCell className="basis-[15%]" align="center" sx={{ fontWeight: "bold", color: "#57267B" }}>S. No.</TableCell>
                                            <TableCell className="basis-[15%]" align="left" sx={{ fontWeight: "bold", color: "#57267B" }}>Username</TableCell>
                                            <TableCell className="basis-[25%]" sx={{ fontWeight: "bold", color: "#57267B" }} align="left">Email</TableCell>
                                            <TableCell className="basis-[15%]" sx={{ fontWeight: "bold", color: "#57267B" }} align="left">Date Subscribed</TableCell>
                                            <TableCell className="basis-[15%]" sx={{ fontWeight: "bold", color: "#57267B" }} align="left">End Date of Subscription</TableCell>
                                            <TableCell className="basis-[15%]" sx={{ fontWeight: "bold", color: "#57267B" }} align="center">Amount Paid</TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>

                            <Divider sx={{marginTop:"3px", marginBottom:"3px"}} variant="middle"  />


                            {
                                allPayment.length===0?(
                                    <>
                                       
                                       <div className="w-full mt-2 text-center text-[#71717A] text-base">No Data!</div>
                                    </>

                                    
                                ):(
                                    <div className=" " style={{overflowY: 'auto', scrollbarWidth:"none" }}>
                                <TableContainer>
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableBody >
                                            {allPayment?.map((eachPayment, index) => (
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 },display:"flex" }}>
                                                    <TableCell className=" basis-[15%]"  align="center" >{index + 1}</TableCell>
                                                    <TableCell className="basis-[15%]" align="left" >{eachPayment?.username}</TableCell>
                                                    <TableCell className="basis-[25%]" align="left">{eachPayment?.email}</TableCell>
                                                    <TableCell className="basis-[15%]" align="left">{eachPayment?.startDate}</TableCell>
                                                    <TableCell className="basis-[15%]" align="left">{eachPayment?.endDate}</TableCell>
                                                    <TableCell className="basis-[15%]" align="center">${eachPayment?.amountPaid}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                                )
                            }
                            

                

                   

                   </div>
                   




                </div>

             </div>


            </Paper>

            {isModalOpen &&
                <GenericModal2
                    open={isModalOpen}
                    handleClose={handleModalToggle}
                    onClose={handleModalToggle}
                    content={modalComp}
                    isCross={true}
                    width="738px"
                    height="1200px"
                />}

        </>


    );
}

export default Subscriptions;

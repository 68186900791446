import React from 'react';
import { useNavigate } from 'react-router-dom';
import heart from '../../assests/icons/heart.png';
import login from '../../assests/icons/login.png';
import user from '../../assests/icons/user.png';
import dollar_circle from '../../assests/icons/dollar_circle.png';
import topology1 from '../../assests/icons/topo1.png';
import ArrowRight from '../../assests/icons/arrowRight.png';

const Cards = ({ backgroundColor, darkColor, imgIndex, cardData,apiData }) => {
  const cardName = [
    'Total Registered users',
    'Subscribed Users',
    'Average Feedback',
    'Total Revenue',
    'Standard Users',
    'Subscribed Users'
  ];
  const iconArr = [heart, login, user, dollar_circle,user,dollar_circle];
  const navigate = useNavigate();

  const handleNavigation = (index) => {
    const routes = ['/user_management', '/subscriptions', '/feedback', '/subscriptions','/user_management','/user_management'];
    navigate(routes[index]);
  };

  return (
    <div
      className="relative flex flex-col justify-between h-full bg-cover bg-center rounded-xl shadow-md overflow-hidden"
      style={{ backgroundColor }}
    >
      <img src={topology1} alt="" className="absolute top-0 left-0 w-full h-full" />
      <div className="p-4 z-10">
        <div className="inline-flex items-center">
          <div className="w-13 h-13 flex justify-center items-center rounded-full border border-white">
            <img src={iconArr[imgIndex]} alt="" className="w-7 h-7" />
          </div>
        </div>
        <div className="mt-4">
          <p className="text-white text-sm font-medium">
            {cardName[imgIndex]}
          </p>
          <p className="text-white text-6xl font-extrabold">
            {apiData}
          </p>
        </div>
      </div>
      <div
        type="button"
        className="z-10 	  w-full h-11 flex items-center justify-between px-4 py-3 cursor-pointer"
        style={{ backgroundColor: darkColor }}
        onClick={() => handleNavigation(imgIndex)}
      >
 
          <span  className="text-white text-sm font-medium ">
          View All
        </span>
        <img src={ArrowRight} alt="" className="w-6 h-6" />
          
        
      </div>
    </div>
  );
};

export default Cards;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'
import Highcharts, { color } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';



const BarChart2 = ({ totalUser, pkjComp, subscribedUserData, standardUserData }) => {
  const chartContainerRef = useRef(null);
  const [containerSize, setContainerSize] = useState({ width: '50%', height: '80%' });
  const [filterBy, setFilterBy] = useState('week');
  const [chartXaxisLabel, setChartXaxisLabel] = useState(['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']);
  const [chartData, setChartData] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [chartData2, setChartData2] = useState([0, 0, 0, 0, 0, 0, 0]);


  useEffect(() => {

    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setContainerSize({
          width: entry.contentRect.width,
          height: entry.contentRect.height
        });
      }
    });

    if (chartContainerRef.current) {
      resizeObserver.observe(chartContainerRef.current);
    }

    return () => {
      if (chartContainerRef.current) {
        resizeObserver.unobserve(chartContainerRef.current);
      }
    };
  }, []);

  useEffect(() => {

    // for standard user
    let tempArray = new Array(7).fill(0);
    standardUserData?.week?.map((eachDay) => {
      tempArray[eachDay?.period?.day - 1] = eachDay?.count;
    })
    setChartData(tempArray);

    // for subscribed user
    let tempArray2 = new Array(7).fill(0);
    subscribedUserData?.week?.map((eachDay) => {
      tempArray2[eachDay?.period?.day - 1] = eachDay?.count;
    })
    setChartData2(tempArray2);
  }, [])

  function setLabelToXaxis(labelName) {
    debugger
    debugger
    switch (labelName) {
      case "year":
        setChartXaxisLabel(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']);
        // for standard user
        let tempArray = new Array(12).fill(0);
        standardUserData?.year?.map((eachMonth) => {
          tempArray[eachMonth?.period?.month - 1] = eachMonth?.count;
        })
        setChartData(tempArray);

        // for subscribed user
        let tempArray2 = new Array(12).fill(0);
        subscribedUserData?.year?.map((eachMonth) => {
          tempArray2[eachMonth?.period?.month - 1] = eachMonth?.count;
        })
        setChartData2(tempArray2);
        break;
      case "week":
        let weeklyXLabel = new Array;
        setChartXaxisLabel(['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']);
        // for standard user
        let tempArrayweek = new Array(7).fill(0);
        standardUserData?.week?.map((eachDay) => {
          tempArrayweek[eachDay?.period?.day - 1] = eachDay?.count;
          tempArrayweek[eachDay?.period?.day - 1] = eachDay?.count;
        })
        setChartData(tempArrayweek);

        // for subscribed user
        let tempArrayweek2 = new Array(7).fill(0);
        subscribedUserData?.week?.map((eachDay) => {
          tempArrayweek2[eachDay?.period?.day - 1] = eachDay?.count;
        })
        setChartData2(tempArrayweek2);
        break;

      case "month":
        setChartXaxisLabel(['Week-1', 'Week-2', 'Week-3', 'Week-4']);
        // for standard user
        let tempArrayQuarter = new Array(4).fill(0);


        standardUserData?.month?.map((eachWeek) => {

          tempArrayQuarter[eachWeek?.period?.week - 1] = eachWeek?.count;
        })
        setChartData(tempArrayQuarter);

        // for subscribed user
        let tempArrayQuarter2 = new Array(4).fill(0);
        subscribedUserData?.month?.map((eachWeek) => {
          tempArrayQuarter2[eachWeek?.period?.week - 1] = eachWeek?.count;
        })
        setChartData2(tempArrayQuarter2);
        break;


      case "yearss":
        setChartXaxisLabel(['2024', '2025', '2026', '2027', '2028', '2029', '2030']);

        let tempArrayYear = new Array(7).fill(0);
        standardUserData?.yearly?.map((eachYear) => {

          tempArrayYear[2024 - eachYear?.period?.year] = eachYear?.count;
        })
        setChartData(tempArrayYear);

        // for subscribed user
        let tempArrayYear2 = new Array(7).fill(0);
        subscribedUserData?.yearly?.map((eachYear) => {
          tempArrayYear2[2024 - eachYear?.period?.year] = eachYear?.count;
        })
        setChartData2(tempArrayYear2);


        break;
      default:
        setChartXaxisLabel(['2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030']);
        setChartData([40, 26, 10, 66, 27, 14, 68, 90, 46, 90]);
        break;
    }
  }

  const options = {
    credits: {
      enabled: false, // Disable the Highcharts logo
    },
    chart: {
      type: 'column',
      height: containerSize.height, // Use state value here for height
      width: containerSize.width  // Use state value here for width
    },
    title: {
      text: '',
      align: 'left'
    },
    subtitle: {
      text: '',
      align: 'left'
    },
    xAxis: {
      categories: chartXaxisLabel,
      crosshair: true,
      accessibility: {
        description: 'Time Period',
      },
    },
    yAxis: {
      visible: false,
      min: 0,
      title: {
        text: 'Number of Users'
      }
    },
    tooltip: {
      valueSuffix: ' Users'
    },
    plotOptions: {
      column: {
        pointPadding: 0.25,
        borderWidth: 0
      }
    },
    legend: {
      align: 'center',
      verticalAlign: 'top',
      layout: 'horizontal',
      enabled: true,
    },
    series: [
      {
        name: 'Standard',
        data: chartData,
        color: "#57267B"
      },
      {
        name: 'Subscribed',
        data: chartData2,
        color: "#F1426D"
      }
    ]
  };

  return (
    <div className={`  bg-[#FFFCFC]  rounded-xl `}>
      {/* begin::Body */}
      <div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
        {/* begin::Hidden */}
        <div className='flex justify-between flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3 '>
          {pkjComp && <div>
            <div className='me-2 '>
              <PeopleOutlineIcon style={{ fill: '#57267b' }} />
              <div className='font-semibold	 text-[#57267B] d-block fs-3'>Users</div>
            </div>
            <div className={`fw-bold fs-3 text-[#57267B]`}>{totalUser}</div>
          </div>}
          <div className=' flex items-center '>
            {/* begin::Label */}
            <span className='fs-7 fw-bolder text-gray-700 pe-4 text-nowrap d-none d-xxl-block'>
              Filter By:
            </span>
            {/* end::Label */}
            {/* begin::Select */}
            <select
              className="form-select form-select-sm form-select-solid w-100px w-xxl-125px"
              data-control="select2"
              data-placeholder="Monthly"
              data-hide-search="true"
              value={filterBy}
              onChange={(e) => {
                setFilterBy(e.target.value);
                setLabelToXaxis(e.target.value);
              }}
            >
              {/* <option value="week">Weekly</option> */}
              <option value="week">Week</option>
              <option value="month">Month</option>
              <option value="year">Year</option>
            </select>
            {/* end::Select  */}
          </div>
        </div>
        {/* end::Hidden */}
        {/* begin::Chart */}
        <div ref={chartContainerRef} className=' max-h-80 xl:h-52 rounded-lg pb-2'>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
          />
        </div>
        {/* end::Chart */}
      </div>
    </div>
  )
}



export default BarChart2;

import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    userName: "Admin",
    email:"your@email.com",
    profileImg:null,
    newNotification:0,
    unReadCount:0
};




const userDetailsSlice = createSlice({
    name: "userDetails",
    initialState,
    reducers: {
        setUserDetails(state, action) {
            const { userName, email,profileImg } = action.payload.userDetails;
            state.userName = userName;
            state.email = email;
            state.profileImg = profileImg;
            return state;
        },
        setNewNotification(state,action){
         
            const { newNotification } = action.payload.userDetails;
           
            state.newNotification=newNotification;
            return state;
        },
        setUnReadCount(state,action){
            const unReadCount=action.payload.unReadCount;
            state.unReadCount=unReadCount;
            return state;
        }
    },
});

export const userDetailsActions = userDetailsSlice.actions;
export default userDetailsSlice.reducer;
import './App.css';
import React, { useEffect } from 'react';
import Login from './App/modules/login/login';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { authActions } from './App/store/slices/auth';
import { jwtDecode } from "jwt-decode";
import AuthenticatedRoutes from './App/Routers/AuthenticatedRoutes';
import Forgetpassword from './App/modules/forgot_password/forget_password';
import ResetNewPassword from './App/modules/ResetNewPassword/ResetNewPassword';


function App() {
  const authToken = sessionStorage.getItem("better-dating-app-auth-token");

  useEffect(()=>{
    console.log("i'm in.");
    return ()=>{sessionStorage.clear()}
  },[])
  // const isLoggedIn = useSelector((state) => state.auth.loggedIn);
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // useEffect(() => {
  //   try {
  //     jwtDecode(authToken);
  //   } catch (error) {
  //     dispatch(authActions.logout());
  //     sessionStorage.clear();
  //     navigate('/login');
  //   }
  // }, [authToken]);
  return (
    <div className="App">
      <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgetPassword" element={<Forgetpassword/>} />
          <Route path="/resetpass/:token" element={ResetNewPassword}/>
          <Route path="*" element={<AuthenticatedRoutes/>} />
        </Routes>
      <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
      />

    </div>
  );
}

export default App;

import React, { Fragment, useEffect, useRef, useState } from "react";
import "./referral.css";
import Paper from "@mui/material/Paper";
import { Button, Grid, TextField, DialogContentText, ToggleButton, ToggleButtonGroup , Stack , Pagination } from '@mui/material';
import { getAllFeedbackData } from "../../API/apis/feedbackApi";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import DatePicker from "react-datepicker"
import moment from 'moment';
import { getAllReferralsByAdmin, saveRefferal, saveRefferalStatus } from "../../API/apis/referralApi";
import Moment from "react-moment";



const CustomInput = React.forwardRef(({ value, onClick, placeholder }, ref) => (
    <TextField
        variant="outlined"
        onClick={onClick}
        value={value}
        autoComplete="off"
        label={placeholder}
        inputRef={ref}
        InputProps={{
            placeholder: "Expiration Date",
            sx: {
                height: '3.5em', // Desired height
                // Target the input element itself:
                '& input': {
                    height: '100%', // Ensures the input takes up the full height of the parent
                    boxSizing: 'border-box', // Ensures padding is included in the height
                    padding: '0 14px', // Padding for the input
                },
            },
        }}
        // Adjust these styles to match your design:
        sx={{
            width: '100%', // Make sure it's 100% to fill the parent width
            maxWidth: '100%', // Match the max-width with other filters
            maxHeight: '3.5em',
            height: '3.5em',
            '& input::placeholder': {
                color: '#888', // Change placeholder color
                opacity: 1, // Ensure placeholder is fully opaque
                fontSize: '16px'
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.23)', // default border color
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#57267B', // border color on hover
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#57267B', // border color when input is focused
            },
            // Ensure the padding and font-size are appropriate:
            '& .MuiOutlinedInput-input': {
                padding: '10px',
                fontSize: '0.875rem', // Adjust font-size if necessary to match other filters
            },
        }}
    />
));

CustomInput.displayName = 'CustomInput';

const ReferralCodePage = () => {

    const navigate = useNavigate();
    const [allReferrals, setAllReferrals] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [open, setOpen] = useState(false);

    // For the Refferal Form -
    const [referralCodeName, setReferralCodeName] = useState('');
    const [expirationDate, setExpirationDate] = useState(null);
    const [maxUseCount, setMaxCount] = useState(null);
    const [referralCode, setReferralCode] = useState('');
    const textFieldRef = useRef(null);

    const [alignment, setAlignment] = useState('web');

    const handleChange = async (status, getRercords) => {
        let sendTheObject = {
            'refId': getRercords?._id,
            'refStatus': status
        }
        const data = await saveRefferalStatus(sendTheObject);

        if (data.status >= 400) {
            toast.error(data?.message, { autoClose: 5000 });
        } else {
            toast.success("Referral status successfully!!", {
                autoClose: 5000,
            });
            getAllReferralsByAdminMethod()
        }
    };


    // Popup open and close here ----
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setReferralCodeName('');
        setExpirationDate(null);
        setMaxCount(null);
        setReferralCode('');
        setOpen(false);
    };

    // Save Refferal Code 
    const saveReferral = async () => {
        if (!referralCodeName) { toast.error("Referral Code Name is required", { autoClose: 5000 }); return; }
        if (!expirationDate) { toast.error("Expiration Date is required", { autoClose: 5000 }); return; }
        if (!referralCode) { toast.error("Referral Code is required", { autoClose: 5000 }); return; }
        if (!maxUseCount) { toast.error("Referral Count is required", { autoClose: 5000 }); return; }
        let sendTheObject = {
            'referralCode': referralCode,
            'isAdmin': true,
            'expirationDate': expirationDate,
            'maxUseCount': maxUseCount,
            'referralCodeName': referralCodeName
        }
        const data = await saveRefferal(sendTheObject);

        if (data.status >= 400) {
            toast.error(data?.message, { autoClose: 5000 });
        } else {
            toast.success("Referral created successfully!!", {
                autoClose: 5000,
            });
            handleClose();
            getAllReferralsByAdminMethod();
        }
    }

    // Get referrals code here -
    const getAllReferralsByAdminMethod = async () => {
        const params = {
            page: currentPage,
            limit: 7
        };
        const { data } = await getAllReferralsByAdmin(params);
        console.log(data)
        if (data?.referrals?.length > 0) {
            debugger
            debugger
            setAllReferrals(data?.referrals)
            const count = Math.ceil(data?.totalRecords / 7);
            setTotalPages(count);
        }
    }

    // Generate referral code after saving the user
    const generate6CharCode = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'; // Uppercase letters and digits
        let code = '';
        for (let i = 0; i < 6; i++) {
            code += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return code;
    };

    useEffect(() => {
        if (referralCode && textFieldRef.current) {
            // Set focus to the TextField
            textFieldRef.current.focus();
        }
    }, [referralCode]);

    useEffect(() => {
        getAllReferralsByAdminMethod()
    }, [])
    const referralCodeGenerate = () => {
        // Generate the referral code
        let referralCode = generate6CharCode();
        setReferralCode(referralCode?.toString())
    }

    useEffect(() => {
        getAllReferralsByAdminMethod();
      }, [currentPage ]);
    

    return (
        <>
            <Paper className="overflow-hidden xl:max-h-[94%] 2xl:max-h-[84vh] overflow-x-auto 2xl:w-full xl:w-[100%] xl:h-[97%] 2xl:h-[86vh] flex flex-col gap-6 rounded-xl">
                {/* <div className=" h-full"> */}
                    {/* Component Title */}
                    <div className="headerSetting">
                        <div className='text-xl text-[#57267B]  mt-8 ml-6 font-semibold '>Referral Codes</div>
                        <div className='text-xl text-[#57267B]  mt-8 ml-6 font-semibold mr1R'>
                            <Button variant="contained" endIcon={<AddBoxIcon />} sx={{
                                background: '#57267b',
                                textTransform: 'capitalize',
                                '&:hover': {
                                    backgroundColor: '#57267b', // Change this to your desired hover color
                                },
                            }} onClick={handleClickOpen}>
                                Add Referral
                            </Button>
                        </div>
                    </div>
                    {/* Table */}
                    <div className=" flex flex-col h-[75%] mt-11 mx-6 ">
                        {/* Table Head */}
                        <div className="flex items-center text-md font-bold text-[#57267B] border-b border-gray-200">
                            <div className='flex-grow p-2' style={{ flexBasis: '30%' }}>Code Name</div>
                            <div className='flex-grow p-2' style={{ flexBasis: '30%' }}>Referral Code</div>
                            <div className='flex-grow p-2 text-center' style={{ flexBasis: '20%' }}>Count</div>
                            <div className='flex-grow p-2 text-center' style={{ flexBasis: '30%' }}>Expiration Date</div>
                            {/* <div className='flex-grow p-2 text-center' style={{ flexBasis: '30%' }}>Status</div> */}
                            <div className='flex-grow p-2 text-center' style={{ flexBasis: '30%' }}>Action</div>
                        </div>

                        {/* Table Content */}
                        {allReferrals.length !== 0 ? (<div style={{ scrollbarWidth: "none" }} className="flex-grow overflow-y-auto ">
                            {allReferrals?.map((reff, index) => (
                                <div key={index} className="flex  mt-3 items-center text-md font-medium border-b border-gray-200">
                                    <div className='flex-grow p-2' style={{ flexBasis: '30%' }}>{reff?.referralCodeName}</div>
                                    <div className='flex-grow p-2' style={{ flexBasis: '30%' }}>{reff?.referralCode}</div>
                                    <div className='flex-grow p-2 text-center' style={{ flexBasis: '20%' }}>{reff?.maxUseCount? reff?.maxUseCount : 'Unlimited'}</div>
                                    <div className='flex-grow p-2 text-center' style={{ flexBasis: '30%' }}>
                                        {
                                            reff?.expirationDate ? <Moment format="DD MMM, YYYY" withTitle>{reff?.expirationDate}</Moment> : 'No'
                                        }
                                    </div>
                                    <div className='flex-grow p-2 text-center' style={{ flexBasis: '30%' }}>
                                        <ToggleButtonGroup
                                            color="primary"
                                            value={reff?.refStatus}
                                            exclusive
                                            onChange={(e) => handleChange(e.target.value, reff)}
                                            aria-label="Platform"
                                        >
                                            <ToggleButton value="Active" sx={{ fontWeight: '700' }}>Active</ToggleButton>
                                            <ToggleButton value="InActive" sx={{ fontWeight: '700' }}>InActive</ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                    {/* <div className='flex-grow p-2 text-center' style={{ flexBasis: '30%' }}>{reff?.maxUseCount}</div> */}
                                </div>
                            ))}
                        </div>) : (<div className=" text-[#71717A] text-center text-sm mt-4">No Referral Found!</div>)}

                    </div>
                {/* </div> */}
            </Paper>

            <Stack spacing={2} direction="row" justifyContent="center" alignItems="center" marginY={1}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={(_event, page) => setCurrentPage(page)}
                />
            </Stack>


            <Fragment>
                <Dialog
                    fullWidth={true}
                    maxWidth={"lg"}
                    open={open}
                    onClose={handleClose}
                >
                    <DialogTitle>
                        <span style={{
                            fontFamily: 'sans-serif',
                            fontWeight: '700'
                        }}>Add New Referral</span>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {/* You can set my maximum width and whether to adapt or not. */}
                        </DialogContentText>
                        <div style={{ marginTop: '4rem', height: '20rem' }}>
                            <Grid container spacing={2}>
                                <Grid xs={6} sx={{ justifyContent: 'center', display: 'flex' }}>
                                    <div style={{ paddingBottom: '2rem' }}>
                                        <TextField label="Referral Code Name" id="outlined-size-normal" value={referralCodeName} onChange={(e) => setReferralCodeName(e.target.value)} />
                                    </div>

                                </Grid>
                                <Grid xs={6} sx={{ justifyContent: 'center', display: 'flex' }}>
                                    <FormControl sx={{ width: '77%' }}>
                                        <DatePicker
                                            selected={expirationDate}
                                            onChange={(date) => {
                                                console.log(moment(date).format(`YYYY-MM-DD`));

                                                setExpirationDate(date)
                                            }}
                                            selectsStart
                                            startDate={expirationDate}
                                            customInput={<CustomInput placeholder="" />}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid xs={6} sx={{ justifyContent: 'center', display: 'flex' }}>
                                    <div style={{ paddingBottom: '2rem', width: '22.4rem' }}>
                                        <TextField label="Referral Code" id="outlined-size-normal" inputRef={textFieldRef} value={referralCode} onChange={(e) => 
                                           { const value = e.target.value.toUpperCase();
                                            if (value.length <= 6) {
                                              setReferralCode(value);
                                            }}
                                        } />
                                    </div>
                                    <Button variant="contained" sx={{
                                        marginLeft: '3px',
                                        background: '#57267b',
                                        textTransform: 'capitalize',
                                        height: '3.5rem',
                                        '&:hover': {
                                            backgroundColor: '#57267b', // Change this to your desired hover color
                                        },
                                    }} onClick={referralCodeGenerate}>
                                        Generate
                                    </Button>

                                </Grid>
                                <div></div>
                                <Grid xs={6} sx={{ justifyContent: 'center', display: 'flex' }}>
                                    <div style={{ paddingBottom: '2rem' }}>
                                        <TextField label="Count" type="number" id="outlined-size-normal" onChange={(e) => setMaxCount(e.target.value)} />
                                    </div>
                                </Grid>

                            </Grid>
                        </div>


                    </DialogContent>
                    <DialogActions>
                        <div style={{ display: 'flex', }}>
                            <Button variant="contained" sx={{
                                background: '#57267b',
                                textTransform: 'capitalize',
                                '&:hover': {
                                    backgroundColor: '#57267b', // Change this to your desired hover color
                                },
                            }} onClick={handleClose}>
                                Close
                            </Button>
                            <div style={{ padding: '0.5rem' }}></div>
                            <Button variant="contained" sx={{
                                marginRight: '1rem',
                                background: '#57267b',
                                textTransform: 'capitalize',
                                '&:hover': {
                                    backgroundColor: '#57267b', // Change this to your desired hover color
                                },
                            }} onClick={saveReferral}>
                                Save
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
            </Fragment>

        </>

    );
};

export default ReferralCodePage;



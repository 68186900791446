import React, { useEffect, useState } from 'react'
import { getAllSessions, updateSesstionData } from '../../API/apis/sessionsApi';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import DatePicker from "react-datepicker"
import 'react-datepicker/dist/react-datepicker.css';
import TextField from '@mui/material/TextField';
// import Button from '../../commonComponents/Button/Button';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import "./sessionManagement.css"
import { Backdrop, CircularProgress, Pagination, Stack } from '@mui/material';
import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
import Paper from "@mui/material/Paper";
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Button from '@mui/material/Button';
import { color } from 'highcharts';

// import DatePicker from 'react-datepicker'; // You might need to install this package
// import 'react-datepicker/dist/react-datepicker.css'; // Import CSS for the date picker

const StyledPagination = styled(Pagination)(({ theme }) => ({
  '& .Mui-selected': {
    backgroundColor: '#57267b',
  },
  '& .MuiPaginationItem-root:hover': {
    backgroundColor: 'rgba(87, 38, 123, 0.6)', // Lighten color on hover
  },
  // Add more styles here as needed for different states
}));

const CustomInput = React.forwardRef(({ value, onClick, placeholder }, ref) => (
  <TextField
    variant="outlined"
    onClick={onClick}
    value={value}
    placeholder={placeholder}
    InputLabelProps={{
      shrink: true,
    }}
    inputRef={ref}
    // Adjust these styles to match your design:
    sx={{
      width: '100%', // Make sure it's 100% to fill the parent width
      maxWidth: '200px', // Match the max-width with other filters
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.23)', // default border color
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#57267B', // border color on hover
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#57267B', // border color when input is focused
      },
      // Ensure the padding and font-size are appropriate:
      '& .MuiOutlinedInput-input': {
        padding: '10px',
        fontSize: '0.875rem', // Adjust font-size if necessary to match other filters
      },
    }}
  />
));

CustomInput.displayName = 'CustomInput';


const DateTags = ({ timeSlots }) => {
  return (
    <div className='flex flex-col items-center'>
      {timeSlots?.map((eachSlot) => {

        let date = new Date(eachSlot);

        // Define an array for month names
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        // Extract date components
        let month = monthNames[date.getMonth()];
        let day = date.getDate();
        let year = date.getFullYear();
        let hour = date.getHours();
        let minute = date.getMinutes();
        let period = hour >= 12 ? 'PM' : 'AM';

        // Convert hour from 24-hour format to 12-hour format
        hour = hour % 12;
        hour = hour ? hour : 12; // the hour '0' should be '12'

        // Add leading zero to minutes if necessary
        minute = minute < 10 ? '0' + minute : minute;

        // Format the date string

        // const bookingDate = new Date(eachSlot?.date);
        // const formattedDate = bookingDate.toLocaleDateString('en-US', {
        //   month: 'long',
        //   day: 'numeric',
        //   year: 'numeric',
        // });
        return (
          <div className='flex text-sm bg-[#57267B] m-1 w-fit py-1 px-2 rounded-md text-white'>
            <div>{`${month} ${day}, ${year} at ${hour}:${minute} ${period}`}</div>
            <div><br /></div>
          </div>
        )
      })}
    </div>
  )
}


const SessionManagement = () => {

  const initialTime = new Date();
  const navigate = useNavigate();
  const [allSessions, setAllSessions] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [trainingTypeFilter, setTrainingTypeFilter] = useState("");
  const [traitTypeFilter, setTraitTypeFilter] = useState("");
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [myTrainingTypeFilter, setMyTrainingTypeFilter] = useState("");
  const [myTraitTypeFilter, setMyTraitTypeFilter] = useState("");
  // const [myStartDateFilter,setMyStartDateFilter]=useState("");

  // const [open, setOpen] = useState(false);
  
  // const loadStatusClose = () => {
  //   setOpen(false);
  // };
  // const loadStatusOpen = () => {
  //   setOpen(true);
  // };


  const applyFilter = () => {
    console.log("Training type: ", trainingTypeFilter);
    console.log("Trait type: ", traitTypeFilter);
    console.log(`start: ${startDate}, end: ${endDate}`);
    let myData = allSessions;
    //filtering Training Type
    myData = myData.filter((eachSessions) => {
      return eachSessions?.trainingType.includes(trainingTypeFilter);
    })
    //filtering Trait Type 
    myData = myData.filter((eachSessions) => {
      return eachSessions?.traitType.includes(traitTypeFilter);
    })


    // Filtering Date
    if (startDate !== "" && endDate !== "") {

      myData = myData.filter((eachSession) => {
        const sessionDates = eachSession.timeSlots.map((slot) => new Date(slot.date));
        const isWithinRange = sessionDates.some(
          (date) =>
            date >= startDate &&
            date <= new Date(endDate.setHours(23, 59, 59, 999)) // Set time to 23:59:59.999 for the end date
        );
        return isWithinRange;
      });



    }



    setFilteredSessions(myData);
  }

  async function fetchAllSessions(page = currentPage, limit = itemsPerPage) {
    const filters = {
      ...(myTrainingTypeFilter !== "" && { trainingType: myTrainingTypeFilter }),
      ...(myTraitTypeFilter !== "" && { moduleName: myTraitTypeFilter }),
    }

    if (startDate && endDate) {
      filters['toDate'] = moment(endDate).format(`YYYY-MM-DD`)
      filters['fromDate'] = moment(startDate).format(`YYYY-MM-DD`)
    }

    if (currentPage) {
      filters['page'] = currentPage
    }
    try {
      // loadStatusOpen()
      const response = await getAllSessions(page, limit, filters);
      // loadStatusClose()
      if (response?.status === 425) {
        localStorage.clear();
        sessionStorage.clear();
        navigate("/login");
        return;
      }
      if (response.status === 200) {
        setAllSessions(response?.data);
        setFilteredSessions(response?.data);
        const count = Math.ceil(response?.totalRecords / 10);
        setTotalPages(count);
      } else {
        // Handle non-200 responses here
        toast.error(`Error: ${response.message || 'Failed to fetch sessions'}`);
      }
    } catch (error) {
      // loadStatusClose()
      // Handle network errors or other exceptions here
      console.error('Error fetching sessions:', error);
      toast.error('Network error while fetching sessions');
    }
  }

  useEffect(() => {
    fetchAllSessions();
  }, [currentPage, itemsPerPage, myTrainingTypeFilter, myTraitTypeFilter, startDate, endDate]);


  const restAllFilter = () => {
    setAllSessions([]);
    setStartDate(null);
    setEndDate(null);
    setTrainingTypeFilter("");
    setTraitTypeFilter("");
    setFilteredSessions([]);
    setCurrentPage(1);
    setTotalPages(0);
    setItemsPerPage(10);
    setMyTrainingTypeFilter("");
    setMyTraitTypeFilter("");
    fetchAllSessions();
  }

  const revardedByNumber = async (getOtherValue) => {
    let sendObject = {
      "userId": getOtherValue?.uid,
      "id": getOtherValue?._id,
      "moduleName": getOtherValue?.moduleName,
      "trainingPointStatus":"Rewarded"
    }
    const result = await updateSesstionData(sendObject);
    // loadStatusClose()
    if (result.status === 200) {
      toast.success("Status updated sucessfully!", {
        autoClose: 5000
      });
      fetchAllSessions();
    } else {
      toast.error(result?.message, {
        autoClose: 5000
      });
    }
  }
  // loadStatusOpen()
  const handleChange = async (event, getOtherValue) => {
    debugger
    debugger
    let sendObject = {
      "userId": getOtherValue?.uid,
      "id": getOtherValue?._id,
      "moduleName": getOtherValue?.moduleName,
      "trainingStatus": event.target.value
      // "trainingPointStatus":"NotRewarded"
    }
    const result = await updateSesstionData(sendObject);
    // loadStatusClose()
    if (result.status === 200) {
      toast.success("Status updated sucessfully!", {
        autoClose: 5000
      });
      fetchAllSessions();
    } else {
      toast.error(result?.message, {
        autoClose: 5000
      });
    }

  };


  return (

    <>
      <Paper
        className=" overflow-y-clip xl:max-h-[80%] 2xl:max-h-[89vh] overflow-x-auto 2xl:w-full xl:w-[100%] xl:h-[85vh] 2xl:h-[85vh] p-6 pb-16 flex flex-col gap-6"
        style={{ borderRadius: "12px" }}
        component={Paper}
      >

        <div className=''>

          <div className='flex flex-wrap items-center justify-between mb-3 '>
            {/* child 1 */}
            <div className='text-xl text-[#57267B]  font-semibold'>Session Management</div>

            {/* child 2 */}
            <div className='flex items-center  xl:mt-5 gap-4 2xl:ml-auto'>

              <div className=' p-2  flex gap-1 border rounded-md border-[#cdcdcd]'>

                {/* Date Range Picker Start */}
                <FormControl variant="outlined" className="w-full" style={{ width: '120px', maxWidth: '150px' }}>
                  <span className='labelSetting'>Start Date </span>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                      console.log(moment(date).format(`YYYY-MM-DD`));

                      setStartDate(date)
                    }}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    customInput={<CustomInput placeholder="Start Date" />}


                  />
                </FormControl>

                {/* Date Range Picker End */}
                <FormControl variant="outlined" className="w-full" style={{ width: '120px', maxWidth: '150px' }}>
                  <span className='labelSetting'>End Date </span>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    customInput={<CustomInput placeholder="End Date" />}
                  />
                </FormControl>

              </div>

              {/* Session Type Filter */}

              <FormControl variant="outlined" className="w-full" style={{ width: "200px", maxWidth: '300px' }}>
                <InputLabel
                  sx={{
                    color: 'rgba(0, 0, 0, 0.54)', // default label color
                    '&.Mui-focused': {
                      color: '#57267B', // label color when the input is focused
                    },
                  }}
                >
                  Training Type
                </InputLabel>
                <Select
                  label="Upload Video"
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(0, 0, 0, 0.23)', // default border color
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(0, 0, 0, 0.54)', // border color on hover
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#57267B', // border color when the input is focused
                    },
                    '& .MuiSelect-icon': {
                      color: 'rgba(0, 0, 0, 0.54)', // default icon color
                    },
                    '&.Mui-focused .MuiSelect-icon': {
                      color: '#57267B', // icon color when the input is focused
                    },
                  }}
                  value={myTrainingTypeFilter}
                  onChange={(event) => {
                    console.log(event.target.value);
                    setMyTrainingTypeFilter(event.target.value);

                    if (event.target.value) {
                      setTrainingTypeFilter(event.target.value);


                      // //checking if already filtered
                      // if(filteredSessions.length<allSessions.length){
                      //   setFilteredSessions(filteredSessions.filter((eachSessions)=>{
                      //     return eachSessions?.trainingType.includes(trainingTypeFilter);   
                      //   }))  
                      // }
                      // else{
                      //   setFilteredSessions(allSessions.filter((eachSessions)=>{
                      //     return eachSessions?.trainingType.includes(trainingTypeFilter);   
                      //   }))  
                      // }



                    }

                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="Group">Group Training</MenuItem>
                  <MenuItem value="One-on-One">One-on-One Training</MenuItem>


                </Select>
              </FormControl>


              {/* Trait Type Filter */}
              <FormControl variant="outlined" className="w-full" style={{ width: "200px", maxWidth: '300px' }}>
                <InputLabel
                  sx={{
                    color: 'rgba(0, 0, 0, 0.54)', // default label color
                    '&.Mui-focused': {
                      color: '#57267B', // label color when the input is focused
                    },
                  }}
                >
                  Trait Type
                </InputLabel>
                <Select
                  label="trait typeee"
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(0, 0, 0, 0.23)', // default border color
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(0, 0, 0, 0.54)', // border color on hover
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#57267B', // border color when the input is focused
                    },
                    '& .MuiSelect-icon': {
                      color: 'rgba(0, 0, 0, 0.54)', // default icon color
                    },
                    '&.Mui-focused .MuiSelect-icon': {
                      color: '#57267B', // icon color when the input is focused
                    },
                  }}
                  value={myTraitTypeFilter}
                  onChange={(event) => {
                    console.log(event.target.value);
                    setMyTraitTypeFilter(event.target.value);


                    if (event.target.value) {
                      setTraitTypeFilter(event.target.value);
                    }
                    // setUploadNewVideoLen(event.target.value);
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="Empathetic">Empathetic</MenuItem>
                  <MenuItem value="healthyCommunication">Healthy Communication</MenuItem>
                  <MenuItem value="Good Listener">Good Listener</MenuItem>
                  <MenuItem value="Self Aware">Self Aware</MenuItem>
                  {/* <MenuItem value="Direct">Direct Communicator</MenuItem> */}

                </Select>
              </FormControl>
              <Button variant="contained" endIcon={<RestartAltIcon />} sx={{ background: '#57267b' }} onClick={restAllFilter}>
                Reset
              </Button>




              {/* <div onClick={()=>{
             
              applyFilter();

            }}>
              <Button title={"Apply"} icon={<FilterAltIcon />} color={"#ffffff"} background={"#57267B"} />

            </div> */}

              {/* <div onClick={() => {
              setFilteredSessions(allSessions);
              setTrainingTypeFilter("");
              setTraitTypeFilter("");
              setStartDate(new Date());
              setEndDate(new Date(new Date().getTime() + 100));
             



            }}>
              <Button title={"Reset"} icon={<RestartAltIcon />} color={"#ffffff"} background={"#57267B"} />

            </div> */}
            </div>

          </div>

          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.8 }}
          >
            {/* my table */}
            <div className=' flex justify-between 2xl:mt-16  border-b border-b-1 2xl:p-4 xl:pt-2 p-4 xl:mt-8 text-md font-bold text-[#57267B] border-[#E0E0E0]'>
              <div className='flex-grow ' style={{ flexBasis: '8%' }}>Username</div>
              {/* <div className='flex-grow ' style={{ flexBasis: '13%' }}>Order ID</div> */}
              <div className='flex-grow' style={{ flexBasis: '8%', textAlign: 'center' }}>Trait</div>
              <div className='flex-grow px-3' style={{ flexBasis: '15%', textAlign: 'center' }}>Booking Date</div>
              <div className='flex-grow' style={{ flexBasis: '8%', textAlign: 'center' }}>Training Type</div>
              <div className='flex-grow' style={{ flexBasis: '4%', textAlign: 'center' }}>Cost ($)</div>
              <div className='flex-grow' style={{ flexBasis: '8%', textAlign: 'center' }}>Status</div>
              <div className='flex-grow' style={{ flexBasis: '8%', textAlign: 'center' }}>Actions</div>
            </div>
            {/* Scrollable section */}
            <div style={{ scrollbarWidth: "none" }} className='mt-2 overflow-y-auto h-[calc(100vh-12rem)] pb-[12.7rem]' >
              <div>
                {filteredSessions?.map((eachSession, index) => {


                  return (
                    <div class=' flex justify-between border-b border-b-1 p-4 text-md font-medium border-[#E0E0E0]'>
                      <div className='flex-grow ' style={{ flexBasis: '8%' }}>{eachSession?.userName}</div>
                      {/* <div className='flex-grow ' style={{ flexBasis: '13%' }}>{eachSession?.orderId}</div> */}
                      <div className='flex-grow' style={{ flexBasis: '8%', textAlign: 'center' }}>{eachSession?.moduleName}</div>
                      <div className='flex-grow px-3' style={{ flexBasis: '15%', textAlign: 'center' }}><DateTags timeSlots={eachSession?.dates} /></div>
                      <div className='flex-grow' style={{ flexBasis: '8%', textAlign: 'center' }}>{eachSession?.trainingType} Training</div>
                      {/* <div className='flex-grow' style={{ flexBasis: '10%' }}>{eachSession?.trainingType === "One-on-One Training" ? ('$' + (eachSession?.timeSlots.length) * 15) : ("NA")}</div> */}
                      <div className='flex-grow' style={{ flexBasis: '4%', textAlign: 'center' }}>{eachSession?.cost}</div>
                      <div className='flex-grow' style={{ flexBasis: '8%', textAlign: 'center' }}>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                          {/* <InputLabel id="demo-select-small-label">Status</InputLabel> */}
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={eachSession?.trainingStatus ? eachSession?.trainingStatus : "NotCompleted"}
                            // label="Status"
                            onChange={(event) => handleChange(event, eachSession)}
                            disabled={eachSession?.trainingStatus == "Completed" && eachSession?.trainingPointStatus == "Rewarded" ? true : false}
                          >
                            <MenuItem value={"Completed"}>Completed</MenuItem>
                            <MenuItem value={"NotCompleted"}>Not Completed</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className='flex-grow' style={{ flexBasis: '8%', textAlign: 'center' }}>
                        {(() => {
                          if (eachSession?.trainingStatus == "Completed" && eachSession?.trainingPointStatus == "Rewarded") {
                            return (
                              <Button variant="contained" sx={{
                                '&.Mui-disabled': {
                                  backgroundColor: '#b0a7c5', // Custom background color when disabled
                                  color: '#ffffff',          // Custom text color when disabled
                                  textTransform: 'capitalize'
                                },
                              }} disabled>
                                Rewarded
                              </Button>
                            )
                          } else if (eachSession?.trainingStatus == "Completed" && eachSession?.trainingPointStatus == "NotRewarded") {
                            return (
                              <Button variant="contained" sx={{
                                background: '#57267b',
                                textTransform: 'capitalize',
                                '&:hover': {
                                  backgroundColor: '#57267b', // Change this to your desired hover color
                                },
                              }} onClick={() => revardedByNumber(eachSession)}>
                                Reward +3
                              </Button>
                            )
                          } else {
                            return (
                              <Button variant="contained" sx={{
                                '&.Mui-disabled': {
                                  backgroundColor: '#8d8899', // Custom background color when disabled
                                  color: '#ffffff',          // Custom text color when disabled
                                  textTransform: 'capitalize'
                                },
                              }} disabled>
                                Reward +3
                              </Button>
                            )
                          }
                        })()}

                      </div>
                    </div>
                  );
                })}

                {filteredSessions.length === 0 ? <div className=' text-[#666464] mt-1 text-center'>Result not found!</div> : null}
              </div>
            </div>

          </motion.div>



        </div>

      </Paper>

      <Stack spacing={2} direction="row" justifyContent="center" alignItems="center" marginY={2}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(_event, page) => setCurrentPage(page)}
        />
      </Stack>

      {/* <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}

    </>

  )
}

export default SessionManagement;                                                                                          
import React, { useEffect } from "react";
import List from "@mui/material/List";
import grid_view from "../../../assests/icons/grid_view.png";
import user from "../../../assests/icons/user.png";
// import coin from "../../../Assets/images/icons/coin.png";
import star from "../../../assests/icons/star.png";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// import setting from "../../../Assets/images/icons/setting.png";
import support from "../../../assests/icons/support.png";
import login from "../../../assests/icons/login.png";
import feedback from "../../../assests/icons/feedback.png";
import "../login/login.css";
import "./sidenav.css";
import SideNavItem from "./sidenavitem";
import { useNavigate } from "react-router";
import FeedbackIcon from '@mui/icons-material/Feedback';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SourceIcon from '@mui/icons-material/Source';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { getFirestore,doc,onSnapshot, updateDoc,  getDocs,collection, addDoc, serverTimestamp, Timestamp  } from "firebase/firestore";
import { db } from "../Chat/firebase.js";
import { userDetailsActions } from "../../store/slices/userDetails";
import ShareIcon from '@mui/icons-material/Share';
import { useSelector,useDispatch } from "react-redux";

const Sidenav = () => {

  const dispatch = useDispatch();

  useEffect(()=>{
    const userSupportCollection = collection(db, "userSupport");
    // Real-time listener for the userSupport collection
    const myEventListener=onSnapshot(userSupportCollection, (querySnapshot) => {
      const rooms = [];
      querySnapshot.forEach((doc) => {
        rooms.push({ id: doc.id, ...doc.data() });
      });
    
      // Filter rooms to get those with unread messages
      const totalUnReadCount = rooms.filter((eachRoom) => eachRoom?.lastMessage?.read === true);
    
      // Calculate the count of unread messages
      const unReadCount = totalUnReadCount.length;

      dispatch(userDetailsActions.setUnReadCount({unReadCount}));
    
      // Debugging and further actions with unReadCount
      console.log("Unread messages count:", unReadCount);
      // You can perform further actions here with unReadCount
    });

    return ()=>{
      myEventListener();
    }


  },[])





 
  const navigate=useNavigate();
  

  return (

    <div className="contain h-[100vh]">
      <div className="gap-12 py-6">
        <div onClick={()=>navigate("/dashboard")} className="">
        <svg className="flex justify-center items-center mx-auto w-[142px] h-[90.51px] "  width="246" height="151" viewBox="0 0 246 151" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_529_5032)">
<path d="M16.4966 136.03C16.4966 137.507 15.784 138.929 14.0073 139.722C16.2498 140.407 17.0703 142.785 17.0703 143.988C17.0703 148.254 13.8716 149.62 9.90793 149.62H0.830078V130.48H9.90793C13.6803 130.48 16.4966 132.177 16.4966 136.03ZM4.38349 138.164H9.90485C12.1751 138.164 12.9123 137.208 12.9123 136.113C12.9123 135.237 12.2028 133.762 9.90485 133.762H4.38349V138.164ZM4.38349 146.255H9.90485C11.3824 146.255 13.486 145.761 13.486 143.957C13.486 142.152 11.3824 141.332 9.90485 141.332H4.38349V146.252V146.255Z" fill="white"/>
<path d="M36.9196 149.62H22.5918V130.48H36.9196V133.981H26.1761V138.383H36.5371V141.745H26.1761V146.064H36.9196V149.617V149.62Z" fill="white"/>
<path d="M48.8106 133.762H42.7402V130.48H58.4869V133.762H52.4165V149.62H48.8076V133.762H48.8106Z" fill="white"/>
<path d="M68.6563 133.762H62.5859V130.48H78.3326V133.762H72.2622V149.62H68.6533V133.762H68.6563Z" fill="white"/>
<path d="M97.8271 149.62H83.5024V130.48H97.8271V133.981H87.0836V138.383H97.4446V141.745H87.0836V146.064H97.8271V149.617V149.62Z" fill="white"/>
<path d="M120.628 149.621H116.334L110.757 143.224H107.284V149.621H103.675V130.457C106.71 130.457 109.746 130.485 112.781 130.485C117.291 130.513 119.672 133.52 119.672 136.827C119.672 139.452 118.469 142.104 114.832 142.842L120.628 149.375V149.621ZM107.284 133.844V139.967H112.781C115.076 139.967 116.063 138.437 116.063 136.904C116.063 135.371 115.051 133.841 112.781 133.841H107.284V133.844Z" fill="white"/>
<path d="M150.536 139.886C150.619 144.726 147.664 149.618 140.968 149.618H133.451V130.479H140.968C147.529 130.479 150.456 135.155 150.536 139.883V139.886ZM137.029 146.148H140.965C145.311 146.148 147.035 142.977 146.952 139.859C146.869 136.879 145.12 133.899 140.965 133.899H137.029V146.148Z" fill="white"/>
<path d="M169.534 146.011H159.528L157.887 149.62H153.976L162.56 130.48H166.496L175.08 149.62H171.144L169.531 146.011H169.534ZM164.531 134.5L160.978 142.646H168.087L164.534 134.5H164.531Z" fill="white"/>
<path d="M181.564 133.762H175.493V130.48H191.24V133.762H185.169V149.62H181.56V133.762H181.564Z" fill="white"/>
<path d="M196.49 149.62V130.48H200.071V149.62H196.49Z" fill="white"/>
<path d="M219.072 130.453H222.681V149.617H220.438V149.645L210.377 136.715V149.621H206.768V130.481H209.692L219.069 142.347V130.453H219.072Z" fill="white"/>
<path d="M243.239 135.157C241.953 133.927 240.04 133.245 238.372 133.245C234.269 133.245 231.783 136.363 231.783 140.299C231.783 143.443 233.615 146.697 238.372 146.697C239.874 146.697 241.188 146.37 242.69 145.167V141.777H237.795V138.55H245.997V146.617C244.109 148.776 241.731 150.062 238.369 150.062C231.179 150.062 228.254 145.333 228.254 140.302C228.254 134.917 231.617 129.941 238.369 129.941C240.938 129.941 243.508 130.925 245.479 132.866L243.236 135.16L243.239 135.157Z" fill="white"/>
<path d="M91.0009 21.4725C96.5324 21.4725 101.016 16.9884 101.016 11.457C101.016 5.92553 96.5324 1.44141 91.0009 1.44141C85.4695 1.44141 80.9854 5.92553 80.9854 11.457C80.9854 16.9884 85.4695 21.4725 91.0009 21.4725Z" fill="white"/>
<path d="M127.439 21.2719C127.439 21.2719 127.448 21.275 127.451 21.2781C128.182 21.5835 128.972 21.7161 129.765 21.7315L133.164 21.8025C133.432 21.7408 133.697 21.6637 133.944 21.5649C135.598 20.9234 137.316 20.4422 139.089 20.4607C140.061 20.4699 141.085 20.581 142.013 20.8617C142.328 19.4459 142.504 17.9498 142.504 16.4014C142.504 7.65667 137.149 0.568359 130.542 0.568359C123.935 0.568359 118.58 7.65667 118.58 16.4014C118.58 18.0177 118.765 19.5785 119.108 21.0498C121.057 20.5902 124.558 20.0659 127.442 21.2688L127.439 21.2719Z" fill="white"/>
<path d="M117.633 38.7372C118.959 40.0821 121.057 40.2795 122.621 39.2153C125.625 37.1672 129.178 36.103 132.892 36.1739C137.698 36.2665 142.158 38.2467 145.449 41.7508C146.548 42.9199 147.454 44.1969 148.176 45.5417C150.098 44.1568 151.831 42.4695 153.294 40.517L153.836 39.7921C153.047 38.6076 152.155 37.4694 151.15 36.396C146.406 31.3435 139.974 28.4872 133.043 28.3546C127.686 28.2744 122.556 29.8012 118.225 32.7501C116.205 34.1258 115.921 36.9944 117.636 38.7341L117.633 38.7372Z" fill="white"/>
<path d="M149.549 58.8393C148.738 61.5908 147.242 64.188 145.058 66.3687L110.754 100.669L93.8572 83.7718C92.3304 82.245 89.8566 82.245 88.3297 83.7718C86.8029 85.2987 86.8029 87.7725 88.3297 89.2993L110.754 111.724L150.585 71.8932C155.749 66.7296 158.257 59.959 158.09 53.2285C155.511 55.5018 152.63 57.3896 149.549 58.8332V58.8393Z" fill="white"/>
<path d="M170.549 15.629L157.575 21.5236L169.734 22.3595L155.123 41.8878C149.327 49.6362 139.604 53.5598 130.36 51.888C125.409 50.9934 120.872 48.6677 117.232 45.1605L114.237 42.2086L107.46 35.5953C97.7653 25.7741 82.1266 25.3052 71.8612 34.5281C66.531 39.3153 63.4958 45.8977 63.3139 53.0601C63.1319 60.207 65.8185 66.919 70.8741 71.9684L80.9359 82.4498L104.163 59.0534C105.683 57.5204 105.674 55.0465 104.141 53.5259C102.608 52.0052 100.134 52.0144 98.6135 53.5474L81.0285 71.2621L76.4818 66.5273L76.4201 66.4625C72.8791 62.9399 71.0006 58.2545 71.127 53.2606C71.2535 48.2698 73.3695 43.683 77.0833 40.3455C84.2364 33.9142 95.1342 34.2412 101.945 41.1413L107.685 46.7429L111.93 50.6603C116.658 55.2131 122.559 58.236 128.993 59.4019C130.853 59.7382 132.726 59.9016 134.592 59.9016C144.839 59.9016 154.873 54.9571 161.23 46.4622L175.98 26.7519L180.755 38.1L182.134 23.9172L183.513 9.73438L170.539 15.6321L170.549 15.629Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_529_5032">
<rect width="245.17" height="149.487" fill="white" transform="translate(0.830078 0.570312)"/>
</clipPath>
</defs>
       </svg>

        </div>
        <div className="flex flex-col gap-3 navContent">
          <div className="line">
            <hr
              style={{
                borderTop: "1px dashed rgba(255, 255, 255, 0.5)",
                margin: "24px 0px",
              }}
            />
          </div>
          <div className="flex flex-col gap-5 px-3">
            <List className="flex flex-col h-auto gap-5 text-white w-60" >
           <SideNavItem to="/dashboard" label="Dashboard" img={<DashboardIcon sx={{color:"#57267B"}} />} />
              <SideNavItem to="/user_management" label="User Management" img={<ManageAccountsIcon sx={{color:"#57267B"}}/>} />
              <SideNavItem to="/content_management" label="Content Management" img={<SourceIcon sx={{color:"#57267B"}}/>} />
              <SideNavItem to="/sessionManagement" label="Session Management" img={<SupervisorAccountIcon sx={{color:"#57267B"}}/>}  />
              <SideNavItem to="/userSupport" type="userSupport" label="User Support"  img={<LiveHelpIcon sx={{color:"#57267B"}}/>}  />
              <SideNavItem to="/feedback" label="Feedback"  img={<FeedbackIcon sx={{color:"#57267B"}}/>} />
              <SideNavItem to="/subscriptions" onClick={()=>{console.log("clicked!!")}} label="Subscriptions"  img={<SupervisedUserCircleIcon sx={{color:"#57267B"}}/>} />
              <SideNavItem to="/referralcode" label="Referral Code"  img={<ShareIcon sx={{color:"#57267B"}}/>} />
            </List>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Sidenav;

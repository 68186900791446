import React,{ useEffect, useState } from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Paper } from '@mui/material'
import { toast } from 'react-toastify';
// import { editProfileDetails, profileDetails, updatePassword } from '../../../API/myProfileApi';
import Joi from 'joi';
import PersonIcon from '@mui/icons-material/Person';
import ModeEdit from "@mui/icons-material/ModeEdit";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from '@mui/icons-material/Edit';
import { HashLoader } from 'react-spinners';
import { UseDispatch, useDispatch } from 'react-redux';
import { userDetailsActions } from '../../store/slices/userDetails';


// Custom content for the loading toast
const LoadingToast = ({ message }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {/* <ClipLoader size={16} color={"#6F2395"} loading={true} /> */}
      <HashLoader
    color="#6f2395"
    size={28}
  />
      <span style={{ marginLeft: '10px' }}>{message}</span>
      
    </div>
  );

const MyAccount = () => {


    
    const [formData, setFormData] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
    });   
    const [isExpanded, setIsExpanded] = useState(false);
    const [isProfile, setIsProfile] = useState(false);
    const [profileData, setProfileData] = useState({});
    const [name, setName] = useState(profileData?.fullName);
    const [image, setImage] = useState(null);
    const [profilePicture, setProfilePicture] = useState(null);
    const [errors, setErrors] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showCnfrmPassword, setShowCnfrmPassword] = useState(false);
    const [profileImg,setProfileImg]=useState(null);
    const dispatch=useDispatch();

    async function updatePassword(postform){

        try{
            const data={
                password:postform?.password,
                oldPassword:postform?.currentPassword
            }
            const token=sessionStorage.getItem("better-dating-app-auth-token");
            const response=await fetch(process.env.REACT_APP_BASE_URL+"admin/adminChangePassword",{
                method:"POST",
                headers:{
                    "Content-Type":"application/json",
                    "authorization":token
                },
                body: JSON.stringify(data)
            });
            return await response.json();
            

        }
        catch(error){

        }
       

    }
    

    const passwordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const newPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };
    const cnfrmPasswordVisibility = () => {
        setShowCnfrmPassword(!showCnfrmPassword);
    };
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const passwordChangeValidation = Joi.object({
        currentPassword: Joi.string().required().label('Current Password'),
        newPassword: Joi.string()
            .min(8)
            .max(16)
            .pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/)
            .required()
            .label('New Password')
            .messages({
                "string.empty": "New Password cannot be empty",
                "string.pattern.base":
                    "The password must include, a minimum length of 8 characters, which should include a mix of upper case, lower case letters, numbers, and special characters.",
            }),
        confirmPassword: Joi.string()
            .valid(Joi.ref('newPassword'))
            .required()
            .label('Confirm Password')
            .messages({
                'any.only': '{{#label}} does not match with New Password',
            }),
    });
    const handleChangeProfilePicture = (e) => {

        if (e.target.files[0]) {
            setErrors("");
            const maxSize = 5 * 1024 * 1024;
            if (e.target.files[0].size <= maxSize) {

                setProfilePicture(e.target.files[0]);
                setImage(URL.createObjectURL(e.target.files[0]));
                setProfileImg(URL.createObjectURL(e.target.files[0]));
            } else {
                setProfilePicture(null);
                setErrors("File size exceeds the maximum size allowed (5 MB). Please choose a smaller file.");
                return;
            }
        }
    };

    const handleCancelPassword = () => {
        setIsExpanded(false);
    }
    const handleCancel = () => {
        setErrors("")
        setIsProfile(false);
        image ? setImage(profileData?.profileImage) : setImage(null)
        // profileImg ? setProfileImg(profileData?.profileImage) : setProfileImg(null)
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const postForm = {
            currentPassword: formData?.currentPassword,
            password: formData?.newPassword,
            confirmPassword: formData?.confirmPassword,
        }
        const validData = passwordChangeValidation.validate(formData);
        if (validData?.error?.message) {
            toast.error(validData?.error?.message, {
                autoClose: 5000,
                toastId: "453"
            })
            return;
        }
        updatePassword(postForm)
            .then((response) => {
                if(response.status===200){
                    toast.success("Password updated succesfully!", {
                        autoClose: 5000, toastId: "12343"
                    })
                    setIsExpanded(false)
                }
                else if(response.status===400){
                    throw new Error('Current password is invalid!');
                }
                else{
                    throw new Error('Something went wrong!');
                }
                
            })
            .catch((error) => {
                toast.error(error?.message, {
                    autoClose: 5000,
                    toastId: "12323"
                })
            })
    };
    const handleNameChange = (e) => {
        setName(e?.target?.value)
    }

    const handleProfileUpdate = async() => {

        let tempProfileImgURL=profileImg;

        //first uploading the image and getting the image link
        if(profilePicture){
            const formData = new FormData(); 
            formData.append('file', profilePicture);
            
            const toastId = toast.info(<LoadingToast message="Updating Profile" />, {
                autoClose: false,
                closeOnClick: false,
                draggable: false,
                hideProgressBar: true,
                icon: false, // Disable the default icon
              });

            try{

                const responsee = await fetch(process.env.REACT_APP_BASE_URL+'admin/uploadFile', {
                    method: 'POST',
                    body: formData,
                    headers: {
                      'Authorization': `${sessionStorage.getItem("better-dating-app-auth-token")}`
                    },
                  });
                const result = await responsee.json();
                // debugger;
                if (responsee.ok) {
                    console.log("Uploaded file URL:", result.fileUrl);
                    tempProfileImgURL=result.fileUrl;
                    setProfileImg(result.fileUrl);
                    setProfilePicture(null);
                    
                  } else {
                    // Handle HTTP error responses
                    throw new Error(result.message || 'Failed to upload profile picture');
                  }
                  toast.dismiss(toastId);

            }  
            catch(error){
                console.error("Error uploading file:", error.message);
                toast.dismiss(toastId);
            }
            

        }
        
        

        const bodyy={
            name:name,
            profileImg:tempProfileImgURL
        }

        
        
        let response=await fetch(process.env.REACT_APP_BASE_URL+"admin/adminUpdateProfile",{
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                "authorization":sessionStorage.getItem("better-dating-app-auth-token")
            },
            body: JSON.stringify(bodyy)
        });
        response =await response.json();
        if(response?.status===200){

            console.log(response);
            const userDetails={
                userName:response?.response?.name,
                email:response?.response?.email,
                profileImg:response?.response?.profilePictures[0]??null
            }

            dispatch(userDetailsActions.setUserDetails({userDetails }));
            
 
            setIsProfile(false);
            // setProfilePicture(null);
            fetchProfileDetails();

            toast.success("Profile updated succesfully!", {
                autoClose: 5000, toastId: "12343"
            })


        }
        else{
            throw new Error('Something went wrong while updating profile!');
        }

        // let data = new FormData();
        // data.append("fullName", name || profileData?.fullName);
        // if (profilePicture) { data.append("media", profilePicture) };
        // data.append("email", profileData?.email);
        // editProfileDetails(data)
        //     .then((response) => {
        //         toast.success("Profile updated succesfully!", {
        //             autoClose: 5000, toastId: "12343"
        //         })
        //         setIsProfile(false);
        //         setProfilePicture(null);
        //         fetchProfileDetails();
        //     })
        //     .catch((error) => {
        //         toast.error(error?.message, {
        //             autoClose: 5000,
        //             toastId: "12323"
        //         })
        //     })
    }

    const fetchProfileDetails = async() => {
        // profileDetails()
        //     .then((response) => {
        //         setProfileData(response?.data?.data[0]);
        //         setImage(response?.data?.data[0]?.profileImage);
        //     })
        //     .catch((error) => toast.error(error?.message, {
        //         autoClose: 5000,
        //         toastId: "ryb"
        //     }))

        try{

            let userDetails=await fetch(process.env.REACT_APP_BASE_URL+"admin/adminMyAccount",{
                method:"GET",
                headers:{
                    "Content-Type":"application/json",
                    "Authorization":sessionStorage.getItem("better-dating-app-auth-token")
                }
            });

            userDetails=await userDetails.json();

            setProfileData({
                fullName:userDetails?.response?.userName,
                email:userDetails?.response?.email
            })
            setName(userDetails?.response?.userName);
           
            const temp=userDetails?.response?.profileUrl??null;
            setProfileImg(temp);
            
        }
        catch(error){
            toast.error(error?.message, {
                 autoClose: 5000,
                 toastId: "ryb"
             })

        }


    }
    useEffect(() => {
        fetchProfileDetails();
    }, [])








  return (
    <>
    <Paper className=' overflow-y-hidden xl:max-h-[97%] 2xl:max-h-[86vh] overflow-x-auto 2xl:w-full xl:w-[100%] xl:h-[97%] 2xl:h-[86vh] flex flex-col gap-6 rounded-xl'>
     <div className=' max-h-full flex flex-col'>
        
        {/* Component Title */}
        <div className='text-2xl text-[#57267B]  mt-8 mx-auto font-semibold xl:pb-6 2xl:pb-8'>Profile</div>

        {/* line seperator */}
        <hr className="w-full h-[1px] bg-[#efefef]" />

        
        {/* image section */}
        <div className=' flex flex-row justify-between items-start  pt-8 px-12'>
                <div className='relative w-[135px] h-[135px] rounded-full bg-gray-300' >
                    {profileImg ? (
                        <img
                            src={profileImg}
                            alt=''
                            className='w-[inherit] h-[inherit] rounded-full'
                        />


                    ) : (
                        <PersonIcon style={{ fontSize: '135px', color: 'white' }} />
                    )}

                    {isProfile && (
                        <button type='button' className='flex absolute top-0 right-0 cursor-pointer bg-[#EEEEEE] rounded-[6px] h-[30px] w-[30px] justify-center items-center' onClick={() => {
                            document.getElementById("change-profile-picture").click();
                        }}>
                            <ModeEdit style={{ fontSize: '20px', color: '#888888' }} />
                            <input
                                type="file"
                                accept="image/png, image/gif, image/jpeg, image/jpg"
                                id="change-profile-picture"
                                style={{ display: "none" }}
                                onChange={handleChangeProfilePicture}
                            />

                        </button>

                    )}

                </div>
                {isProfile && <div className="flex flex-row justify-between items-center gap-4 ">
                    <button className="btn !text-[#888888] !bg-[#EEEEEE] text-[14px] font-medium rounded-[6px]" type="submit" onClick={handleCancel}>Cancel
                    </button><button  className="btn text-[14px] font-medium flex flex-row justify-center items-center gap-1" style={errors ? { color: "#888888", background: "#EEEEEE" } : {backgroundColor:"#57267B"}} type="submit" disabled={errors} onClick={handleProfileUpdate}>Save Profile
                    </button>
                </div>}
                {!isProfile && <button  style={{backgroundColor:"#57267B"}} className="btn text-[14px]  font-medium flex flex-row justify-center items-center gap-1" onClick={() => setIsProfile(true)}>
                    <ModeEdit className="w-5 h-5 font-[white] " />
                    <span className="text-[14px] font-medium">Update Profile</span>
                </button>}
            </div>



        {/* line seperator */}
        {/* <hr className="w-full h-[1px] bg-[#efefef]" /> */}


        {/* main body profile name,email,password and password change  */}
        <div style={{scrollbarWidth:"none"}} className="w-full px-12 pt-4  flex flex-col gap-8 flex-grow max-h-full 2xl:mt-4  ">

                {/* showing name, email */}
                <div className="bg- flex flex-row justify-between ">
                    
                    {/* name  */}
                    <div className="w-1/2">
                        <p className="text-4">Full Name</p>
                        {!isProfile ? <p className="text-[14px] text-grey-500 font-medium">{profileData?.fullName}</p>
                            : <input
                                type="fullName"
                                id="fullName"
                                name="fullName"
                                className="w-[80%] h-[90%] border-[1px] border-[#C7C6C6] font-normal rounded-md p-[5px]"
                                style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                                defaultValue={profileData?.fullName}
                                onChange={handleNameChange}
                            />}
                    </div>
                    
                    {/* email */}
                    <div className="w-1/2">
                        <p className="text-4">Email Address</p>
                        <p className="text-[14px] text-grey-500 font-medium">{profileData?.email}</p>
                    </div>

                </div>

               
                {/* password, and update password button  */}
                {!isExpanded && (
                    <div className="flex flex-row justify-between items-center">
                        <div className="">
                            <p className="text-4">Password</p>
                            <p className="text-[14px] text-grey-500 font-medium">*****</p>
                        </div>
                        <button style={{backgroundColor:"#57267B"}} className="btn flex flex-row justify-center items-center gap-1" onClick={() => setIsExpanded(true)}>
                            <EditIcon sx={{height:"1.25rem",width:"1.25rem"}}/>
                            <span className="text-[14px] font-medium">Update Password</span>
                        </button>
                    </div>
                )}


                {isExpanded && (
                    <>
                        <div className="flex flex-row justify-between  items-center">
                            <p className="text-4">Update Password</p>
                            <div className="flex flex-row justify-between items-center gap-4 ">  <button className="btn !text-[#888888] !bg-[#EEEEEE] text-[14px] font-medium rounded-[6px]" type="submit" onClick={handleCancelPassword}>Cancel
                            </button><button style={{backgroundColor:"#57267B"}} className="btn text-[14px] font-medium flex flex-row justify-center items-center gap-1" type="submit" onClick={(e) => handleSubmit(e)}>Save Password</button>
                            </div>
                        </div>
                        <div style={{scrollbarWidth:"none"}} className="flex flex-col gap-6 pb-8 xl:h-72 2xl:h-fit 2xl:mt-12 overflow-y-auto">
                            <div className="w-1/2 flex flex-col relative">
                                <p className="">Current Password</p>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    id="currentPassword"
                                    name="currentPassword"
                                    className="w-full border-[1px] border-[#C7C6C6] font-normal rounded-md p-[10px]"
                                    style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                                    placeholder="Current Password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                />
                                <button type="button" className="flex absolute right-[12px] top-[36px]" onClick={passwordVisibility}>
                                    {showPassword ? (
                                        <VisibilityIcon sx={{ color: "#a1a0a3" }} />
                                    ) : (
                                        <VisibilityOffIcon sx={{ color: "#a1a0a3" }} />
                                    )}                </button>

                            </div>
                            <div className="w-1/2 flex flex-col relative">
                                <p className="">New Password</p>
                                <input
                                    type={showNewPassword ? "text" : "password"}
                                    id="newPassword"
                                    name="newPassword"
                                    className="w-full border-[1px] border-[#C7C6C6] font-normal rounded-md p-[10px]"
                                    style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                                    placeholder="New Password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                />
                                <button type="button" className="flex absolute right-[12px] top-[36px]" onClick={newPasswordVisibility}>
                                    {showNewPassword ? (
                                        <VisibilityIcon sx={{ color: "#a1a0a3" }} />
                                    ) : (
                                        <VisibilityOffIcon sx={{ color: "#a1a0a3" }} />
                                    )}                </button>

                            </div>
                            <div className="w-1/2 flex flex-col relative">
                                <p className="">Confirm New Password</p>
                                <input
                                    type={showCnfrmPassword ? "text" : "password"}
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    className="w-full border-[1px] border-[#C7C6C6] font-normal rounded-md p-[10px]"
                                    style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                                    placeholder="Confirm Password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                />
                                <button type="button" className="flex absolute right-[12px] top-[36px]" onClick={cnfrmPasswordVisibility}>
                                    {showCnfrmPassword ? (
                                        <VisibilityIcon sx={{ color: "#a1a0a3" }} />
                                    ) : (
                                        <VisibilityOffIcon sx={{ color: "#a1a0a3" }} />
                                    )}                </button>

                            </div>
                        </div>
                    </>
                )}
        </div>

     </div>
    </Paper>
    </>
  )
}

export default MyAccount
import React, { useEffect, useState } from 'react';
import './dashboard.css';
import Cards from '../../commonComponents/cards';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';

import BarChart2 from '../../commonComponents/barChart2';
import LineChart2 from '../../commonComponents/lineChart2';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const [regUser, setRegUser] = useState(5);
  const [newUsers, setNewUsers] = useState(0);
  const [flaggedProfiles, setFlaggedProfiles] = useState(0);
  const [triggerRefresh,setTriggerRefresh]=useState(false)
  const [triggerRefresh2,setTriggerRefresh2]=useState(true)
  const [totalRevenue, setTotalRevenue] = useState(550);
  const [pkjBarChartData,setPkjBarChartData]=useState(new Array(12).fill(0));
  const [apiData,setApiData]=useState({
    totalUser:0,
    subscribedUser:0,
    avgFeedback:0,
    totalRevenue:0,
    barChartData:{
      standard:[],
      subscribed:[]
    }
  })
  const [cardDataApi,setCardDataApi]=useState([
    0,
    0,
    0,
    0,
  ])

  const navigate=useNavigate();

  async function getAllChartData(periodd="yearly"){
    try{  
      const data = {
          period: periodd
      };
  
      let response = await fetch(process.env.REACT_APP_BASE_URL + "subscribe/barChartSubscription", {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
              "Content-Type": "application/json"
          }
      });
  
      response = await response.json();
     
  
      const months = [
        "January", "February", "March", "April", "May", "June", 
        "July", "August", "September", "October", "November", "December"
      ];
      // debugger;
      const values = months.map(month => response?.data[month]*9);

      setPkjBarChartData(values);
    
      
  }
  catch(error){
    console.log({error})
  }

  }

  useEffect(()=>{

    getAllChartData();

  },
[])


  useEffect(() => {
    const cardDetails = async (data) => {
      try {
        const response=await fetch(process.env.REACT_APP_BASE_URL+"admin/dashboard",{
          method:"GET",
          headers:{
            "Content-Type":"application/json",
            'Authorization': `${sessionStorage.getItem("better-dating-app-auth-token")}`
          },
          body: JSON.stringify(data)
        }) 
        const json=await response.json();
        if(json?.status===425){
          localStorage.clear();
          sessionStorage.clear();
          navigate("/login");
        }
        setApiData(json?.response);
        console.log(json);
        
        setCardDataApi([json?.response?.totalUser,json?.response?.subscribedUser,json?.response?.avgFeedback.toFixed(1),json?.response?.totalRevenue]);
        setTriggerRefresh(!triggerRefresh);
        setTriggerRefresh2(!triggerRefresh2);
      } catch (error) {
        toast.error('Error fetching dashboard data', {
          autoClose: 5000,
          toastId: 'dashboardError',
        });
      }
    };
    cardDetails();
  }, []);

  const colors = ['#F1426D', '#7239EA', '#22C1AE', '#4192F1'];
  const colors1 = ['#E21F4F', '#5D1EE2', '#009583', '#005bc4'];
  const cardData = [regUser, newUsers, flaggedProfiles, totalRevenue];

  return (
    <div className="w-full px-5 ">
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.8, opacity: 0 }}
        transition={{ duration: 1 }}
        className="space-y-5"
      >
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
          {colors.map((backgroundColor, index) => (
            <Cards
              key={index}
              cardData={cardData[index]}
              imgIndex={index}
              backgroundColor={colors[index]}
              darkColor={colors1[index]}
              apiData={cardDataApi[index]}
            />
          ))}
        </div>
        <div className=" flex gap-8">
          <div key={triggerRefresh} className="flex-1 ">
            <BarChart2 totalUser={apiData?.totalUser}  pkjComp={"200px"} subscribedUserData={apiData?.barChartData?.subscriptionData} standardUserData={apiData?.barChartData?.standardData}  />
          </div>
          <div key={triggerRefresh2} className="flex-1">
            <LineChart2 totalRevenue={apiData?.totalRevenue} chartData={pkjBarChartData} />
            
          </div>
          
        </div>
      </motion.div>
    </div>
  );
};

export default Dashboard;

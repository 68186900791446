import React,{ useEffect, useState } from "react";

import Paper from "@mui/material/Paper";
import GenericModal from "../../commonComponents/GenericModal/GenericModal";
import { Stack, Pagination } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getAllFeedbackData } from "../../API/apis/feedbackApi";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';


const StyledPagination = styled(Pagination)(({ theme }) => ({
  '& .Mui-selected': {
    backgroundColor: '#57267b',
  },
  '& .MuiPaginationItem-root:hover': {
    backgroundColor: 'rgba(87, 38, 123, 0.6)', // Lighten color on hover
  },
  // Add more styles here as needed for different states
}));

const Feedback = () => {

  const navigate=useNavigate();
    const [allFeedback, setAllFeedback] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);


    async function fetchAllFeedbackData(page = 1) {
      const { response, pagination,status } = await getAllFeedbackData(page, 10); 

      if(status===425){
        localStorage.clear();
        sessionStorage.clear();
        navigate("/login");
        return;
      }

      if (response.status >= 400) {
        toast.error(response?.message, { autoClose: 5000 });
      } else {
        setAllFeedback(response);
  
        setTotalPages(pagination.totalPages); 
      }
    }

    useEffect(()=>{
      fetchAllFeedbackData(currentPage);
    },[currentPage])



    const [isModalOpen,setIsModalOpen]=useState(false);
    const [feedbackComp,setFeedbackComp]=useState(<div>Loading...</div>)


    const handleModalToggle=()=>{
       
        setIsModalOpen(!isModalOpen);
      }
    
  
    return (
        <>
        <Paper className="overflow-hidden xl:max-h-[99%] 2xl:max-h-[89vh] overflow-x-auto 2xl:w-full xl:w-[100%] xl:h-[97%] 2xl:h-[86vh] flex flex-col gap-6 rounded-xl">
        <div className=" h-full">
          {/* Component Title */}
          <div className='text-xl text-[#57267B]  mt-8 ml-6 font-semibold '>Testimonials and Feedback</div>
          
          {/* Table */}
          <div className=" flex flex-col h-[75%] mt-11 mx-6 ">
            {/* Table Head */}
            <div className="flex items-center text-md font-bold text-[#57267B] border-b border-gray-200">
              <div className='flex-grow p-2' style={{ flexBasis: '20%' }}>Username</div>
              <div className='flex-grow p-2' style={{ flexBasis: '30%' }}>Email</div>
              <div className='flex-grow p-2 text-center' style={{ flexBasis: '20%' }}>Rating</div>
              <div className='flex-grow p-2 text-center' style={{ flexBasis: '30%' }}>Qualitative Feedback</div>
            </div>
  
            {/* Table Content */}
            {allFeedback.length!==0?(<div style={{scrollbarWidth:"none"}} className="flex-grow overflow-y-auto ">
              {allFeedback?.map((eachFeedback, index) => (
                <div key={index} className="flex  mt-3 items-center text-md font-medium border-b border-gray-200">
                  <div className='flex-grow p-2' style={{ flexBasis: '20%' }}>{eachFeedback.name}</div>
                  <div className='flex-grow p-2' style={{ flexBasis: '30%' }}>{eachFeedback.email}</div>
                  <div className='flex-grow p-2 text-center' style={{ flexBasis: '20%' }}>{eachFeedback.rating}</div>
                  <div className='flex-grow p-2  flex justify-center' style={{ flexBasis: '30%' }}><button type="button" className="w-[36.8px] h-[36.8px] bg-[#ffffff] hover:border-solid hover:border-2  hover:border-[#6F2295] border-none rounded-[5.02px] p-[10.04px]" onClick={() => {
                    setFeedbackComp(<>


                    <div className='flex flex-col  rounded-lg  text-center'>
                          <div className="flex justify-between w-full mt-3 ">
                              <div className=" flex flex-col w-1/2 h-12 gap-1">
                                  <p className="h-6 text-md font-bold text-[#57267B] " style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Username</p>
                                  <p className="h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>{eachFeedback?.name}</p>
                              </div>
                              <div className=" flex flex-col w-1/2 h-12 gap-1">
                                  <p className="h-6 text-md font-bold text-[#57267B]" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Email</p>
                                  <p className="h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>{eachFeedback?.email}</p>
                              </div>
                              <div className=" flex flex-col w-1/2 h-12 gap-1">
                                  <p className="h-6 text-md font-bold text-[#57267B]" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Rating</p>
                                  <p className="h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>{eachFeedback?.rating}</p>
                              </div>
                          </div>
              
                          <div className="p-5">
                              
                              <textarea disabled={true}  className=" text-[#888888] p-5 text-[14px] font-normal w-full rounded-lg italic ">{"Qualitative Feedback: "+`"`+eachFeedback?.feedback+`"`}</textarea>
                          </div>
              
                          

                    </div>
                    
                    </>);
                    handleModalToggle();
                    }}>
          <svg id="Layer_1" style={{ fill: "#6F2295" }} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>eye-glyph</title><path d="M320,256a64,64,0,1,1-64-64A64.07,64.07,0,0,1,320,256Zm189.81,9.42C460.86,364.89,363.6,426.67,256,426.67S51.14,364.89,2.19,265.42a21.33,21.33,0,0,1,0-18.83C51.14,147.11,148.4,85.33,256,85.33s204.86,61.78,253.81,161.25A21.33,21.33,0,0,1,509.81,265.42ZM362.67,256A106.67,106.67,0,1,0,256,362.67,106.79,106.79,0,0,0,362.67,256Z" /></svg>
        </button></div>
                </div>
              ))}
            </div>):(<div className=" text-[#71717A] text-center text-sm mt-4">No Feedbacks!</div>)}
            
          </div>
        </div>
      </Paper>

      {isModalOpen&&
       <GenericModal      
       open={isModalOpen}
       handleClose={handleModalToggle}
       onClose={handleModalToggle}
       content={feedbackComp}
       isCross={true}
       title="User Feedback"
       width="738px"
       height="800px"
      />}


<Stack spacing={2} direction="row" justifyContent="center" alignItems="center" marginY={1}>
    <Pagination
      count={totalPages}
      page={currentPage}
      onChange={(_event, page) => setCurrentPage(page)}
    />
  </Stack>

      </>
      
    );
  }
  
  export default Feedback;
  
import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ReportIcon from '@mui/icons-material/Report';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import { userDetailsActions } from '../../store/slices/userDetails';



const NotificationPopUp = ({handleClose}) => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [allNotification,setAllNotification]=useState([]);


    useEffect(() => {
        const fetchNotifications = async () => {
          try {
            let response = await fetch(process.env.REACT_APP_BASE_URL + "admin/getAllNotifications",
                {
                    method:"GET",
                    headers:{
                     "Content-Type":"application/json",
                     'Authorization': `${sessionStorage.getItem("better-dating-app-auth-token")}`
                    }
                }
            );
            response = await response.json();

            if(response?.status===425){
                localStorage.clear();
                sessionStorage.clear();
                navigate("/login");
                return;
              }
            debugger;
            setAllNotification(response?.response.allNotification);
            const userDetails={
                newNotification:0
            }
            dispatch(userDetailsActions.setNewNotification({userDetails}));
            debugger;
          } catch (error) {
            console.error("Failed to fetch notifications:", error);
            // toast.error('Error fetching notication data', {
            //     autoClose: 5000,
            //     toastId: 'notify',
            //   });
          }
        };
    
        fetchNotifications();
      }, []);




    return (
        <div className=" w-[28rem] bg-white  h-[28rem] p-5 rounded-[5px] flex flex-col gap-2.5">
            {/* <div className="flex-col justify-start items-start gap-3 flex">
                <div onClick={()=>{console.log("dshwiwifo")}} className="text-neutral-800 text-sm font-medium font-['Inter']">
                    <button>My Account</button>
                </div>
                <div  onClick={()=>{console.log("dshwiwifo")}} className="text-neutral-800 w-full h-full text-sm font-medium font-['Inter']">
                    <button >Logout</button>
                </div>
            </div> */}

            <div className='font-bold text-lg text-[#6f2395]'>Notification</div>
            <hr className="h-px mb-1 bg-gray-200 border-0 dark:bg-gray-700"/>
            <div style={{scrollbarWidth:"none"}} className='flex flex-col gap-3 h-full overflow-y-auto'>
                {allNotification?.map((eachNotification)=>{

                    const formattedTime = moment(eachNotification?.createdAt).format('MMMM Do YYYY, h:mm:ss a');   
                    return (
                    <>
                        {eachNotification?.type==="feedback"?(


                        //   my feedback 
                       
                        <div style={{backgroundColor:eachNotification?.isWatched?"#F3F4F6":""}} onClick={()=>{navigate("/feedback"); handleClose()}} className='hover:cursor-pointer flex bg-pink-50 rounded-lg flex gap-2 items-center p-2 py-3 text-base'>
                            <div className='bg-[#E21F4F] p-2 rounded-3xl'><div className='w-5 h-5 flex justify-center items-center'>
                                <FeedbackIcon sx={{fill:"#fff", width:"16px",height:"16px"}}/>
                                </div>
                            </div>
                            <div className='flex-grow'>
                               <p>New feedback from <span className='font-bold italic'>{eachNotification?.email}</span>.</p>
                               <p className='italic'>
                                  "{eachNotification?.feedback && eachNotification?.feedback.length > 26
                                   ? eachNotification.feedback.slice(0, 26) + "..."
                                   : eachNotification?.feedback}"
                               </p>
                               <div style={{fontSize:"0.65rem", lineHeight:"0rem"}} className='text-gray-500 text-[0.60rem] w-full flex justify-end '><div>{formattedTime}</div></div>
                              
                            </div>
                           
                        </div>
                        
                        
                        )
                        
                        
                        
                        :


                        // my report div
                        ( <div style={{backgroundColor:eachNotification?.isWatched?"#F3F4F6":""}} onClick={()=>{navigate("/user_management"); handleClose()}} className='hover:cursor-pointer flex bg-purple-50 rounded-lg flex gap-2 items-center p-2 py-3 text-base'>
                        <div className='bg-[#57267b] p-2 rounded-3xl'><div className='w-5 h-5 flex justify-center items-center'>
                            <ReportIcon sx={{fill:"#fff", width:"16px",height:"16px"}}/>
                            </div>
                        </div>
                        <div className='flex-grow'>
                           <p><span className='font-bold italic'>{eachNotification?.email}</span> is reported.</p>
                           <p className=''>Total Report Count: <span className={eachNotification?.reportCount>=5?"font-bold text-red-500":""}>{eachNotification?.reportCount}</span></p>
                           <div style={{fontSize:"0.65rem", lineHeight:"0rem"}} className='text-gray-500 text-[0.60rem] w-full flex justify-end '><div>{formattedTime}</div></div>
                        </div>
                    
                    </div>)}
                      
                    </>)
                })}

            </div>

        </div>
    );
};

export default NotificationPopUp;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileDets from './profile_details/profile_details';
// import GenericModal from '../GenericModal';
import GenericModal from '../../commonComponents/GenericModal/GenericModal';
import LogoutPage from './logoutPage';
import { toast } from 'react-toastify';

const ProfilePopUp = ({handleClose}) => {
    const [isProfileDetsOpen, setIsProfileDetsOpen] = useState(false);
    const [isLogoutOpen, setIsLogoutOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const navigate = useNavigate();

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleMyAccountClick = () => {
        console.log("this is clicked my account naaaa");
        navigate("/myAccount");
        handleClose();
    };

    const handleLogout = () => {
        console.log("loged out");

        localStorage.clear();
        sessionStorage.clear();

        toast.success('Logged out Successfully!');


        navigate("/login");
        
        // handleOpenModal();
        // setIsLogoutOpen(!isLogoutOpen);
        // setIsProfileDetsOpen(false);
        
    };

    return (
        <div className=" w-[233px] h-fit p-5 bg-white rounded-[5px] flex flex-col gap-2.5">
            <div className="flex-col justify-start items-start gap-3 flex">
                <div onClick={handleMyAccountClick} className="text-neutral-800 text-sm font-medium font-['Inter']">
                    <button>My Account</button>
                </div>
                <div  onClick={handleLogout} className="text-neutral-800 w-full h-full text-sm font-medium font-['Inter']">
                    <button >Logout</button>
                </div>
            </div>

            

            {/* {isProfileDetsOpen && (
                <GenericModal
                    open={isModalOpen}
                    handleClose={handleCloseModal}
                    content={<ProfileDets />}
                    isCross={true}
                    title="Profile"
                    width="738px"
                    height="auto"
                />
            )} */}
            {isLogoutOpen && (
                <GenericModal
                    open={isModalOpen}
                    handleClose={handleCloseModal}
                    content={<div className='bg-red-300 h-40'>
                        this is button
                    </div>}
                />
            )}
        </div>
    );
};

export default ProfilePopUp;

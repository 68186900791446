import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemText } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import './sidenav.css';
import { useSelector } from "react-redux";
import Badge from "@mui/material/Badge";

const SideNavItem = ({ to, label, img, type }) => {
    const location = useLocation();
    const isActive = location.pathname === to;
    const myUnReadCount=useSelector((store)=> store.userDetails);
    

    return (
 
 <Link to={to}>

  
            <ListItem
                className={`flex gap-[4px] flex-row justify-center items-center listItem ${isActive ? 'activeHover' : ''}`}
            >

          <Badge
            color="primary"
            className="relative hover:bg-purple-100 p-2 rounded-3xl"
            style={{backgroundColor:myUnReadCount?.unReadCount===0?"#fff":"#F3E8FF"}}
          >
            {img}
            {type === "userSupport" && <div 
                  style={{
                    display: ( myUnReadCount?.unReadCount===0) ? "none" : "flex"
                  }} 
                  className="absolute -top-2 -right-2 bg-[#795195] text-white text-xs font-bold w-5 h-5 flex items-center justify-center rounded-full"
                >
                  {myUnReadCount?.unReadCount}
            </div>}
            

          </Badge>

{/* 
                {type==="userSupport" && <div className='bg-red-500'>{myUnReadCount?.unReadCount}</div> }
                {img} */}
                <ListItemText
                    sx={{
                        fontFamily: "Plus Jakarta Sans",
                        lineHeight: "20px",
                        fontSize: "14px",
                        fontWeight: "500",
                        width: "75px",
                        height: "22px",
                    }}
                    primary={label}
                />

                
            </ListItem>
        </Link>
    );
};

SideNavItem.propTypes = {
    to: PropTypes.string,
    label: PropTypes.string,
    img: PropTypes.string,
};

export default SideNavItem;

import React from "react";
import classes from "./Loader.module.css";
import { CircularProgress } from "@mui/material";

const Loader = () => {
  return (
    <div className={classes.backdrop}>
      <h1 className="text-white mr-2">Uploading Media</h1>
      <CircularProgress sx={{color:"white"}}  />
    </div>
  ); 
};

export default Loader;
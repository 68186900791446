import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
};

const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    toggleLoading(state, action) {
      state.isLoading = action.payload;

      return state;
    },
  },
});

export const loaderActions = loaderSlice.actions;


export default loaderSlice.reducer;

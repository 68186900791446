import React,{ useEffect }  from 'react'
import Sidenav from '../modules/sidenav/sidenav'
import { useNavigate,Navigate, Route, Routes } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import { useDispatch,useSelector } from 'react-redux';
import { authActions } from '../store/slices/auth';
import Navbar from '../modules/navbar/navbar';
import Dashboard from '../modules/dashboard/Dashboard';
import Content_management from '../modules/content_management/content_management';
import SessionManagement from '../modules/session_management/sessionManagement';
import UserManagement from '../modules/user_management/user_management';
import Chat from '../modules/Chat/Chat';
import Loader from '../commonComponents/Loader/Loader';
import Feedback from '../modules/Feedback/Feedback';
import Subscriptions from '../modules/Subscriptions/Subscriptions.jsx';
import ReferralCodePage from '../modules/ReferralCode/referralcode.js';
import MyAccount from '../modules/MyAccount/MyAccount.js';
// import { loaderActions } from '../store/slices/loader';
// import UserManagement from '../modules/user_management/user_management';


const AuthenticatedRoutes = () => {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.loader.isLoading);
    const navigate=useNavigate();
    const authToken = sessionStorage.getItem("better-dating-app-auth-token");
      useEffect(() => {
    try {
      jwtDecode(authToken);

    } catch (error) {
      
      dispatch(authActions.logout());
      sessionStorage.clear();
      navigate('/login');
    }
  }, [authToken]);
  return (

    <>
     {loader && <Loader />}
     <div className="flex flex-row">
     <Sidenav />

     <div className="flex flex-col w-full card">
       <Navbar />
       <div className="p-6 bg-gray-100 h-[100%]">
         <Routes>
         
           <Route path="dashboard" element={<Dashboard/>} />

           <Route path="user_management" element={<UserManagement/>} />
           <Route path="content_management" element={<Content_management/>} />
           <Route path="sessionManagement" element={<SessionManagement/>} />
           <Route path="userSupport" element={<Chat/>} />
           <Route path="feedback" element={<Feedback/>} />
           <Route path="subscriptions" element={<Subscriptions/>} />
           <Route path="myAccount" element={<MyAccount/>} />
           <Route path="referralcode" element={<ReferralCodePage/>} />
           
          
           <Route path="*" element={<Navigate replace to="dashboard" />} />
         </Routes>
        
       </div>
    </div>
   </div>
    </>

   

  )
}


export default AuthenticatedRoutes ;
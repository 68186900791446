import API from "../urlConstants"

export const getAllModules = async (page = 1, limit = 10) => {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}admin/getAllQuiz?page=${page}&limit=${limit}`,
       {
        method:"GET",
        headers:{
            "Content-Type":"application/json",
            'Authorization': `${sessionStorage.getItem("better-dating-app-auth-token")}`
        },
       }
       
    );
    return await response.json();
}



export const addModules=async(data)=>{
    const moduleKey = data.associatedTrait
    .toLowerCase() 
    .split(' ')   
    .map((word, index) => {
        if (index === 0) {
            return word;
        } else {
            return word.charAt(0).toUpperCase() + word.slice(1); 
        }
    })
    .join(''); 


    console.log({moduleKey});
    debugger;

    data={
        ...data,
        moduleKey:moduleKey
    }
    
    const response=await fetch(process.env.REACT_APP_BASE_URL+"admin/addQuiz",
    {
        method:"POST",
        headers:{
            "Content-Type":"application/json",
            'Authorization': `${sessionStorage.getItem("better-dating-app-auth-token")}`
        },
        body: JSON.stringify(data)
    }
    )
    return await response.json();
}


export const deleteModule=async(data)=>{
    const response=await fetch(process.env.REACT_APP_BASE_URL+`admin/deleteQuiz?id=${data}`,
    {
        method:"DELETE",
        headers:{
            "Content-Type":"application/json"
        },
    }
    )
    return await response.json();
}

export const updateModule=async(data)=>{
    
    const response=await fetch(process.env.REACT_APP_BASE_URL+`admin/updateQuiz`,
    {
        method:"PUT",
        headers:{
            "Content-Type":"application/json"
        },
        body: JSON.stringify(data)
    }
    )
    return await response.json();
}

export const addQuiz=async(data)=>{
    
    const response=await fetch(process.env.REACT_APP_BASE_URL+`admin/addQuiz`,
    {
        method:"POST",
        headers:{
            "Content-Type":"application/json"
        },
        body: JSON.stringify(data)
    }
    )
    return await response.json();
}

export const getParticularModule=async({_id})=>{
    try{
       
    
        const response=await fetch(process.env.REACT_APP_BASE_URL+`admin/content/getParticularModule/${_id}`,
            {
                method:"GET",
                headers:{
                    "Content-Type":"application/json",
                    'Authorization': `${sessionStorage.getItem("better-dating-app-auth-token")}`
                },
            }
        )
        return await response.json();
        
    }
    catch(error){
        console.log("error", error.message)
    }
   
}
export const deleteThisQuestion=async(data)=>{
    
    const response=await fetch(process.env.REACT_APP_BASE_URL+`admin/content/deleteThisQuestion`,
    {
        method:"DELETE",
        headers:{
            "Content-Type":"application/json"
        },
        body: JSON.stringify(data)
    }
    )
    return await response.json();
}



import { Avatar } from "@mui/material";
import { Switch } from "@mui/material";
import React from "react";
import classes from "./ChatBox.module.css";
import Button from "../../../commonComponents/Common/Button/Button";
import ForumIcon from "@mui/icons-material/Forum";
import { useEffect } from "react";
import { queryMessages } from "./firebaseQueries";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../../store/slices/loader";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { useState } from "react";
import moment from "moment";
import { db } from "../firebase";
import { useRef } from "react";
import { toast } from "react-toastify";
import GenericModal from "../../../commonComponents/GenericModal/GenericModal";
import { HashLoader } from "react-spinners";
import { v4 as uuidv4 } from "uuid";
import { resetWarningCache } from "prop-types";

const ChatBox = ({ room }) => {
  const [disableSendBtn, setDisableSendBtn] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [otherUser, setOtherUser] = useState(null);
  const [inputMessage, setInputMessage] = useState("");
  const [chatMedia, setChatMedia] = useState("");
  const [mediaPreview, setMediaPreview] = useState("");
  const [mediaType, setMediaType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageComp, setImageComp] = useState(
    <div className="w-[100%] text-center">Loading...</div>
  );

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  let currentUser = "support";
  const messagesEndRef = useRef(null);
  const messagesReference = collection(
    db,
    `userSupport/${room?.uniqueId}/messages`
  );
  useEffect(() => {
    setMediaPreview("");
    setMediaType("");
    setChatMedia("");
    setInputMessage("");
    const usersId = Object.keys(room?.userDetails);
    const userId = usersId.filter((id) => id !== "support")[0];
    setOtherUser(room?.userDetails?.[userId]);
    let messagesRef = queryMessages(room?.uniqueId);
    const unsubscribe = onSnapshot(messagesRef, (snapshot) => {
      let messages = [];
      snapshot.forEach((doc) => {
        messages.push({ ...doc.data() });
      });
      setChatMessages(messages);
    });
    return () => unsubscribe();
  }, [room]);


  // Custom content for the loading toast
  const LoadingToast = ({ message }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {/* <ClipLoader size={16} color={"#6F2395"} loading={true} /> */}
      <HashLoader color="#6f2395" size={28} />
      <span style={{ marginLeft: "10px" }}>{message}</span>
    </div>
  );

  async function uploadMedia(formData) {
    try {
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "admin/uploadFile",
        {
          method: "POST",
          body: formData,
          // headers: {
          //   'Authorization': `Bearer ${sessionStorage.getItem("better-dating-app-auth-token")}`
          // },
          headers: {
            Authorization: `${sessionStorage.getItem(
              "better-dating-app-auth-token"
            )}`,
          },
        }
      );

      const result = await response.json();
      if (response.ok) {
        // Update the state with the new video URL from the upload response
        // Reset or clear the 'uploadNewVideoLen' after successful upload if necessary

        return result;
      } else {
        // Handle HTTP error responses

        throw new Error(result.message || "Failed to upload video");
      }
    } catch (error) {
      console.error("Error uploading file:", error.message);
      // Implement your error handling logic here
      toast.error("Unsuccessfull file upload", {
        autoClose: 5000,
      });
    }
  }
  const markMessageRead = async () => {

    if (
      room &&
      room?.lastMessage?.read &&
      room?.lastMessage?.sentBy !== "support"
    ) {
      try {
        let chatRoomRef = doc(db, "userSupport", room?.uniqueId);
        await updateDoc(chatRoomRef, {
          lastMessage: {
            sentBy: room?.lastMessage?.sentBy,
            message: room?.lastMessage?.message,
            createdAt: serverTimestamp(),
            read: false,
          },
        });
      } catch (error) {
        toast.error("Could not mark the last message as read.");
      }
    }
  };
  useEffect(() => {
    scrollToBottom();
    markMessageRead();
  }, [chatMessages]);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const getUserDetails = (userId) => {
    return room?.userDetails?.[userId];
  };
  const handleSubmit = async (e) => {
    debugger
    debugger
    e.preventDefault();
    const _inputMessage = inputMessage;
    setInputMessage("");
    const _mediaPreview = mediaPreview;
    setMediaPreview("");
    let chatRoomRef = doc(db, "userSupport", room?.uniqueId);
    if (!inputMessage.trim() && !chatMedia) return;
    try {
      const myId=uuidv4();
      let sendObj={
        text: _inputMessage,
        media: {
          name: _mediaPreview,
          type: mediaType.includes("image")
            ? "image"
            : mediaType.includes("video")
            ? "video"
            : "",
          url: chatMedia?.fileUrl || "",
          thumbnail: chatMedia?.thumbnailUrl || "",
        },
        messageType: mediaType.includes("image")
          ? "image"
          : mediaType.includes("video")
          ? "video"
          : "text",
        sentBy: "support",
        sentTo: otherUser?.id,
        createdAt: serverTimestamp(),
        _id: myId
      }
      if (mediaPreview) {
        if (mediaType.includes("image")) {
          sendObj['image'] = chatMedia?.fileUrl
        }
        if (mediaType.includes("video"))
          sendObj['video'] = chatMedia?.fileUrl
      }
      await addDoc(messagesReference, sendObj);
      await updateDoc(chatRoomRef, {
        lastMessage: {
          sentBy: currentUser,
          createdAt: serverTimestamp(),
          message: _inputMessage
            ? _inputMessage
            : `${
                currentUser === "support" ? "Support Center" : user?.name
              } uploaded a new ${mediaType}`,
          read: false,
        },
      });

      const myData = {
        userId: otherUser?.id,
        message: _inputMessage,
        messageType: mediaType.includes("image")
          ? "image"
          : mediaType.includes("video")
          ? "video"
          : "text",
        roomId: room?.roomId,
      };

      debugger;

      const result = await fetch(
        process.env.REACT_APP_BASE_URL + "call/notification",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(myData),
        }
      );

      setMediaType("");
      setChatMedia("");
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleToggle = async (data) => {
    let chatRoomRef = doc(db, "userSupport", room?.uniqueId);
    const docSnap = await getDoc(chatRoomRef);
    let mydata;

    if (docSnap.exists()) {
      // console.log(docSnap.data());
      // debugger;
      mydata = docSnap.data();
    }
    try {
      await updateDoc(chatRoomRef, {
        isOpen: !data,
        lastMessage: {
          ...mydata?.lastMessage,
          createdAt: serverTimestamp(),
        },
      });
    } catch (error) {
      toast.error(error?.message);
    }
  };

  return (
    <div
      className={`pkj_flex-col  pkj_ht-100 pkj_just-sp-bwn ${classes.right_container}`}
    >
      <div className={classes.top_half}>
        {room && (
          <div
            className="pkj_flex-row  pkj_align-center pkj_wd-100 pkj_just-sp-bwn"
            style={{ paddingBottom: "10px", borderBottom: "1px solid #EFEFEF" }}
          >
            <div
              className={`pkj_flex-row pkj_normal-gap pkj_align-center ${classes.chat_heading}`}
            >
              <Avatar src={otherUser?.image} alt={otherUser?.fullName} />
              <div className="pkj_flex-col">
                <h3>{otherUser?.fullName}</h3>
                {/* <p>
                  Booking Id {room?.uniqueId.toUpperCase()} | {room?.roomName}
                </p> */}
              </div>
            </div>

            {room.roomName === "Promotions" ? (
              <div className="flex cursor-pointer justify-center gap-1 items-center">
                <div
                  style={{ color: "#f1426d" }}
                  className="bg-white font-medium  py-1  rounded-lg text-white"
                >
                  {"Promotions"}
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="#f1426d"
                    viewBox="0 0 256 256"
                  >
                    <path d="M228,120c0,22.63-6,36.72-17.93,41.87a27.3,27.3,0,0,1-11,2.13,41.75,41.75,0,0,1-8.4-.93,4.05,4.05,0,0,1-2.52-1.64,368.49,368.49,0,0,0-47.75-55.26,8,8,0,0,0-11,11.62c14.84,13.91,64.13,63.49,78.32,120.27a8,8,0,0,1-5.82,9.7A8.13,8.13,0,0,1,200,248a8,8,0,0,1-7.75-6.06c-4.12-16.47-11.65-32.48-20.46-47.09a25.85,25.85,0,0,1-1.9,7.21C164.72,214,150.63,220,128,220s-36.72-6-41.88-17.94c-5.45-12.58-.39-30.82,15-54.21.68-1,1.36-2,2-3l-3,2C82.84,158.27,68.35,164,56.89,164a27.3,27.3,0,0,1-11-2.13C34,156.72,28,142.63,28,120s6-36.72,17.93-41.88c12.59-5.45,30.83-.39,54.22,15l3,2q-1-1.5-2-3c-15.41-23.39-20.47-41.63-15-54.22C91.28,26,105.37,20,128,20s36.72,6,41.88,17.93c5.45,12.59.39,30.83-15,54.22q-1,1.53-2,3l3-2c23.39-15.41,41.63-20.47,54.22-15C222,83.28,228,97.37,228,120Z"></path>
                  </svg>
                </div>
              </div>
            ) : (
              <div className="flex  justify-center gap-3 items-center">
                <Switch
                  checked={room?.isOpen}
                  onChange={() => {
                    handleToggle(room?.isOpen);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "#228B26",
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: "#228B26",
                    },
                  }}
                />
                <div
                  style={{
                    backgroundColor: room.isOpen ? "#228B26" : "#D34343",
                  }}
                  className="bg-green-500 px-4 py-1 rounded-lg text-white"
                >
                  {room?.isOpen ? "Open" : "Closed"}
                </div>
              </div>
            )}
          </div>
        )}
        {chatMessages?.length !== 0 ? (
          <div
            className={`${classes.chat_component} pkj_overflow-auto pkj_hidden-scroll`}
          >
            {chatMessages.map((message) => {
              const userInfo = getUserDetails(message?.sentBy);
              if (message?.media?.url) {
                if (message?.media?.type === "image") {
                  return (
                    <div
                      key={message?.createdAt + message?.sentBy}
                      ref={messagesEndRef}
                      className={`pkj_flex-col pkj_normal-gap ${
                        classes.chat_message
                      } ${
                        message?.sentBy === currentUser ? "pkj_align-right" : ""
                      }`}
                    >
                      <div
                        className={`${
                          message?.sentBy === currentUser
                            ? "pkj_flex-reverse-row"
                            : "pkj_flex-row"
                        } pkj_normal-gap pkj_align-center`}
                      >
                        <Avatar
                          src={userInfo?.image}
                          alt={userInfo?.fullName}
                          sx={{ height: "30px", width: "30px" }}
                        />
                        <h3>{userInfo?.fullName}</h3>
                        {/* <p>
                      {moment.unix(message?.createdAt?.seconds).format("lll")}
                    </p> */}
                      </div>
                      <div
                        className={`pkj_medium-gap pkj_flex-col ${
                          message?.sentBy === currentUser
                            ? classes.sending_message
                            : classes.incoming_message
                        }`}
                        onClick={() => {
                          setImageComp(
                            <div className="flex items-center justify-center w-full h-[60vh] overflow-y-auto p-1">
                              {" "}
                              <img src={message?.media?.url} />
                            </div>
                          );
                          handleModalToggle();
                        }}
                      >
                        <div
                          className={`pkj_flex-col pkj_medium-gap ${
                            message?.sentBy === currentUser
                              ? "pkj_flex-reverse-row"
                              : "pkj_flex-row"
                          }`}
                          style={{
                            textAlign:
                              message?.sentBy === currentUser
                                ? "right"
                                : "left",
                          }}
                        >
                          <img src={message?.media?.url} width={200} />
                          {/* {message?.message} */}
                          {message?.text}
                        </div>
                        <p
                          style={{
                            textAlign:
                              message?.sentBy === currentUser
                                ? "right"
                                : "left",
                          }}
                        >
                          {message?.createdAt ? (
                            moment
                              .unix(message?.createdAt?.seconds)
                              .format("lll")
                          ) : (
                            <div>sending..</div>
                          )}
                        </p>
                      </div>
                    </div>
                  );
                } else if (message?.media?.type === "video") {
                  return (
                    <div
                      key={message?.createdAt + message?.sentBy}
                      ref={messagesEndRef}
                      className={`pkj_flex-col pkj_normal-gap ${
                        classes.chat_message
                      } ${
                        message?.sentBy === currentUser ? "pkj_align-right" : ""
                      }`}
                    >
                      <div
                        className={`${
                          message?.sentBy === currentUser
                            ? "pkj_flex-reverse-row"
                            : "pkj_flex-row"
                        } pkj_normal-gap pkj_align-center`}
                      >
                        <Avatar
                          src={userInfo?.image}
                          alt={userInfo?.fullName}
                          sx={{ height: "30px", width: "30px" }}
                        />
                        <h3>{userInfo?.fullName}</h3>
                        {/* <p>
                      {moment.unix(message?.createdAt?.seconds).format("lll")}
                    </p> */}
                      </div>
                      <div
                        className={`pkj_medium-gap pkj_flex-col ${
                          message?.sentBy === currentUser
                            ? classes.sending_message
                            : classes.incoming_message
                        }`}
                      >
                        <div
                          className={`pkj_flex-col pkj_medium-gap ${
                            message?.sentBy === currentUser
                              ? "pkj_flex-reverse-row"
                              : "pkj_flex-row"
                          }`}
                          style={{
                            textAlign:
                              message?.sentBy === currentUser
                                ? "right"
                                : "left",
                          }}
                        >
                          <video width="320" height="240" controls>
                            <source
                              src={message?.media?.url}
                              type="video/mp4"
                            />
                          </video>
                          {/* {message?.message} */}
                          {message?.text}
                        </div>
                        <p
                          style={{
                            textAlign:
                              message?.sentBy === currentUser
                                ? "right"
                                : "left",
                          }}
                        >
                          {message?.createdAt ? (
                            moment
                              .unix(message?.createdAt?.seconds)
                              .format("lll")
                          ) : (
                            <div>sending..</div>
                          )}
                        </p>
                      </div>
                    </div>
                  );
                }
              }
              return (
                <div
                  key={message?.createdAt + message?.sentBy}
                  ref={messagesEndRef}
                  className={`pkj_flex-col pkj_normal-gap ${
                    classes.chat_message
                  } ${message?.sentBy === "support" ? "pkj_align-right" : ""}`}
                >
                  <div
                    className={`${
                      message?.sentBy === currentUser
                        ? "pkj_flex-reverse-row"
                        : "pkj_flex-row"
                    } pkj_normal-gap pkj_align-center`}
                  >
                    <Avatar
                      src={userInfo?.image}
                      alt={userInfo?.fullName}
                      sx={{ height: "30px", width: "30px" }}
                    />
                    <h3>{userInfo?.fullName}</h3>
                    {/* <p>
                      {moment.unix(message?.createdAt?.seconds).format("lll")}
                    </p> */}
                  </div>
                  <div
                    className={`${
                      message?.sentBy === currentUser
                        ? classes.sending_message
                        : classes.incoming_message
                    }`}
                  >
                    <div
                      className={`${
                        message?.sentBy === currentUser
                          ? "pkj_flex-reverse-row"
                          : "pkj_flex-row"
                      }`}
                      style={{
                        textAlign:
                          message?.sentBy === currentUser ? "right" : "left",
                      }}
                    >
                      {/* {message?.message??message?.text} */}
                      {message?.message || message?.text}
                    </div>
                    <p
                      style={{
                        textAlign:
                          message?.sentBy === currentUser ? "right" : "left",
                      }}
                    >
                      {message?.createdAt ? (
                        moment.unix(message?.createdAt?.seconds).format("lll")
                      ) : (
                        <div>sending..</div>
                      )}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div
            className="pkj_flex-row pkj_justify-center pkj_align-center pkj_ht-100"
            style={{ fontSize: "150px", color: "#d0f2f0" }}
          >
            <ForumIcon fontSize="inherit" />
          </div>
        )}
      </div>
      {room && (
        <>
          {mediaPreview && (
            <div
              style={{
                position: "relative",
                width: "min-content",
              }}
            >
              <p className={`pkj_subtle-label ${classes.mediaPreview}`}>
                {mediaPreview}
              </p>
              <div
                className={classes.media_cross}
                onClick={() => {
                  setMediaPreview("");
                  setChatMedia("");
                  setMediaType("");
                }}
              >
                X
              </div>
            </div>
          )}
          <form
            className={`pkj_wd-100 pkj_flex-row pkj_align-center ${classes.input_form}`}
            onSubmit={handleSubmit}
          >
            {room?.isOpen ? (
              <div className="pkj_flex-row pkj_align-center pkj_wd-100 pkj_normal-gap">
                <div
                  style={{ color: "#a1a0a3" }}
                  className="pkj_pointer"
                  onClick={() => {
                    document.getElementById("chat_media").click();
                  }}
                >
                  <PermMediaIcon />
                </div>
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="chat_media"
                  accept=".mp4, .jpeg, .jpg, .png"
                  onChange={(e) => {
                    setMediaPreview(e.target.files["0"]?.name);
                    setMediaType(e.target.files["0"].type);
                    // dispatch(loaderActions.toggleLoading(true));
                    setDisableSendBtn(true);
                    const toastId = toast.info(
                      <LoadingToast message="Uploading Media" />,
                      {
                        autoClose: false,
                        closeOnClick: false,
                        draggable: false,
                        hideProgressBar: true,
                        icon: false, // Disable the default icon
                      }
                    );
                    const formData = new FormData();
                    formData.append("file", e.target.files[0]);

                    uploadMedia(formData)
                      .then((res) => {
                        setChatMedia({...res});
                        toast.success("Media upload successfully!!", {
                          autoClose: 5000,
                        });
                      })
                      .catch((error) => {
                        toast.error(error?.message, {
                          toastId: "chat message media upload",
                        });
                        setChatMedia("");
                        setMediaPreview("");
                        setMediaType("");
                      })
                      .finally(() => {
                        // dispatch(loaderActions.toggleLoading(false))
                        toast.dismiss(toastId);
                        setDisableSendBtn(false);
                      });
                    e.target.value = "";
                  }}
                />
                <input
                  placeholder="Type something..."
                  className={classes.chat_input}
                  value={inputMessage}
                  onChange={(e) => setInputMessage(e.target.value)}
                />
                <Button disabled={disableSendBtn} title="Send" type="submit" />
              </div>
            ) : (
              <div className=" text-red-500  w-full flex justify-center ">
                <input placeholder="Chat has been closed!" disabled={true} />
              </div>
            )}
          </form>
        </>
      )}

      {isModalOpen && (
        <GenericModal
          open={isModalOpen}
          handleClose={handleModalToggle}
          content={imageComp}
          isCross={true}
          title="Preview"
          width="738px"
          height="800px"
          onClose={handleModalToggle}
        />
      )}
    </div>
  );
};

export default ChatBox;

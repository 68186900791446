import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import classes from "./SearchInput.module.css";

const SearchInput = (props) => {
  return (
    <div className="pkj_flex-row xl:h-16 pkj_align-center pkj_bg-light pkj_pd-10 pkj_bord-rd-6 pkj_color-grey-secondary pkj_subtle-border pkj_small-gap">
      <SearchIcon sx={{ opacity: "0.4000000059604645" }} />
      <input
        className={`${classes.searchInput} pkj_bg-light pkj_wd-100`}
        type="text"
        {...props}
      />
    </div>
  );
};

export default SearchInput;

import React from "react";
import { Modal, Box, Typography } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { fontWeight } from "@mui/system";

const GenericModal = ({ open, onClose, title,   content, caption,width,height,handleClose  }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="generic-modal-title"
      className="flex justify-center items-center p-4 overflow-y-clip my-auto xl:max-h-[99vh]"
      sx={{ zIndex: 50, }}>
      <Box className="w-full max-w-3xl mx-auto overflow-hidden bg-white rounded-lg shadow-lg">
        <div className="flex justify-end px-4 py-2">
          <CloseIcon onClick={onClose} className="cursor-pointer" />
        </div>
        <div className="px-4 py-2	">
          <div id="generic-modal-title" variant="h5" component="h2" className="text-2xl 2xl:text-3xl font-semibold	    text-center">
            {title}
          </div>
          <Typography variant="caption" display="block" className="text-sm text-center text-gray-500">
            {caption}
          </Typography>
        </div>
        <div className="p-4">
          {content}
        </div>
      </Box>
    </Modal>
  );
};

export default GenericModal;

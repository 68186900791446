import { collection, orderBy, query, where } from "firebase/firestore";
import { db } from "../firebase";

export const queryRooms = (userId) => {
  console.log("queryRooms called with userId:", userId); // Debug log

  return query(
    collection(db, "userSupport"),
    // where("chatType", "==", "Support"),
    orderBy("lastMessage.createdAt", "desc"),
  );
}



// export const queryGroupRoom = (userId) =>
//   query(
//     collection(db, "groups"),
//     where("users", "array-contains", "66152fb8e6c23de4980b3f95"),
//     
//   );

export const queryClientRoom = (userId) =>
  query(
    collection(db, "userSupport"),
    where("users", "array-contains", userId),
    where("type", "==", "direct-client"),
    orderBy("lastMessage.createdAt", "desc")
  );

export const queryMessages = (roomId) => {
  const messagesRef = query(
    collection(db, `userSupport/${roomId}/messages`),
    orderBy("createdAt", "asc")
  );

  return messagesRef;
};

export const queryGroupMessages = (roomId) => {
  const messagesRef = query(
    collection(db, `groups/${roomId}/messages`),
    orderBy("createdAt", "asc")
  );

  return messagesRef;
};

import React, { useState } from "react";
import Notification from "../../../assests/icons/notification.png";
import Badge from "@mui/material/Badge";
import ArrowDown from "../../../assests/icons/arrow-down.png";
import ProfilePopUp from "./profilePopUp";
import NotificationPopUp from "./NotificationPopUp";
import User from "../../../assests/icons/user.png";
import Popover from "@mui/material/Popover";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import store from "../../store/store";
import NotificationsIcon from '@mui/icons-material/Notifications';

const Navbar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2]=useState(null);


  // const [clickCount, setClickCount] = useState(0);
  // const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNotification = (e) => {
    setAnchorE2(e.currentTarget);
    // setClickCount(clickCount + 1)
    // navigate("/notifications", {
    //   state: {
    //     eventTriggered: clickCount
    //   },
    // });
  };
  const handleClose2 = () => {
    setAnchorE2(null);
  };
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorE2);
  const id = open ? "simple-popover" : undefined;
  const id2 = open2 ? "notification-popover" : undefined;
  const userDetails = useSelector((store) => store?.userDetails)
 
  return (
    <div className="w-full z-10 right-0 h-16 px-6 py-3 bg-white shadow justify-end items-start gap-3 inline-flex">
      <div className="justify-center items-center gap-5 flex">
        <div className="w-8 h-8 justify-center items-center flex">

          <div className="w-8 h-8 relative">
          <Badge
            color="primary"
            onClick={(e) => {
              handleNotification(e);
            }}
            className="relative hover:bg-purple-100 p-2 rounded-3xl"
            style={{backgroundColor:userDetails?.newNotification===0?"#fff":"#F3E8FF"}}
          >
            <NotificationsIcon sx={{ fill: "#71717A" }} />
            <div style={{display:userDetails?.newNotification===0?"none":"flex"}} className="absolute -top-2 -right-2 bg-[#6f2395] text-white text-xs font-bold w-5 h-5 flex items-center justify-center rounded-full">
              {userDetails?.newNotification}
            </div>
          </Badge>
          </div>
        </div>
        <button type="button"
          className="justify-center items-center gap-3 flex cursor-pointer"
          aria-describedby={id}
          onClick={handleClick}
        >
          <div className="bg-gray-300 w-10 h-10 rounded-full">
            <img className="w-[inherit] h-[inherit] rounded-full" src={userDetails?.profileImg || User} alt="" />
          </div>
          <div className="flex-col justify-start items-start inline-flex">
            <div className="text-black text-base font-semibold font-['Plus Jakarta Sans']">
              {userDetails?.userName}

            </div>
            <div className="text-zinc-500 text-sm font-normal font-['Plus Jakarta Sans']">
              {userDetails?.email}
            </div>
          </div>
          {/* <div className="w-6 h-6 justify-center items-center flex">
            <div className="w-6 h-6 relative">
              <img src={ArrowDown} alt="arrow icon" />
            </div>
          </div> */}
        </button>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="mt-3"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ProfilePopUp handleClose={handleClose}/>
      </Popover>


      
      {/* notification popup */}
      <Popover
        id={id2}
        open={open2}
        anchorEl={anchorE2}
        onClose={handleClose2}
        className="mt-2 ml-[-18rem]"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <NotificationPopUp handleClose={handleClose2}/>
      </Popover>

      
    </div>
  );
};
export default Navbar;

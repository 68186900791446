import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/auth.js";
import loaderReducer from "./slices/loader";
import userDetailsReducer from "./slices/userDetails.js"
import { loadState,saveState } from "./persistData.js";

const preloadedState = loadState(); // Load state from localStorage

const store=configureStore({
    reducer:{
        auth:authReducer,
        loader: loaderReducer,
        userDetails: userDetailsReducer
    },
    preloadedState,
})


store.subscribe(() => {
    saveState(store.getState()); // Save state to localStorage on every state change
});

export default store;
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CustomButton from '../../commonComponents/Button/Button';
import { deleteThisQuestion, updateModule, addQuiz, getParticularModule } from '../../API/apis/contentApi';
import { toast } from "react-toastify";
import GenericModal from '../../commonComponents/GenericModal/GenericModal';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from '../../store/slices/loader';
import { HashLoader, ClipLoader, SyncLoader, BounceLoader, PacmanLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
// import temp from "../../../assests/temp.mp4"

// Custom content for the loading toast
const LoadingToast = ({ message }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {/* <ClipLoader size={16} color={"#6F2395"} loading={true} /> */}
    <HashLoader
      color="#6f2395"
      size={28}
    />
    <span style={{ marginLeft: '10px' }}>{message}</span>

  </div>
);

const EditModuleModalComp = ({ close, pushUpdatedToAllModuleData, data }) => {
  const navigate = useNavigate();
  const [myModuleData, setMyModuleData] = useState({ moduleTitle: '', description: '', associatedTrait: '' })
  const [moduleTitle, setModuleTitle] = useState(myModuleData?.moduleTitle);
  const [description, setDescription] = useState(myModuleData?.description);
  const [video, setVideo] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [addNewQuestion, setAddNewQuestion] = useState(false);
  const [addNewQuestion2, setAddNewQuestion2] = useState(false);
  const [addNewQuestion3, setAddNewQuestion3] = useState(false);
  const [descriptiveKey, setDescriptiveKey] = useState(false);
  const [descriptiveKey2, setDescriptiveKey2] = useState(false);
  const [descriptiveKey3, setDescriptiveKey3] = useState(false);
  const [triggerFetchData, setTriggerFetchData] = useState(false);
  const [btn1, setBtn1] = useState({ BackgroundColor: "#e5e7eb", textColor: "#ffffff" });
  const [trainingVideo1, setTrainingVideo1] = useState(null);
  const [trainingVideo2, setTrainingVideo2] = useState(null);
  const [trainingVideo3, setTrainingVideo3] = useState(null);
  const [uploadNewVideoLen, setUploadNewVideoLen] = useState(null);
  const [disableBtn, setDisableBtn] = useState(false)


  const dispatch = useDispatch();



  async function deleteQuestion(index) {

    setTrainingVideo1(prevState => {
      // Create a copy of the state object
      const newState = { ...prevState };

      // Create a copy of the questions array and remove the question at the specified index
      newState.questions = [...prevState.questions.slice(0, index), ...prevState.questions.slice(index + 1)];

      return newState; // Return the updated state object
    });
  }
  async function deleteQuestion2(index) {

    setTrainingVideo2(prevState => {
      // Create a copy of the state object
      const newState = { ...prevState };

      // Create a copy of the questions array and remove the question at the specified index
      newState.questions = [...prevState.questions.slice(0, index), ...prevState.questions.slice(index + 1)];

      return newState; // Return the updated state object
    });
  }
  async function deleteQuestion3(index) {

    setTrainingVideo3(prevState => {
      // Create a copy of the state object
      const newState = { ...prevState };

      // Create a copy of the questions array and remove the question at the specified index
      newState.questions = [...prevState.questions.slice(0, index), ...prevState.questions.slice(index + 1)];

      return newState; // Return the updated state object
    });
  }


  async function fetchParticularModuleData() {
    const _id = data?._id;




    const result = await getParticularModule({ _id });
    if (result?.status === 425) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/login");
      return;
    }

    return result.response;

  }

  useEffect(() => {
    async function fetchData() {
      const updatedData = await fetchParticularModuleData();

      console.log({ updatedData });


      if (updatedData) {
        setMyModuleData(updatedData);
        setModuleTitle(updatedData.moduleTitle);
        setDescription(updatedData.description);

        // setting the individual data to the respective state
        updatedData?.quiz.map((eachVideo) => {
          if (eachVideo?.duration === 5) {
            setTrainingVideo1(eachVideo);

          }
          else if (eachVideo?.duration === 7) {
            setTrainingVideo2(eachVideo);

          }
          else {
            setTrainingVideo3(eachVideo);

          }

        })
      }
    }

    fetchData();
  }, []);



  // State to track each option's value
  const [options, setOptions] = useState({
    question: '',
    optionA: '',
    optionB: '',
    optionC: '',
    optionD: '',
    correctAnswer: ''
  });

  const [options2, setOptions2] = useState({
    question: '',
    optionA: '',
    optionB: '',
    optionC: '',
    optionD: '',
    correctAnswer: ''
  });

  const [options3, setOptions3] = useState({
    question: '',
    optionA: '',
    optionB: '',
    optionC: '',
    optionD: '',
    correctAnswer: ''
  });

  // Handle change for each option
  const handleOptionChange = (event) => {
    const { name, value } = event.target;
    setOptions(prevOptions => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const handleOptionChange2 = (event) => {
    const { name, value } = event.target;
    setOptions2(prevOptions => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const handleOptionChange3 = (event) => {
    const { name, value } = event.target;
    setOptions3(prevOptions => ({
      ...prevOptions,
      [name]: value,
    }));
  };
  const saveQuestion = async (videoData) => {

    if (descriptiveKey) {

      if (options?.question === "") {

        toast.error("Please add the question.", {
          autoClose: 5000
        });
        return;

      }


      const newQuestionData = {
        question: options?.question,
        answers: [
          options?.optionA,
          options?.optionB,
          options?.optionC,
          options?.optionD,
        ],
        correctAnswer: 7
      }
      setTrainingVideo1({
        ...trainingVideo1,
        questions: [...trainingVideo1?.questions, newQuestionData]
      })
      setOptions({
        question: '',
        optionA: '',
        optionB: '',
        optionC: '',
        optionD: '',
        correctAnswer: ''
      })

    }

    else {

      if (options?.question === "" || options?.optionA === "" || options?.optionB === "" || options?.optionC === "" || options?.optionD === "" || options?.correctAnswer === "") {

        if (options?.question === "") {
          toast.error("Please add the question.", {
            autoClose: 5000
          });
          return;
        }
        else if (options?.optionA === "") {
          toast.error("Add Option 1", {
            autoClose: 5000
          });
          return;
        }
        else if (options?.optionB === "") {
          toast.error("Add Option 2", {
            autoClose: 5000
          });
          return;
        }
        else if (options?.optionC === "") {
          toast.error("Add Option 3", {
            autoClose: 5000
          });
          return;
        }
        else if (options?.optionD === "") {
          toast.error("Add Option 4", {
            autoClose: 5000
          });
          return;
        }
        else if (options?.correctAnswer === "") {
          toast.error("Select the Correct Option.", {
            autoClose: 5000
          });
          return;
        }
        else {
          toast.error("Please fill all the details.", {
            autoClose: 5000
          });
          return;

        }




        return;
      }

      const newQuestionData = {
        question: options?.question,
        answers: [
          options?.optionA,
          options?.optionB,
          options?.optionC,
          options?.optionD
        ],
        correctAnswer: options?.correctAnswer
      }
      setTrainingVideo1({
        ...trainingVideo1,
        questions: [...trainingVideo1?.questions, newQuestionData]
      })
      setOptions({
        question: '',
        optionA: '',
        optionB: '',
        optionC: '',
        optionD: '',
        correctAnswer: ''
      })
    }

    handleAddNewQuestion();

  }

  const saveQuestion2 = async (videoData) => {

    if (descriptiveKey2) {

      if (options2?.question === "") {

        toast.error("Please add the question.", {
          autoClose: 5000
        });
        return;

      }

      const newQuestionData = {
        question: options2?.question,
        answers: [
          options2?.optionA,
          options2?.optionB,
          options2?.optionC,
          options2?.optionD,
        ],
        correctAnswer: 7
      }
      setTrainingVideo2({
        ...trainingVideo2,
        questions: [...trainingVideo2?.questions, newQuestionData]
      })
      setOptions2({
        question: '',
        optionA: '',
        optionB: '',
        optionC: '',
        optionD: '',
        correctAnswer: ''
      })

    }

    else {
      if (options2?.question === "" || options2?.optionA === "" || options2?.optionB === "" || options2?.optionC === "" || options2?.optionD === "" || options2?.correctAnswer === "") {

        if (options2?.question === "") {
          toast.error("Please add the question.", {
            autoClose: 5000
          });
          return;
        }
        else if (options2?.optionA === "") {
          toast.error("Add Option 1", {
            autoClose: 5000
          });
          return;
        }
        else if (options2?.optionB === "") {
          toast.error("Add Option 2", {
            autoClose: 5000
          });
          return;
        }
        else if (options2?.optionC === "") {
          toast.error("Add Option 3", {
            autoClose: 5000
          });
          return;
        }
        else if (options2?.optionD === "") {
          toast.error("Add Option 4", {
            autoClose: 5000
          });
          return;
        }
        else if (options2?.correctAnswer === "") {
          toast.error("Select the Correct Option.", {
            autoClose: 5000
          });
          return;
        }
        else {
          toast.error("Please fill all the details.", {
            autoClose: 5000
          });
          return;

        }




        return;
      }

      const newQuestionData = {
        question: options2?.question,
        answers: [
          options2?.optionA,
          options2?.optionB,
          options2?.optionC,
          options2?.optionD,
        ],
        correctAnswer: options2?.correctAnswer
      }

      setTrainingVideo2({
        ...trainingVideo2,
        questions: [...trainingVideo2?.questions, newQuestionData]
      })
      setOptions2({
        question: '',
        optionA: '',
        optionB: '',
        optionC: '',
        optionD: '',
        correctAnswer: ''
      })
    }

    handleAddNewQuestion2();

  }


  const saveQuestion3 = async (videoData) => {

    if (descriptiveKey3) {

      if (options3?.question === "") {

        toast.error("Please add the question.", {
          autoClose: 5000
        });
        return;

      }

      const newQuestionData = {
        question: options3?.question,
        answers: [
          options3?.optionA,
          options3?.optionB,
          options3?.optionC,
          options3?.optionD,
        ],
        correctAnswer: 7
      }
      setTrainingVideo3({
        ...trainingVideo3,
        questions: [...trainingVideo3?.questions, newQuestionData]
      })
      setOptions3({
        question: '',
        optionA: '',
        optionB: '',
        optionC: '',
        optionD: '',
        correctAnswer: ''
      })

    }
    else {

      if (options3?.question === "" || options3?.optionA === "" || options3?.optionB === "" || options3?.optionC === "" || options3?.optionD === "" || options3?.correctAnswer === "") {

        if (options3?.question === "") {
          toast.error("Please add the question.", {
            autoClose: 5000
          });
          return;
        }
        else if (options3?.optionA === "") {
          toast.error("Add Option 1", {
            autoClose: 5000
          });
          return;
        }
        else if (options3?.optionB === "") {
          toast.error("Add Option 2", {
            autoClose: 5000
          });
          return;
        }
        else if (options3?.optionC === "") {
          toast.error("Add Option 3", {
            autoClose: 5000
          });
          return;
        }
        else if (options3?.optionD === "") {
          toast.error("Add Option 4", {
            autoClose: 5000
          });
          return;
        }
        else if (options3?.correctAnswer === "") {
          toast.error("Select the Correct Option.", {
            autoClose: 5000
          });
          return;
        }
        else {
          toast.error("Please fill all the details.", {
            autoClose: 5000
          });
          return;

        }




        return;
      }



      const newQuestionData = {
        question: options3?.question,
        answers: [
          options3?.optionA,
          options3?.optionB,
          options3?.optionC,
          options3?.optionD,
        ],
        correctAnswer: options3?.correctAnswer
      }
      setTrainingVideo3({
        ...trainingVideo3,
        questions: [...trainingVideo3?.questions, newQuestionData]
      })
      setOptions3({
        question: '',
        optionA: '',
        optionB: '',
        optionC: '',
        optionD: '',
        correctAnswer: ''
      })

    }


    handleAddNewQuestion3();

  }


  const handleAddNewQuestion = (isDescriptive) => {
    setAddNewQuestion(!addNewQuestion);


    if (isDescriptive) {
      setDescriptiveKey(true);
      setAddNewQuestion(!addNewQuestion);

    }
    else {
      setDescriptiveKey(false);
      setAddNewQuestion(!addNewQuestion);


    }

  }

  const handleAddNewQuestion2 = (isDescriptive) => {

    if (isDescriptive) {
      setDescriptiveKey2(true);
      setAddNewQuestion2(!addNewQuestion2);

    }
    else {
      setDescriptiveKey2(false);
      setAddNewQuestion2(!addNewQuestion2);


    }
  }
  const handleAddNewQuestion3 = (isDescriptive) => {
    if (isDescriptive) {
      setDescriptiveKey3(true);
      setAddNewQuestion3(!addNewQuestion3);

    }
    else {
      setDescriptiveKey3(false);
      setAddNewQuestion3(!addNewQuestion3);


    }

  }




  const handleVideoChange = async (event) => {
    console.log("initial", uploadNewVideoLen);
    const file = event.target.files[0];
    if (!file) return;

    // Create a URL for the video file
    const videoUrl = URL.createObjectURL(file);

    // Create a new video element and set its source to the file URL
    const videoElement = document.createElement("video");
    videoElement.src = videoUrl;

    // Load the video file metadata (like duration)
    videoElement.addEventListener("loadedmetadata", async () => {
      // Now you have access to the video's duration
      const videoDuration = videoElement.duration;
      console.log(`Duration: ${videoDuration} seconds`);
      console.log("initial", uploadNewVideoLen);

      // if (uploadNewVideoLen == 5) {
      //   if (videoDuration < 290 || videoDuration > 320) {
      //     console.log(videoDuration);
      //     toast.error("Video duration must be of 5 minutes.", {
      //       autoClose: 5000
      //     });

      //     return;
      //   }

      // }
      // else if (uploadNewVideoLen == 7) {

      //   if (videoDuration < 410 || videoDuration > 440) {
      //     toast.error("Video duration must be of 7 minutes.", {
      //       autoClose: 5000
      //     });

      //     return;
      //   }

      // }
      // else if (uploadNewVideoLen == 10) {

      //   if (videoDuration < 585 || videoDuration > 620) {
      //     toast.error("Video duration must be 10 minutes.", {
      //       autoClose: 5000
      //     });

      //     return;
      //   }

      // }

      // else {
      //   toast.error("Please check the video duration.", {
      //     autoClose: 5000
      //   });
      // }

      // You can now use the video duration to determine which video slot it fits into
      // And then proceed with your form data and fetch request
      const formData = new FormData();
      formData.append('file', file);

      const toastId = toast.info(<LoadingToast message="Uploading Video" />, {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        hideProgressBar: true,
        icon: false, // Disable the default icon

      });


      setUploadNewVideoLen(false);
      setBtn1({ BackgroundColor: "#e5e7eb", textColor: "#ffffff" });
      setDisableBtn(true);

      //Here i want to freeze the page, i mean user can't click anything



      try {

        // dispatch(loaderActions.toggleLoading(true));
        // Replace 'YOUR_API_ENDPOINT' with your actual API endpoint
        // If your API requires authentication, make sure to add an 'Authorization' header
        // const response = await fetch(process.env.REACT_APP_BASE_URL + 'admin/uploadFile', {
        const response = await fetch(process.env.REACT_APP_BASE_URL + 'admin/uploadCompressedFile', {
          method: 'POST',
          body: formData,
          // headers:{
          //   "authorization":sessionStorage.getItem("better-dating-app-auth-token")

          // },

          headers: {
            'Authorization': `${sessionStorage.getItem("better-dating-app-auth-token")}`
          },
        });


        // dispatch(loaderActions.toggleLoading(false))
        toast.dismiss(toastId);
        setDisableBtn(false)

        const result = await response.json();

        if (response.ok) {
          console.log("Uploaded file URL:", result.fileUrl);
          // Update the state with the new video URL from the upload response
          switch (uploadNewVideoLen) {
            case "5":
              setTrainingVideo1({
                video: result.fileUrl,
                questions: [],
                duration: 5,
              });
              break;
            case "7":
              setTrainingVideo2({
                video: result.fileUrl,
                questions: [],
                duration: 7,
              });
              break;
            case "10":
              setTrainingVideo3({
                video: result.fileUrl,
                questions: [],
                duration: 10,
              });
              break;
            default:
              console.log("Unsupported video length");
          }
          // Reset or clear the 'uploadNewVideoLen' after successful upload if necessary
          toast.success("Video upload successfully!!", {
            autoClose: 5000
          });
          setUploadNewVideoLen(null);
        } else {
          // Handle HTTP error responses
          throw new Error(result.message || 'Failed to upload video');
        }
      } catch (error) {
        console.error("Error uploading file:", error.message);
        // Implement your error handling logic here
      }
      // Release the object URL to free memory
      URL.revokeObjectURL(videoUrl);
    });
  };



  const handleVideoUpdate = (event) => {
    console.log("initial", uploadNewVideoLen);
    const file = event.target.files[0];
    if (!file) return;

    // Create a URL for the video file
    const videoUrl = URL.createObjectURL(file);

    // Create a new video element and set its source to the file URL
    const videoElement = document.createElement("video");
    videoElement.src = videoUrl;

    // Load the video file metadata (like duration)
    videoElement.addEventListener("loadedmetadata", async () => {
      // Now you have access to the video's duration
      const videoDuration = videoElement.duration;
      console.log(`Duration: ${videoDuration} seconds`);
      console.log("initial", uploadNewVideoLen);

      // if (uploadNewVideoLen == 5) {
      //   if (videoDuration < 290 || videoDuration > 320) {
      //     console.log(videoDuration);
      //     toast.error("Video duration must be of 5 minutes.", {
      //       autoClose: 5000
      //     });

      //     return;
      //   }

      // }
      // else if (uploadNewVideoLen == 7) {

      //   if (videoDuration < 410 || videoDuration > 440) {
      //     toast.error("Video duration must be of 7 minutes.", {
      //       autoClose: 5000
      //     });

      //     return;
      //   }

      // }
      // else if (uploadNewVideoLen == 10) {

      //   if (videoDuration < 585 || videoDuration > 620) {
      //     toast.error("Video duration must be 10 minutes.", {
      //       autoClose: 5000
      //     });

      //     return;
      //   }

      // }

      // else {
      //   toast.error("Please check the video duration.", {
      //     autoClose: 5000
      //   });
      // }

      // You can now use the video duration to determine which video slot it fits into
      // And then proceed with your form data and fetch request
      const formData = new FormData();
      formData.append('file', file);

      const toastId = toast.info(<LoadingToast message="Updating Video" />, {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        hideProgressBar: true,
        icon: false, // Disable the default icon

      });
      setDisableBtn(true);


      try {
        // Replace 'YOUR_API_ENDPOINT' with your actual API endpoint
        // If your API requires authentication, make sure to add an 'Authorization' header
        // const response = await fetch(process.env.REACT_APP_BASE_URL + 'admin/uploadFile', {
        const response = await fetch(process.env.REACT_APP_BASE_URL + 'admin/uploadCompressedFile', {
          method: 'POST',
          body: formData,
          // headers:{
          //   "authorization":sessionStorage.getItem("better-dating-app-auth-token")

          // },

          headers: {
            'Authorization': `${sessionStorage.getItem("better-dating-app-auth-token")}`
          },
        });

        const result = await response.json();

        if (response.ok) {
          console.log("Uploaded file URL:", result.fileUrl);
          // Update the state with the new video URL from the upload response
          switch (uploadNewVideoLen) {
            case "5":
              setTrainingVideo1({
                video: result.fileUrl,
                questions: [],
                duration: 5,
                isWatched: false
              });
              break;
            case "7":
              setTrainingVideo2({
                video: result.fileUrl,
                questions: [],
                duration: 7,
                isWatched: false
              });
              break;
            case "10":
              setTrainingVideo3({
                video: result.fileUrl,
                questions: [],
                duration: 10,
                isWatched: false
              });
              break;
            default:
              console.log("Unsupported video length");
          }

          toast.dismiss(toastId);
          setDisableBtn(false)

          // Reset or clear the 'uploadNewVideoLen' after successful upload if necessary
          toast.success("Video Updated Successfully!!", {
            autoClose: 5000
          });
          setUploadNewVideoLen(null);
        } else {
          // Handle HTTP error responses
          throw new Error(result.message || 'Failed to upload video');
        }
      } catch (error) {
        console.error("Error uploading file:", error.message);
        // Implement your error handling logic here
      }
      // Release the object URL to free memory
      URL.revokeObjectURL(videoUrl);
    });
  };

  const handleAdd = async () => {

    if (description.length > 1200) {
      toast.error("Description must be under 300 words.", {
        autoClose: 5000
      });
      return;
    }

    const _id = data?._id;
    const quiz = [];
    if (trainingVideo1) quiz.push(trainingVideo1);
    if (trainingVideo2) quiz.push(trainingVideo2);
    if (trainingVideo3) quiz.push(trainingVideo3);
    const dataa = {
      _id: myModuleData?._id,
      moduleTitle: moduleTitle,
      associatedTrait: myModuleData?.associatedTrait,
      description: description,
      quiz: quiz,

    }



    const result = await updateModule(dataa);


    if (result.status === 200) {
      pushUpdatedToAllModuleData(result?.response);
      close();
    }
    else {
      toast.error(result?.message, {
        autoClose: 5000
      });

    }



  };

  return (
    <div className="p-8 rounded-lg w-full max-w-6xl  h-full mx-auto ">
      <div className="space-y-4 flex-col h-full">

        <div className='flex gap-6'>

          <TextField
            fullWidth
            label="Associated Trait"
            variant="outlined"
            value={myModuleData?.associatedTrait}
            onChange={(e) => setModuleTitle(e.target.value)}
            disabled="true"
            sx={{
              '& label.Mui-focused': {
                color: '#57267B',
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: '#57267B',
                },
              },
            }}
          />
          <TextField
            fullWidth
            label="Module Title"
            variant="outlined"
            value={moduleTitle}
            onChange={(e) => setModuleTitle(e.target.value)}
            sx={{
              '& label.Mui-focused': {
                color: '#57267B',
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: '#57267B',
                },
              },
            }}
          />


        </div>

        <TextField
          fullWidth
          label="Description"
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          sx={{
            '& label.Mui-focused': {
              color: '#57267B',
            },
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: '#57267B',
              },
            },
          }}
        />

        {/* add line here  */}
        <hr style={{ margin: "20px 0" }} />

        {/* upload video section */}
        <div className='flex justify-between items-center'>

          <div className='text-[#57267b] text-center font-bold py-6 px-8'>Video and Quizes</div>



          <FormControl variant="outlined" className="mt-4 w-64">
            <InputLabel
              sx={{
                color: 'rgba(0, 0, 0, 0.54)', // default label color
                '&.Mui-focused': {
                  color: '#57267B', // label color when the input is focused
                },
              }}
            >
              Select Duration
            </InputLabel>
            <Select
              label="Upload Video"
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.23)', // default border color
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.54)', // border color on hover
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#57267B', // border color when the input is focused
                },
                '& .MuiSelect-icon': {
                  color: 'rgba(0, 0, 0, 0.54)', // default icon color
                },
                '&.Mui-focused .MuiSelect-icon': {
                  color: '#57267B', // icon color when the input is focused
                },
              }}
              onChange={(event) => {
                console.log(event.target.value);
                if (event.target.value) {
                  setBtn1({ BackgroundColor: "#57267b", textColor: "#ffffff" })
                }
                setUploadNewVideoLen(event.target.value);
              }}
            >
              {!trainingVideo1 && <MenuItem value="5">Novice</MenuItem>}
              {!trainingVideo2 && <MenuItem value="7">Intermediate</MenuItem>}
              {!trainingVideo3 && <MenuItem value="10">Advance</MenuItem>}
              {trainingVideo1 && trainingVideo2 && trainingVideo3 && <MenuItem value={0}>No Options Left</MenuItem>}


            </Select>
          </FormControl>


          <button style={{
            background: btn1?.BackgroundColor,
            color: btn1?.textColor,
            borderRadius: "6px",
            height: "45px",
            fontSize: "16px",
            fontWeight: "500",
            border: "none",
            padding: "10px 16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "4px"

          }}
            disabled={!uploadNewVideoLen}

            onClick={() => document.getElementById('file-input').click()}>
            Upload Video
            <input
              type="file"
              hidden
              accept="video/*"
              onChange={handleVideoChange}

              id="file-input" // Add an ID to the input for reference
            />
          </button>








        </div>

        {/* **************************************************************************************************** */}

        {/* <div>{trainingVideo1?.url}</div>
     <div>{trainingVideo2?.url}</div>
     <div>{trainingVideo3?.url}</div> */}


        <div className=' h-40 flex-grow'>


          {/* my video Details section */}

          {/* 5 min training video */}
          {trainingVideo1 &&
            <div key={trainingVideo1?._id} className='border border-gray-300 rounded p-4 mt-2 relative'>


              <div className='text-[#57267b] text-center font-semibold py-6 px-8'>Training Video & Quiz-1</div>

              <div className='flex-col'>
                {trainingVideo1?.video &&
                  <video key={trainingVideo1?.video} width="100%" controls>
                    <source src={trainingVideo1?.video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>}


                <div className='flex gap-4 my-4'>
                  <button style={{
                    background: "#57267b",
                    color: "#ffffff",
                    borderRadius: "6px",
                    height: "45px",
                    fontSize: "16px",
                    fontWeight: "500",
                    border: "none",
                    padding: "10px 16px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "4px"

                  }}
                    disabled={disableBtn}

                    onClick={() => {
                      setUploadNewVideoLen("5");
                      document.getElementById('file-input-upload-vid-1').click()
                    }}>
                    Update Video
                    <input
                      type="file"
                      hidden
                      accept="video/*"
                      onChange={handleVideoUpdate}
                      id="file-input-upload-vid-1" // Add an ID to the input for reference
                    />
                  </button>

                </div>
                {/* questions */}
                {trainingVideo1?.questions?.map((eachQuestion, index) => {
                  return (
                    <div key={eachQuestion?._id} className=' mt-5'>
                      <div onClick={() => {
                        // deleteQuestion(trainingVideo1?._id, eachQuestion?._id);
                        deleteQuestion(index);
                      }} className='flex justify-end  hover:cursor-pointer	' >
                        <DeleteOutlineIcon style={{ color: '#f91b1b' }} />
                      </div>

                      <div className="">
                        <div className="font-semibold text-lg px-1">{index + 1}. {eachQuestion?.question}</div>
                        {eachQuestion?.correctAnswer != 7 && <div className="bg-white rounded-lg px-4 py-2">
                          <div className={`py-2 border-b border-gray-200 ${eachQuestion?.correctAnswer === 1 ? "text-green-500" : ""}`}>A. {eachQuestion?.answers[0]}</div>
                          <div className={`py-2 border-b border-gray-200 ${eachQuestion?.correctAnswer === 2 ? "text-green-500" : ""}`}>B. {eachQuestion?.answers[1]}</div>
                          <div className={`py-2 border-b border-gray-200 ${eachQuestion?.correctAnswer === 3 ? "text-green-500" : ""}`}>C. {eachQuestion?.answers[2]}</div>
                          <div className={`pt-2 ${eachQuestion?.correctAnswer === 4 ? "text-green-500" : ""}`}>D. {eachQuestion?.answers[3]}</div>
                        </div>}
                      </div>

                    </div>

                  )
                })}


                {/* add questions segment  */}
                {addNewQuestion && (descriptiveKey ?
                  <div>

                    <div className='border mb-20 border-gray-300 rounded p-4 mt-2 relative'>
                      {/* <div onClick={() => {
                        handleAddNewQuestion3()
                      }} className=' w-fit ml-auto'><CancelOutlinedIcon sx={{ fill: "#9c9c9c" }} /></div> */}
                      {/* Question */}
                      <label htmlFor="question" className="block mt-4 text-sm font-light text-[#9c9c9c]">
                        Type Your Question Here
                      </label>
                      <input
                        type="text"
                        name="question"
                        id="question"
                        value={options.question}
                        onChange={handleOptionChange}
                        className="mt-1 px-4 py-2 block h-20 w-full rounded-md ring-1 ring-[#d1d5db] border-gray-300 shadow-sm focus:border-[#57267b] focus:ring-2 focus:ring-[#57267b]"
                      />


                      {/* my last control buttom */}
                      <div className=' mt-8 flex'>

                        {/* my save button */}
                        <div onClick={() => {

                          saveQuestion(trainingVideo1);

                        }}>
                          <CustomButton title={"Save Question"} color={"#ffffff"} background={"#57267B"} />
                        </div>

                        {/* my cancel button */}
                        <div onClick={() => {
                          handleAddNewQuestion()
                        }} className=' w-fit ml-auto'> <CustomButton title={"Cancel"} color={"#ffffff"} background={"#57267B"} /></div>
                      </div>

                    </div>


                  </div> : <div className='border mb-20 border-gray-300 rounded p-4 mt-2 relative'>
                    <div onClick={() => {
                      handleAddNewQuestion()
                    }} className=' w-fit ml-auto'><CancelOutlinedIcon sx={{ fill: "#9c9c9c" }} /></div>
                    {/* Question */}
                    <label htmlFor="question" className="block text-sm font-light text-[#9c9c9c]">
                      Question
                    </label>
                    <input
                      type="text"
                      name="question"
                      id="question"
                      value={options.question}
                      onChange={handleOptionChange}
                      className="mt-1 px-4 py-2 block w-full rounded-md ring-1 ring-[#d1d5db] border-gray-300 shadow-sm focus:border-[#57267b] focus:ring-2 focus:ring-[#57267b]"
                    />

                    {/* Option A */}
                    <label htmlFor="optionA" className="block text-sm font-light text-[#9c9c9c] mt-4">
                      Option 1
                    </label>
                    <input
                      type="text"
                      name="optionA"
                      id="optionA"
                      value={options.optionA}
                      onChange={handleOptionChange}
                      className="mt-1 px-4 py-2 block w-full rounded-md ring-1 ring-[#d1d5db] border-gray-300 shadow-sm focus:border-[#57267b] focus:ring-2 focus:ring-[#57267b]"
                    />

                    {/* Option B */}
                    <label htmlFor="optionB" className="block text-sm font-light text-[#9c9c9c] mt-4">
                      Option 2
                    </label>
                    <input
                      type="text"
                      name="optionB"
                      id="optionB"
                      value={options.optionB}
                      onChange={handleOptionChange}
                      className="mt-1 px-4 py-2 block w-full rounded-md ring-1 ring-[#d1d5db] border-gray-300 shadow-sm focus:border-[#57267b] focus:ring-2 focus:ring-[#57267b]"
                    />

                    {/* Option C */}
                    <label htmlFor="optionC" className="block text-sm font-light text-[#9c9c9c] mt-4">
                      Option 3
                    </label>
                    <input
                      type="text"
                      name="optionC"
                      id="optionC"
                      value={options.optionC}
                      onChange={handleOptionChange}
                      className="mt-1 px-4 py-2 block w-full rounded-md ring-1 ring-[#d1d5db] border-gray-300 shadow-sm focus:border-[#57267b] focus:ring-2 focus:ring-[#57267b]"
                    />

                    {/* Option D */}
                    <label htmlFor="optionD" className="block text-sm font-light text-[#9c9c9c] mt-4">
                      Option 4
                    </label>
                    <input
                      type="text"
                      name="optionD"
                      id="optionD"
                      value={options.optionD}
                      onChange={handleOptionChange}
                      className="mt-1 px-4 py-2 block w-full rounded-md ring-1 ring-[#d1d5db] border-gray-300 shadow-sm focus:border-[#57267b] focus:ring-2 focus:ring-[#57267b]"
                    />
                    <div className='mt-8 mb-6 rounded'>
                      <FormControl fullWidth variant="outlined" className="w-full">
                        <InputLabel
                          sx={{
                            color: '#9c9c9c', // default label color
                            '&.Mui-focused': {
                              color: '#57267B', // label color when the input is focused
                            },

                          }}
                        >
                          Correct Option
                        </InputLabel>
                        <Select
                          label="Correct Option"
                          value={options.correctAnswer}
                          onChange={(e) => setOptions(
                            {
                              question: options?.question,
                              optionA: options?.optionA,
                              optionB: options?.optionB,
                              optionC: options?.optionC,
                              optionD: options?.optionD,
                              correctAnswer: e.target.value,

                            }



                          )}
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#d1d5db', // default border color
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#d1d5db', // border color on hover
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#57267B', // border color when the input is focused
                            },
                            '& .MuiSelect-icon': {
                              color: 'rgba(0, 0, 0, 0.54)', // default icon color
                            },
                            '&.Mui-focused .MuiSelect-icon': {
                              color: '#57267B', // icon color when the input is focused
                            },
                          }}
                        >
                          <MenuItem value={1}>A</MenuItem>
                          <MenuItem value={2}>B</MenuItem>
                          <MenuItem value={3}>C</MenuItem>
                          <MenuItem value={4}>D</MenuItem>

                        </Select>
                      </FormControl>

                    </div>

                    {/* <div onClick={() => {
                      saveQuestion3(trainingVideo3);
                    }}>
                      <CustomButton title={"Save Question"} color={"#ffffff"} background={"#57267B"} />
                    </div> */}

                    {/* my last control buttom */}
                    <div className='flex'>

                      {/* my save button */}
                      <div onClick={() => {

                        saveQuestion(trainingVideo1);

                      }}>
                        <CustomButton title={"Save Question"} color={"#ffffff"} background={"#57267B"} />
                      </div>

                      {/* my cancel button */}
                      <div onClick={() => {
                        handleAddNewQuestion()
                      }} className=' w-fit ml-auto'> <CustomButton title={"Cancel"} color={"#ffffff"} background={"#57267B"} /></div>
                    </div>



                  </div>)





                }



                {/* <div onClick={() => {
                  handleAddNewQuestion()
                }}>
                  <CustomButton disabled={disableBtn} title={"+ Add Questions"} color={"#ffffff"} background={"#57267B"} />
                </div> */}


                <div className={" flex gap-2 mt-8"}>
                  <div onClick={() => {
                    handleAddNewQuestion()
                  }}>
                    <CustomButton disabled={disableBtn} title={"+ Add Objective Question"} color={"#ffffff"} background={"#57267B"} />
                  </div>

                  <div onClick={() => {
                    handleAddNewQuestion(true)
                  }}>
                    <CustomButton disabled={disableBtn} title={"+ Add Subjective Question"} color={"#ffffff"} background={"#57267B"} />
                  </div>

                </div>



              </div>


            </div>
          }

          {/* 7 min training video */}
          {trainingVideo2 &&
            <div key={trainingVideo2?._id} className='border border-gray-300 rounded p-4 mt-2 relative'>


              <div className='text-[#57267b] text-center font-semibold py-6 px-8'>Training Video & Quiz-2</div>

              <div className='flex-col'>
                <video key={trainingVideo2?.video} width="100%" controls>
                  <source src={trainingVideo2?.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>

                <div className='flex gap-4 my-4'>
                  <button style={{
                    background: "#57267b",
                    color: "#ffffff",
                    borderRadius: "6px",
                    height: "45px",
                    fontSize: "16px",
                    fontWeight: "500",
                    border: "none",
                    padding: "10px 16px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "4px"

                  }}
                    // disabled={!uploadNewVideoLen}
                    disabled={disableBtn}

                    onClick={() => {
                      setUploadNewVideoLen("7");
                      document.getElementById('file-input-upload-vid-2').click()
                    }}>
                    Update Video
                    <input
                      type="file"
                      hidden
                      accept="video/*"
                      onChange={handleVideoUpdate}
                      id="file-input-upload-vid-2" // Add an ID to the input for reference
                    />
                  </button>
                </div>
                {/* questions */}
                {trainingVideo2?.questions?.map((eachQuestion, index) => {
                  return (
                    <div key={index} className=' mt-5'>
                      <div onClick={() => {
                        // deleteQuestion(trainingVideo2?._id, eachQuestion?._id);
                        deleteQuestion2(index);
                      }} className='flex justify-end  hover:cursor-pointer	' >
                        <DeleteOutlineIcon style={{ color: '#f91b1b' }} />
                      </div>

                      <div className="">
                        <div className="font-semibold text-lg px-1">{index + 1}. {eachQuestion?.question}</div>
                        {eachQuestion?.correctAnswer != 7 && <div className="bg-white rounded-lg px-4 py-2">
                          <div className={`py-2 border-b border-gray-200 ${eachQuestion?.correctAnswer === 1 ? "text-green-500" : ""}`}>A. {eachQuestion?.answers[0]}</div>
                          <div className={`py-2 border-b border-gray-200 ${eachQuestion?.correctAnswer === 2 ? "text-green-500" : ""}`}>B. {eachQuestion?.answers[1]}</div>
                          <div className={`py-2 border-b border-gray-200 ${eachQuestion?.correctAnswer === 3 ? "text-green-500" : ""}`}>C. {eachQuestion?.answers[2]}</div>
                          <div className={`pt-2 ${eachQuestion?.correctAnswer === 4 ? "text-green-500" : ""}`}>D. {eachQuestion?.answers[3]}</div>
                        </div>}
                      </div>

                    </div>

                  )
                })}
                {addNewQuestion2 && (descriptiveKey2 ?

                  // this is descriptive
                  <div>

                    <div className='border mb-20 border-gray-300 rounded p-4 mt-2 relative'>
                      {/* <div onClick={() => {
                        handleAddNewQuestion3()
                      }} className=' w-fit ml-auto'><CancelOutlinedIcon sx={{ fill: "#9c9c9c" }} /></div> */}
                      {/* Question */}
                      <label htmlFor="question" className="block mt-4 text-sm font-light text-[#9c9c9c]">
                        Type Your Question Here
                      </label>
                      <input
                        type="text"
                        name="question"
                        id="question"
                        value={options2.question}
                        onChange={handleOptionChange2}
                        className="mt-1 px-4 py-2 block h-20 w-full rounded-md ring-1 ring-[#d1d5db] border-gray-300 shadow-sm focus:border-[#57267b] focus:ring-2 focus:ring-[#57267b]"
                      />


                      {/* my last control buttom */}
                      <div className=' mt-8 flex'>

                        {/* my save button */}
                        <div onClick={() => {

                          saveQuestion2(trainingVideo2);

                        }}>
                          <CustomButton title={"Save Question"} color={"#ffffff"} background={"#57267B"} />
                        </div>

                        {/* my cancel button */}
                        <div onClick={() => {
                          handleAddNewQuestion2()
                        }} className=' w-fit ml-auto'> <CustomButton title={"Cancel"} color={"#ffffff"} background={"#57267B"} /></div>
                      </div>

                    </div>


                  </div> :

                  <div className='border mb-20 border-gray-300 rounded p-4 mt-2 relative'>
                    <div onClick={() => {
                      handleAddNewQuestion2()
                    }} className=' w-fit ml-auto'><CancelOutlinedIcon sx={{ fill: "#9c9c9c" }} /></div>
                    {/* Question */}
                    <label htmlFor="question" className="block text-sm font-light text-[#9c9c9c]">
                      Question
                    </label>
                    <input
                      type="text"
                      name="question"
                      id="question"
                      value={options2.question}
                      onChange={handleOptionChange2}
                      className="mt-1 px-4 py-2 block w-full rounded-md ring-1 ring-[#d1d5db] border-gray-300 shadow-sm focus:border-[#57267b] focus:ring-2 focus:ring-[#57267b]"
                    />

                    {/* Option A */}
                    <label htmlFor="optionA" className="block text-sm font-light text-[#9c9c9c] mt-4">
                      Option 1
                    </label>
                    <input
                      type="text"
                      name="optionA"
                      id="optionA"
                      value={options2.optionA}
                      onChange={handleOptionChange2}
                      className="mt-1 px-4 py-2 block w-full rounded-md ring-1 ring-[#d1d5db] border-gray-300 shadow-sm focus:border-[#57267b] focus:ring-2 focus:ring-[#57267b]"
                    />

                    {/* Option B */}
                    <label htmlFor="optionB" className="block text-sm font-light text-[#9c9c9c] mt-4">
                      Option 2
                    </label>
                    <input
                      type="text"
                      name="optionB"
                      id="optionB"
                      value={options2.optionB}
                      onChange={handleOptionChange2}
                      className="mt-1 px-4 py-2 block w-full rounded-md ring-1 ring-[#d1d5db] border-gray-300 shadow-sm focus:border-[#57267b] focus:ring-2 focus:ring-[#57267b]"
                    />

                    {/* Option C */}
                    <label htmlFor="optionC" className="block text-sm font-light text-[#9c9c9c] mt-4">
                      Option 3
                    </label>
                    <input
                      type="text"
                      name="optionC"
                      id="optionC"
                      value={options2.optionC}
                      onChange={handleOptionChange2}
                      className="mt-1 px-4 py-2 block w-full rounded-md ring-1 ring-[#d1d5db] border-gray-300 shadow-sm focus:border-[#57267b] focus:ring-2 focus:ring-[#57267b]"
                    />

                    {/* Option D */}
                    <label htmlFor="optionD" className="block text-sm font-light text-[#9c9c9c] mt-4">
                      Option 4
                    </label>
                    <input
                      type="text"
                      name="optionD"
                      id="optionD"
                      value={options2.optionD}
                      onChange={handleOptionChange2}
                      className="mt-1 px-4 py-2 block w-full rounded-md ring-1 ring-[#d1d5db] border-gray-300 shadow-sm focus:border-[#57267b] focus:ring-2 focus:ring-[#57267b]"
                    />

                    <div className='mt-8 mb-6 rounded'>
                      <FormControl fullWidth variant="outlined" className="w-full">
                        <InputLabel
                          sx={{
                            color: '#9c9c9c', // default label color
                            '&.Mui-focused': {
                              color: '#57267B', // label color when the input is focused
                            }
                          }}
                        >
                          Correct Option
                        </InputLabel>
                        <Select
                          label="Correct Option"
                          value={options2.correctAnswer}
                          onChange={(e) => setOptions2(
                            {
                              question: options2?.question,
                              optionA: options2?.optionA,
                              optionB: options2?.optionB,
                              optionC: options2?.optionC,
                              optionD: options2?.optionD,
                              correctAnswer: e.target.value,

                            }



                          )}
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#d1d5db', // default border color
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#d1d5db', // border color on hover
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#57267B', // border color when the input is focused
                            },
                            '& .MuiSelect-icon': {
                              color: 'rgba(0, 0, 0, 0.54)', // default icon color
                            },
                            '&.Mui-focused .MuiSelect-icon': {
                              color: '#57267B', // icon color when the input is focused
                            },
                          }}
                        >
                          <MenuItem value={1}>A</MenuItem>
                          <MenuItem value={2}>B</MenuItem>
                          <MenuItem value={3}>C</MenuItem>
                          <MenuItem value={4}>D</MenuItem>

                        </Select>
                      </FormControl>

                    </div>

                    {/* <div onClick={() => {
                      
                      saveQuestion2(trainingVideo2);

                    }}>
                      <CustomButton title={"Save Question"} color={"#ffffff"} background={"#57267B"} />
                    </div> */}


                    {/* my last control buttom */}
                    <div className='flex'>

                      {/* my save button */}
                      <div onClick={() => {

                        saveQuestion2(trainingVideo2);

                      }}>
                        <CustomButton title={"Save Question"} color={"#ffffff"} background={"#57267B"} />
                      </div>

                      {/* my cancel button */}
                      <div onClick={() => {
                        handleAddNewQuestion2()
                      }} className=' w-fit ml-auto'> <CustomButton title={"Cancel"} color={"#ffffff"} background={"#57267B"} /></div>
                    </div>






                  </div>)




                }



                {/* <div onClick={() => {
                  handleAddNewQuestion2()
                }}>
                  <CustomButton disabled={disableBtn} title={"+ Add Questions"} color={"#ffffff"} background={"#57267B"} />
                </div> */}


                <div className={" flex gap-2 mt-8"}>
                  <div onClick={() => {
                    handleAddNewQuestion2()
                  }}>
                    <CustomButton disabled={disableBtn} title={"+ Add Objective Question"} color={"#ffffff"} background={"#57267B"} />
                  </div>

                  <div onClick={() => {
                    handleAddNewQuestion2(true)
                  }}>
                    <CustomButton disabled={disableBtn} title={"+ Add Subjective Question"} color={"#ffffff"} background={"#57267B"} />
                  </div>

                </div>



              </div>


            </div>
          }



          {/* 10 min training video */}
          {trainingVideo3 &&
            <div key={trainingVideo3?._id} className='border border-gray-300 rounded p-4 mt-2 relative'>


              <div className='text-[#57267b] text-center font-semibold py-6 px-8'>Training Video & Quiz-3</div>

              <div className='flex-col'>
                <video key={trainingVideo3?.video} width="100%" controls>
                  <source src={trainingVideo3?.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>

                <div className='flex gap-4 my-4'>
                  <button style={{
                    background: "#57267b",
                    color: "#ffffff",
                    borderRadius: "6px",
                    height: "45px",
                    fontSize: "16px",
                    fontWeight: "500",
                    border: "none",
                    padding: "10px 16px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "4px"

                  }}
                    // disabled={!uploadNewVideoLen}
                    disabled={disableBtn}

                    onClick={() => {
                      setUploadNewVideoLen("10");
                      document.getElementById('file-input-upload-vid-3').click()
                    }}>
                    Update Video
                    <input
                      type="file"
                      hidden
                      accept="video/*"
                      onChange={handleVideoUpdate}
                      id="file-input-upload-vid-3" // Add an ID to the input for reference
                    />
                  </button>
                </div>
                {/* questions */}
                {trainingVideo3?.questions?.map((eachQuestion, index) => {
                  return (
                    <div key={index} className=' mt-1'>
                      <div onClick={() => {
                        // deleteQuestion(trainingVideo3?._id, eachQuestion?._id);
                        deleteQuestion3(index);
                      }} className='flex justify-end  hover:cursor-pointer	' >
                        <DeleteOutlineIcon style={{ color: '#f91b1b' }} />
                      </div>


                      <div className="">
                        <div className="font-semibold text-lg px-1">{index + 1}. {eachQuestion?.question}</div>
                        {eachQuestion?.correctAnswer != 7 && <div className="bg-white rounded-lg px-4 py-2">
                          <div className={`py-2 border-b border-gray-200 ${eachQuestion?.correctAnswer === 1 ? "text-green-500" : ""}`}>A. {eachQuestion?.answers[0]}</div>
                          <div className={`py-2 border-b border-gray-200 ${eachQuestion?.correctAnswer === 2 ? "text-green-500" : ""}`}>B. {eachQuestion?.answers[1]}</div>
                          <div className={`py-2 border-b border-gray-200 ${eachQuestion?.correctAnswer === 3 ? "text-green-500" : ""}`}>C. {eachQuestion?.answers[2]}</div>
                          <div className={`pt-2 ${eachQuestion?.correctAnswer === 4 ? "text-green-500" : ""}`}>D. {eachQuestion?.answers[3]}</div>
                        </div>}
                      </div>

                    </div>

                  )
                })}


                {/* add questions segment  */}
                {addNewQuestion3 && (descriptiveKey3 ?
                  <div>

                    <div className='border mb-20 border-gray-300 rounded p-4 mt-2 relative'>
                      {/* <div onClick={() => {
                        handleAddNewQuestion3()
                      }} className=' w-fit ml-auto'><CancelOutlinedIcon sx={{ fill: "#9c9c9c" }} /></div> */}
                      {/* Question */}
                      <label htmlFor="question" className="block mt-4 text-sm font-light text-[#9c9c9c]">
                        Type Your Question Here
                      </label>
                      <input
                        type="text"
                        name="question"
                        id="question"
                        value={options3.question}
                        onChange={handleOptionChange3}
                        className="mt-1 px-4 py-2 block h-20 w-full rounded-md ring-1 ring-[#d1d5db] border-gray-300 shadow-sm focus:border-[#57267b] focus:ring-2 focus:ring-[#57267b]"
                      />


                      {/* my last control buttom */}
                      <div className=' mt-8 flex'>

                        {/* my save button */}
                        <div onClick={() => {

                          saveQuestion3(trainingVideo3);

                        }}>
                          <CustomButton title={"Save Question"} color={"#ffffff"} background={"#57267B"} />
                        </div>

                        {/* my cancel button */}
                        <div onClick={() => {
                          handleAddNewQuestion3()
                        }} className=' w-fit ml-auto'> <CustomButton title={"Cancel"} color={"#ffffff"} background={"#57267B"} /></div>
                      </div>

                    </div>


                  </div> : <div className='border mb-20 border-gray-300 rounded p-4 mt-2 relative'>
                    <div onClick={() => {
                      handleAddNewQuestion3()
                    }} className=' w-fit ml-auto'><CancelOutlinedIcon sx={{ fill: "#9c9c9c" }} /></div>
                    {/* Question */}
                    <label htmlFor="question" className="block text-sm font-light text-[#9c9c9c]">
                      Question
                    </label>
                    <input
                      type="text"
                      name="question"
                      id="question"
                      value={options3.question}
                      onChange={handleOptionChange3}
                      className="mt-1 px-4 py-2 block w-full rounded-md ring-1 ring-[#d1d5db] border-gray-300 shadow-sm focus:border-[#57267b] focus:ring-2 focus:ring-[#57267b]"
                    />

                    {/* Option A */}
                    <label htmlFor="optionA" className="block text-sm font-light text-[#9c9c9c] mt-4">
                      Option 1
                    </label>
                    <input
                      type="text"
                      name="optionA"
                      id="optionA"
                      value={options3.optionA}
                      onChange={handleOptionChange3}
                      className="mt-1 px-4 py-2 block w-full rounded-md ring-1 ring-[#d1d5db] border-gray-300 shadow-sm focus:border-[#57267b] focus:ring-2 focus:ring-[#57267b]"
                    />

                    {/* Option B */}
                    <label htmlFor="optionB" className="block text-sm font-light text-[#9c9c9c] mt-4">
                      Option 2
                    </label>
                    <input
                      type="text"
                      name="optionB"
                      id="optionB"
                      value={options3.optionB}
                      onChange={handleOptionChange3}
                      className="mt-1 px-4 py-2 block w-full rounded-md ring-1 ring-[#d1d5db] border-gray-300 shadow-sm focus:border-[#57267b] focus:ring-2 focus:ring-[#57267b]"
                    />

                    {/* Option C */}
                    <label htmlFor="optionC" className="block text-sm font-light text-[#9c9c9c] mt-4">
                      Option 3
                    </label>
                    <input
                      type="text"
                      name="optionC"
                      id="optionC"
                      value={options3.optionC}
                      onChange={handleOptionChange3}
                      className="mt-1 px-4 py-2 block w-full rounded-md ring-1 ring-[#d1d5db] border-gray-300 shadow-sm focus:border-[#57267b] focus:ring-2 focus:ring-[#57267b]"
                    />

                    {/* Option D */}
                    <label htmlFor="optionD" className="block text-sm font-light text-[#9c9c9c] mt-4">
                      Option 4
                    </label>
                    <input
                      type="text"
                      name="optionD"
                      id="optionD"
                      value={options3.optionD}
                      onChange={handleOptionChange3}
                      className="mt-1 px-4 py-2 block w-full rounded-md ring-1 ring-[#d1d5db] border-gray-300 shadow-sm focus:border-[#57267b] focus:ring-2 focus:ring-[#57267b]"
                    />
                    <div className='mt-8 mb-6 rounded'>
                      <FormControl fullWidth variant="outlined" className="w-full">
                        <InputLabel
                          sx={{
                            color: '#9c9c9c', // default label color
                            '&.Mui-focused': {
                              color: '#57267B', // label color when the input is focused
                            },

                          }}
                        >
                          Correct Option
                        </InputLabel>
                        <Select
                          label="Correct Option"
                          value={options3.correctAnswer}
                          onChange={(e) => setOptions3(
                            {
                              question: options3?.question,
                              optionA: options3?.optionA,
                              optionB: options3?.optionB,
                              optionC: options3?.optionC,
                              optionD: options3?.optionD,
                              correctAnswer: e.target.value,

                            }



                          )}
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#d1d5db', // default border color
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#d1d5db', // border color on hover
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#57267B', // border color when the input is focused
                            },
                            '& .MuiSelect-icon': {
                              color: 'rgba(0, 0, 0, 0.54)', // default icon color
                            },
                            '&.Mui-focused .MuiSelect-icon': {
                              color: '#57267B', // icon color when the input is focused
                            },
                          }}
                        >
                          <MenuItem value={1}>A</MenuItem>
                          <MenuItem value={2}>B</MenuItem>
                          <MenuItem value={3}>C</MenuItem>
                          <MenuItem value={4}>D</MenuItem>

                        </Select>
                      </FormControl>

                    </div>

                    {/* <div onClick={() => {
                      saveQuestion3(trainingVideo3);
                    }}>
                      <CustomButton title={"Save Question"} color={"#ffffff"} background={"#57267B"} />
                    </div> */}

                    {/* my last control buttom */}
                    <div className='flex'>

                      {/* my save button */}
                      <div onClick={() => {

                        saveQuestion3(trainingVideo3);

                      }}>
                        <CustomButton title={"Save Question"} color={"#ffffff"} background={"#57267B"} />
                      </div>

                      {/* my cancel button */}
                      <div onClick={() => {
                        handleAddNewQuestion3()
                      }} className=' w-fit ml-auto'> <CustomButton title={"Cancel"} color={"#ffffff"} background={"#57267B"} /></div>
                    </div>



                  </div>)





                }
                <div className={" flex gap-2 mt-8"}>
                  <div onClick={() => {
                    handleAddNewQuestion3()
                  }}>
                    <CustomButton disabled={disableBtn} title={"+ Add Objective Question"} color={"#ffffff"} background={"#57267B"} />
                  </div>

                  <div onClick={() => {
                    handleAddNewQuestion3(true)
                  }}>
                    <CustomButton disabled={disableBtn} title={"+ Add Subjective Question"} color={"#ffffff"} background={"#57267B"} />
                  </div>

                </div>



              </div>


            </div>
          }









          {/* I want this div to take all the white space between this and increase it's height */}
          <div className='mt-12 '>
            <div className="flex justify-end space-x-2">

              <Button
                variant="outlined"
                style={{ borderColor: '#57267B', color: '#57267B' }}
                onClick={close}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: '#57267B', color: 'white' }}
                onClick={handleAdd}
                disabled={disableBtn}
              >
                Save
              </Button>

            </div>

          </div>



        </div>



      </div>


    </div>
  );
};

export default EditModuleModalComp;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState,useEffect, useRef} from 'react'
import Highcharts, { color } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';


const LineChart2=({totalRevenue,chartData}) => {
  const chartContainerRef = useRef(null);
    const [containerSize, setContainerSize] = useState({ width: '50%', height: '50%' });

    const [myTotalRevenue,setMyTotalRevenue]=useState(0);
    const [myChartData,setMyChartData]=useState([0,0,0,0,0,0,0,0,0,0,0,0])
 


  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
            setContainerSize({
                width: entry.contentRect.width,
                height: entry.contentRect.height
            });
        }
    });

    if (chartContainerRef.current) {
        resizeObserver.observe(chartContainerRef.current);
    }

    return () => {
        if (chartContainerRef.current) {
            resizeObserver.unobserve(chartContainerRef.current);
        }
    };
  }, []);

  useEffect(()=>{
    setMyTotalRevenue(totalRevenue);
    // let tempArray2=new Array(12).fill(0);
    // chartData?.year?.map((eachMonth)=>{
    //         tempArray2[eachMonth?.period?.month-1]=eachMonth?.count*9;
    // })
    
    // setMyChartData(tempArray2);

  },[])

  const options = {
    credits: {
        enabled: false, // Disable the Highcharts logo
      },
    chart: {
        type: 'line',
        height: containerSize.height, // Use state value here for height
        width: containerSize.width  // Use state value here for width
    },
    title: {
        text: '',
        align: 'left'
    },
    subtitle: {
        text: '',
        align: 'left'
    },
    xAxis: {
        categories:['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
        crosshair: true,
        accessibility: {
            description: 'Months'
        }
    },
    yAxis: {
        visible: false,
        min: 0,
        title: {
            text: ''
        }
    },
    tooltip: {
        valueSuffix: '',
        valuePrefix: '$'
    },
    plotOptions: {
        column: {
            pointPadding: 0.25,
            borderWidth: 0
        }
    },
    legend: {
        align: 'center',
        verticalAlign: 'top',
        layout: 'horizontal',
        enabled:false
    
    },
    series: [
        {
            name: 'Net Profit',
            data: chartData,
            color: '#F1426D'
        }
    ]
};

  return (
    <div className={`  bg-[#FFFCFC]  rounded-xl `}>
    
      {/* begin::Body */}
      <div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
        {/* begin::Hidden */}
        <div className='flex justify-between flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3 '>
         <div>
          <div className='me-2 '>
          <AttachMoneyIcon style={{ fill: '#57267b' }}/>
          <div className='font-semibold	 text-[#57267B] d-block fs-3'>Total Revenue</div>    
          </div>
          <div className={`fw-bold fs-3 text-[#57267B]`}>$ {totalRevenue}</div>

         </div>
        



        </div>
        {/* end::Hidden */}

        {/* begin::Chart */}
        <div ref={chartContainerRef} className=' max-h-80 xl:h-52 rounded-lg pb-2'>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
        {/* end::Chart */}
      </div>
    </div>
  )
}



export default LineChart2;
